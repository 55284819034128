<template>
  <div class="abstract-container">
    <button
      @click="toggleCollapse"
      class="toggle-button"
    >
      <img
        :src="ArrowIcon"
        class="toggle-button-icon"
        :class="abstractShow ? 'arrow-up' : 'arrow-down'"
      />
      Abstract
    </button>
      <div class="collapse" :aria-expanded="abstractShow">
        {{ props.abstractText }}
      </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";

const ArrowIcon = require("../../assets/images/ArrowWhiteIcon.svg") as string;

interface Props {
  abstractText: string;
}

const props = defineProps<Props>();

const abstractShow = ref(false);

function toggleCollapse() {
  abstractShow.value = !abstractShow.value;
}
</script>

<style lang="scss">
.abstract-container {
  width: auto;
  margin: 0 3.2rem;
  font-size: 1.8rem;
  border: solid 0.1rem map-get($colours, accent-purple);
  border-radius: 0.8rem;
  padding: 0.8rem 1.6rem 1.6rem;

  .collapse[aria-expanded="false"] {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    height: 2rem !important;
    overflow: hidden;
  }

  .toggle-button {
    background: transparent;
    display: flex;
    align-items: center;
    margin: 1rem 0 0.4rem;
    color: map-get($colours, grey-1);
    font-size: 1.1rem;


    .toggle-button-icon {
      margin-right: 0.4rem;
      margin-bottom: 0.6rem;
      width: 2.4rem;
    }
  }


  .arrow-up {
    transform: rotate(-90deg);
  }

  .arrow-down {
    transform: rotate(90deg);
  }
}
</style>
