<template>
  <div class="subscription-overview-card">
    <div class="title">
      {{
        isFree
          ? "Free"
          : isMonthly
          ? "Monthly Subscription"
          : isYearly
          ? "Yearly Subscription"
          : ""
      }}
    </div>
    <ul class="features">
      <li
        v-for="perk in isFree
          ? SUBSCRIPTION_MODELS.FREE.perks
          : isMonthly
          ? SUBSCRIPTION_MODELS.MONTHLY.perks
          : isYearly
          ? SUBSCRIPTION_MODELS.YEARLY.perks
          : []"
        :key="perk"
      >
        {{ perk }}
      </li>
      <li v-if="freeTrialEligible">30 day free trial**</li>
    </ul>
    <div class="cost label-value-row">
      <span class="label">Cost</span>
      <div class="value">
        <span> {{ formattedSubscriptionPrice }}</span>
        <span>
          {{
            isFree
              ? "Will not auto renew to a paid subscription"
              : isMonthly
              ? "Renews monthly"
              : isYearly
              ? "Renews yearly"
              : ""
          }}
        </span>
      </div>
    </div>
    <div class="subtotal label-value-row">
      <span class="label">Subtotal</span>
      <span class="value"> {{ formattedSubscriptionPrice }}</span>
    </div>
  </div>
</template>

<script>
import {
  DEFAULT_COUNTRY_ISO,
  SUBSCRIPTION_MODELS,
} from "../../assets/constants";

export default {
  props: {
    subscriptionModel: String,
    formattedSubscriptionPrice: String,
    freeTrialEligible: Boolean,
  },

  computed: {
    isFree() {
      return this.subscriptionModel === SUBSCRIPTION_MODELS.FREE.type;
    },

    isMonthly() {
      return this.subscriptionModel === SUBSCRIPTION_MODELS.MONTHLY.type;
    },

    isYearly() {
      return this.subscriptionModel === SUBSCRIPTION_MODELS.YEARLY.type;
    },

    currentUserCountryISO() {
      return this.$store.state.currentUser.location || DEFAULT_COUNTRY_ISO;
    },
  },

  data() {
    return {
      SUBSCRIPTION_MODELS: SUBSCRIPTION_MODELS,
    };
  },
};
</script>

<style lang="scss">
.subscription-overview-card {
  font-size: 1.4rem;
  font-family: $font-secondary;
  background-color: map-get($colours, accent-purple-3);
  border-radius: 1.4rem;
  padding: 1.6rem;
  margin-top: 2.4rem;

  .title {
    font-size: 1.4rem;
    font-weight: bold;
  }

  .features {
    color: map-get($colours, translucent-white-60);
    padding-left: 2rem;
    margin: 2.4rem 0 2rem;

    li {
      padding-left: 0.2rem;
    }

    > *:not(:first-child) {
      margin-top: 1.2rem;
    }
  }

  .label-value-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 0.1rem solid map-get($colours, translucent-black);

    .label {
    }

    .value-wrapper {
      display: flex;
      flex-direction: column;

      .free-trial-disclaimer {
        color: map-get($colours, green);
      }
    }

    .value {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      &.strikethrough {
        text-decoration: line-through;
      }
    }

    &.cost {
      padding: 2rem 0;
    }

    &.subtotal {
      font-weight: bold;
      padding: 2rem 0 0.4rem;
    }
  }
}
</style>
