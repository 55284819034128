<template>
  <Dialog
    :visible="props.visible"
    class="upload-dialog"
    header="false"
    :closable="false"
  >
    <div class="upload-dialog-content">
      <div class="close-wrapper">
        <button class="close" :onclick="close">
          <img :src="CloseGreyIcon" alt="Close menu" />
        </button>
      </div>
      <div class="upload-dialog-content-title">New Session</div>
      <div class="upload-dialog-content-subtitle">
        Select a session type to get started
      </div>
      <TabView class="tabview">
        <TabPanel header="With Recording">
          <div class="upload-dialog-content-panel">
            Before starting a recording: Please make sure you have permission
          </div>
          <div class="big-buttons-column">
            <button
              class="big-button"
              @click="router.push({ name: 'Recorder' })"
            >
              <div class="big-button-content">
                <div class="big-button-content-icon">
                  <img :src="MicIcon" alt="AudioRecordIcon" />
                </div>
                <div class="big-button-content-content">
                  <div class="big-button-content-content-title">
                    Audio Recording
                  </div>
                  <div class="big-button-content-content-text">
                    Record a lecture effortlessly and add personal notes.
                  </div>
                </div>
              </div>
            </button>
            <button class="big-button" :disabled="true">
              <div class="big-button-content big-button-disabled">
                <div class="big-button-content-icon">
                  <img :src="ScreenRecordIcon" alt="ScreenRecordIcon" />
                </div>
                <div class="big-button-content-content">
                  <div class="big-button-content-content-title">
                    Screen Recording
                  </div>
                  <div class="big-button-content-content-text">
                    Record your screen and add notes.
                  </div>
                </div>
              </div>
              <div class="big-button-wrapper-tag">
                <Tag value="COMING SOON" class="big-button-tag" />
              </div>
            </button>
          </div>
        </TabPanel>
        <TabPanel header="Without Recording">
          <div class="upload-dialog-content-panel">
            Before starting a recording: Please make sure you have permission
          </div>
          <div class="big-buttons-column">
            <button class="big-button" @click="router.push({ name: 'Upload' })">
              <div class="big-button-content">
                <div class="big-button-content-icon">
                  <img :src="UploadIcon" alt="UploadIcon" />
                </div>
                <div class="big-button-content-content">
                  <div class="big-button-content-content-title">
                    Upload Files
                  </div>
                  <div class="big-button-content-content-text">
                    Upload audio, video files or PDFs for editable transcription.
                  </div>
                </div>
              </div>
            </button>
            <button
              class="big-button"
              :onClick="goToSessionSetup"
              :disabled="!isProUser"
            >
              <div
                class="big-button-content"
                :class="{ 'big-button-disabled': !isProUser }"
              >
                <div class="big-button-content-icon">
                  <img :src="LiveCaptionsIcon2" alt="LiveCaptionsIcon2" />
                </div>
                <div class="big-button-content-content">
                  <div class="big-button-content-content-title">
                    Live Captions
                    <Tag
                      class="big-button-content-content-title-tag tag-pro"
                      value="PRO"
                    />
                  </div>
                  <div class="big-button-content-content-text">
                    Enable real-time captions for live audio, without recording.
                  </div>
                </div>
              </div>
              <div class="big-button-wrapper-tag" v-if="!isProUser">
                <Tag value="COMING SOON" class="big-button-tag" />
              </div>
            </button>
          </div>
        </TabPanel>
      </TabView>
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import { useRouter } from "vue-router";
import { computed, ref, onBeforeMount } from "vue";
import { getCurrentUserSubscriptionDetails } from "../assets/helpers_legacy";

const CloseGreyIcon = require("../assets/images/Close-grey.svg") as string;
const LiveCaptionsIcon2 =
  require("../assets/images/LiveCaptionsIcon2.svg") as string;
const MicIcon = require("../assets/images/MicIcon.svg") as string;
const ScreenRecordIcon =
  require("../assets/images/ScreenRecordIcon.svg") as string;
const UploadIcon = require("../assets/images/Upload-white.svg") as string;

const router = useRouter();

const props = defineProps({
  visible: {
    type: Boolean,
    required: true,
  },
  setVisible: {
    type: Function,
    required: true,
  },
});

const currentUserSubscriptionDetails = ref();

onBeforeMount(async () => {
  currentUserSubscriptionDetails.value =
    await getCurrentUserSubscriptionDetails();
});

const isProUser = computed(() => {
  return currentUserSubscriptionDetails.value.isPro;
});

function close() {
  props.setVisible(false);
}

function goToSessionSetup() {
  router.push({
    name: "SessionSetup",
  });
}
</script>

<style lang="scss">
.upload-dialog {
  width: 45.1rem;
  .p-dialog-header {
    display: none;
  }

  .p-dialog-content {
    background-color: map-get($colours, accent-purple-2);
    width: 100%;
    height: 100%;
    border-radius: 1.4rem;
    margin: 0;
    padding: 0;
  }

  .big-buttons-column {
    display: flex;
    flex-direction: column;
    .big-button {
      background: map-get($colours, accent-purple-3);
      height: 7.4rem;
      margin-bottom: 1.6rem;
      border-radius: 14px;
      color: white;
      padding: 1.4rem 1.6rem 1.6rem;

      .big-button-content {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .big-button-content-icon {
          border-radius: 50%;
          background: white;
          width: 4rem;
          height: 4rem;
          display: flex;
          align-items: center;
          justify-content: center;
          background: rgba(25, 25, 25, 0.3);
          img {
            width: 2.4rem;
            height: 2.4rem;
          }
        }

        .big-button-content-content {
          margin-left: 1.6rem;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
          font-family: $font-default;

          .big-button-content-content-title {
            font-size: 1.4rem;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .big-button-content-content-title-tag {
              font-size: 0.8rem;
              margin-left: 0.8rem;
              color: #303030;
              font-family: $font-secondary;
            }

            .tag-pro {
              background: #e993ff;
            }

            .tag-basic {
              background: #ffd362;
            }
          }

          .big-button-content-content-text {
            font-size: 1rem;
            color: #e8e8e8;
          }
        }
      }
      .big-button-disabled {
        filter: blur(0.2rem);
      }

      .big-button-wrapper-tag {
        height: 1%;
        width: 100%;
        position: relative;
        .big-button-tag {
          position: absolute;
          left: 50%;
          font-size: 1rem;
          color: white;
          transform: translate(-50%, -150%);
          background: rgba(255, 255, 255, 0.2);
        }
      }
    }
  }

  .tabview {
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .p-tabview-nav {
      background-color: blue;
      width: 100%;
      color: white;
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: space-evenly;
      border-color: rgba(255, 255, 255, 0.2);

      li.p-tabview-ink-bar {
        background-color: white;
        border-width: 0.1rem;
      }
    }

    .p-tabview-panels {
      background-color: transparent;
      flex: 1;
      overflow: auto;
    }
    .p-tabview-nav-link {
      background-color: yellow;
      width: 100%;
      display: flex;
      justify-content: center;
      overflow: hidden;
      font-size: 1.2rem;
      span.p-tabview-title {
        background-color: transparent;
        width: 16rem;
        color: white;
        text-align: center;
      }
    }
  }

  .p-tabview .p-tabview-nav {
    background-color: transparent;
    li.p-tabview-ink-bar {
      background-color: white;
      border-width: 0.1rem;
    }
  }

  li.p-highlight {
    background-color: transparent;
  }

  li[class="true"] {
    background-color: transparent;
  }

  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    background-color: transparent;
    &li.p-tabview-ink-bar {
      background-color: white;
      border-width: 0.1rem;
    }
  }

  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background-color: transparent;
    &li.p-tabview-ink-bar {
      background-color: white;
    }
  }

  .close-wrapper {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    margin: 0;

    .close {
      background-color: map-get($colours, accent-purple-2);
      margin-top: 1.6rem;
      width: 2.4rem;
      height: 2.4rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 2.4rem;
        height: 2.4rem;
        margin: 0;
      }
    }
  }

  .upload-dialog-content {
    margin: 0 2.4rem;
    font-family: $font-accent;
    color: white;

    .upload-dialog-content-title {
      font-size: 2.4rem;
      margin-bottom: 0.8rem;
    }

    .upload-dialog-content-subtitle {
      font-size: 1.4rem;
      margin-bottom: 2.4rem;
    }

    .upload-dialog-content-panel {
      font-size: 1.2rem;
      font-family: $font-secondary;
      color: white;
      margin-bottom: 1.6rem;
    }
  }
}
</style>
