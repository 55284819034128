<template>
  <header class="side-panel-header">
    <div class="side-panel-header-left-wrapper">
      <IconButton
        v-if="goToPreviousMenu != undefined"
        :icon="dark ? ChevronLeftWhiteIcon : ChevronLeftBlackFatIcon"
        alt="Previous menu"
        @click="goToPreviousMenu(true)"
        dark
        xsmall
        class="previous-menu-button"
      />
      <h2>{{ title }}</h2>
    </div>
    <IconButton
      :icon="CloseWhiteIcon"
      alt="Close menu"
      :onclick="onClose"
      dark
      xxxsmall
    />
  </header>
</template>

<script>
import CloseWhiteIcon from "../../assets/images/Close-white.svg";
import ChevronLeftBlackFatIcon from "../../assets/images/Chevron-left-black-fat.svg";
import ChevronLeftWhiteIcon from "../../assets/images/Chevron-left-white.svg";
import IconButton from "../IconButton";

export default {
  props: {
    title: String,
    onClose: Function,
    goToPreviousMenu: Function,
    dark: Boolean,
  },

  components: {
    IconButton,
  },

  data() {
    return {
      CloseWhiteIcon,
      ChevronLeftBlackFatIcon,
      ChevronLeftWhiteIcon,
    };
  },
};
</script>

<style lang="scss">
.side-panel-header {
  padding: 0 $side-panel-outside-padding;
  height: 6.4rem;
  min-height: 6.4rem;
  padding-right: 1.6rem;
  border-bottom: 1px solid map-get($component-colours, border-light);
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: map-get($colours, white);

  .side-panel-header-left-wrapper {
    display: flex;

    .previous-menu-button {
      margin-right: 0.8rem;
      margin-left: -0.8rem;
    }

    h2 {
      font-family: $font-default;
      font-size: 2rem;
      line-height: 2.4rem;
      margin: 0;
      transform: translateY(0.1rem);
    }
  }

  .close {
    cursor: pointer;
  }
}
</style>
