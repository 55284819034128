<template>
  <div class="pdf-overview-panel" ref="pdfOverviewPanel" v-show="visible">
    <button
      v-for="i in numPages"
      :key="i"
      class="pdf-preview-page-button"
      :class="i === currentPageNumber && 'active'"
      @click="switchToPdfPage(i)"
    >
      <vue-pdf-embed
        ref="pdfViewer"
        :source="pdfData"
        :page="i"
        :width="80"
        :textLayer="false"
        class="pdf-preview-page"
      />
      <div class="pdf-preview-page-number">{{ i }}</div>
    </button>
  </div>
</template>

<script>
import { ref } from "vue";
import { onClickOutside } from "@vueuse/core";
import VuePdfEmbed from "vue-pdf-embed";

export default {
  setup(props, { emit }) {
    const pdfOverviewPanel = ref(null);
    // Close panel when click outside
    onClickOutside(pdfOverviewPanel, () => emit("hide"));

    return {
      pdfOverviewPanel,
    };
  },

  props: {
    visible: Boolean,
    pdfData: String,
    switchToPdfPage: Function,
    numPages: Number,
    currentPageNumber: Number,
  },

  components: {
    VuePdfEmbed,
  },
};
</script>

<style lang="scss">
.pdf-overview-panel {
  border-radius: 0.8rem;
  overflow: auto;
  max-width: 95%;
  z-index: 9999;
  padding: 1.6rem;
  display: flex;
  flex-direction: row;
  gap: 2.4rem;
  background-color: map-get($colours, accent-purple-3);
  box-shadow: $small-crisp-box-shadow;

  .pdf-preview-page-button {
    background-color: transparent;
    padding: 0.8rem 0.8rem 0.4rem;
    border-radius: 0.4rem;

    &.active,
    &:hover {
      background-color: rgba(135, 117, 148, 0.2);
      filter: none;
    }

    .pdf-preview-page {
      cursor: pointer;
      pointer-events: none;
    }

    .pdf-preview-page-number {
      color: map-get($colours, grey-3);
      margin-top: 1.2rem;
      font-size: 1.4rem;
    }
  }
}
</style>
