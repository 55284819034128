<template>
  <div class="landing">
    <div class="top-section">
      <LogoHeader />
      <div class="top-menu">
        <TextButton text="LOGIN" @click="goToLogin" />
        <RoundedButton
          class="header-button"
          text="Start for free"
          fitWidth
          lessRound
          uppercase
          accent
          semiBold
          @click="goToSignup"
        />
        <RoundedSquareButton
          :image="GridLight"
          imageAlt="App grid"
          :onClick="toggleAppGrid"
          v-tooltip.top="'App grid'"
          smallImg
          noShadow
          :active="appGridOpen"
        />
        <AppGrid ref="appGrid" />
      </div>
    </div>
    <div class="section">
      <div class="feature-wrapper">
        <h1 class="catchphrase">Supercharge Learning Potential</h1>
        <h2 class="description top-banner">
          Our comprehensive note-taking and transcription app. Designed for
          inclusive and meaningful learning moments. Messenger Pigeon allows you
          to not only capture and organize content but also engage with it.
        </h2>
        <div class="nav-buttons">
          <RoundedButton
            class="signup-button"
            text="Start for free"
            fitWidth
            lessRound
            uppercase
            bold
            accent
            taller
            @click="goToSignup"
          />
          <RoundedButton
            class="download-button"
            fitWidth
            lessRound
            uppercase
            outline
            bold
            transparent
            taller
            @click="toggleNewMenu"
          >
            Download App
            <img :src="ChevronDownFilledWhite" class="down-chevron" />
            <div class="large-screen-menu platform-options-menu-wrapper">
              <div class="platform-options-menu menu">
                <div class="mobile-platform platform-option option">
                  Mobile Application
                  <img
                    :src="ChevronDownFilledWhite"
                    class="down-chevron rotate-cc90"
                  />
                  <div class="mobile-options-menu platform-option-menu menu">
                    <a
                      :href="LINKS.MP_DOWNLOAD_APPSTORE"
                      class="platform-option option"
                    >
                      <img :src="AppleLogoWhite" class="platform-option-logo" />
                      App Store
                    </a>
                    <a
                      :href="LINKS.MP_DOWNLOAD_PLAYSTORE"
                      class="platform-option option"
                    >
                      <img
                        :src="GooglePlayLogoWhite"
                        class="platform-option-logo"
                      />Google Play
                    </a>
                  </div>
                </div>
                <div class="desktop-platform platform-option option">
                  Desktop Application
                  <img
                    :src="ChevronDownFilledWhite"
                    class="down-chevron rotate-cc90"
                  />
                  <div class="desktop-options-menu platform-option-menu menu">
                    <a
                      :href="macOSLink"
                      class="platform-option option"
                      download
                    >
                      <img :src="AppleLogoWhite" class="platform-option-logo" />
                      Mac
                    </a>
                    <a
                      :href="windowsLink"
                      class="platform-option option"
                      download
                    >
                      <img
                        :src="WindowsLogoWhite"
                        class="platform-option-logo"
                      />
                      Windows
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="small-screen-menu platform-options-menu-wrapper">
              <div class="platform-options-menu menu">
                <div class="mobile-platform platform-option option">
                  Mobile
                  <img
                    :src="ChevronDownFilledWhite"
                    class="down-chevron rotate-cc90"
                  />
                  <div class="mobile-options-menu platform-option-menu menu">
                    <a
                      :href="LINKS.MP_DOWNLOAD_APPSTORE"
                      class="platform-option option"
                    >
                      <img :src="AppleLogoWhite" class="platform-option-logo" />
                      App Store
                    </a>
                    <a
                      :href="LINKS.MP_DOWNLOAD_PLAYSTORE"
                      class="platform-option option"
                    >
                      <img
                        :src="GooglePlayLogoWhite"
                        class="platform-option-logo"
                      />Google Play
                    </a>
                  </div>
                </div>
                <div class="desktop-platform platform-option option">
                  Desktop
                  <img
                    :src="ChevronDownFilledWhite"
                    class="down-chevron rotate-cc90"
                  />
                  <div class="desktop-options-menu platform-option-menu menu">
                    <a
                      :href="macOSLink"
                      class="platform-option option"
                      download
                    >
                      <img :src="AppleLogoWhite" class="platform-option-logo" />
                      Mac
                    </a>
                    <a
                      :href="windowsLink"
                      class="platform-option option"
                      download
                    >
                      <img
                        :src="WindowsLogoWhite"
                        class="platform-option-logo"
                      />
                      Windows
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </RoundedButton>
        </div>
      </div>
      <img :src="GirlWithBirds" class="feature-img" />
    </div>
    <div class="feature-grid-wrapper">
      <div class="feature-description">
        <div class="circle">
          <img :src="TranscriptWhite" />
        </div>
        <h2>Transcription</h2>
        <p>Fast and accurate transcription for more inclusive learning.</p>
      </div>
      <div class="feature-description">
        <div class="circle">
          <img :src="NotesWhite" />
        </div>
        <h2>Summary Notes</h2>
        <p>Jot down personalized insights and reminders with just one click.</p>
      </div>
      <div class="feature-description">
        <div class="circle">
          <img :src="MagicWandWhite" />
        </div>
        <h2>AI Study Assistant</h2>
        <p>Engage with study materials, using prompts built for learning.</p>
      </div>
      <div class="feature-description">
        <div class="circle">
          <img :src="MagicStars" />
        </div>
        <h2>Pro Services</h2>
        <p>Connect to a team of professional note takers and captioners.</p>
      </div>
    </div>
    <div class="section">
      <img :src="GirlYogaWithPlants" class="feature-img left" />
      <div class="feature-wrapper">
        <h1 class="catchphrase">Accessibility & Flexibility</h1>
        <h2 class="description">
          Messenger Pigeon accommodates various learning approaches, whether you
          prefer professionally summarized notes in your inbox or the freedom to
          jot down personal thoughts. Securely capturing your content, Messenger
          Pigeon fosters a supportive environment for personalized learning
          journeys.
        </h2>
      </div>
    </div>
    <div class="section">
      <div class="feature-wrapper">
        <h1 class="catchphrase">A trusted learning companion</h1>
        <h2 class="description">
          Built-in generative AI designed to support learning. Our proprietary
          technology not only produces accurate transcripts but also offers
          unique learning opportunities through 14 carefully crafted prompts
          designed by our expert learning strategists.
        </h2>
      </div>
      <img :src="PathDoodle" class="feature-img" />
    </div>
    <div class="section">
      <div class="center-banner">
        <h1 class="catchphrase">Discover effortless learning today!</h1>
        <div class="video-wrapper">
          <video
            controls
            loop
            controlslist="nodownload noremoteplayback noplaybackrate"
          >
            <source
              src="../assets/videos/Study-Smarter-With-The-Messenger-Pigeon-Mobile-App.mp4"
            />
            <a
              href="../assets/videos/Study-Smarter-With-The-Messenger-Pigeon-Mobile-App.mp4"
              >MP4</a
            >
          </video>
        </div>
        <div class="description">
          Book a demo with our team to learn how Messenger Pigeon can support
          you and your students.
        </div>
        <div class="nav-buttons">
          <RoundedButton
            class="demo-button"
            text="Book A Demo"
            fitWidth
            lessRound
            uppercase
            bold
            accent
            taller
          />
        </div>
      </div>
    </div>
    <div class="section">
      <div class="center-banner">
        <h1 class="catchphrase">FAQ</h1>
        <Accordion class="faq-card">
          <AccordionTab
            v-for="faq in faqQuestions"
            :key="faq.id"
            :header="faq.question"
          >
            <p class="faq-answer">
              {{ faq.answer }}
            </p>
          </AccordionTab>
        </Accordion>
      </div>
    </div>
    <div class="footer">
      <p>© {{ currentYear }} Habitat Learn Group. All rights reserved.</p>
      <div class="socials">
        <div class="info-row">
          <IconButton
            :icon="LinkedInIcon"
            alt="LinkedIn"
            class="social-button"
            @click.prevent="openExternalUrl(SOCIALS_URLS.LINKEDIN)"
            small
            rounded
          />
          <IconButton
            :icon="TwitterIcon"
            class="social-button"
            alt="Twitter"
            @click.prevent="openExternalUrl(SOCIALS_URLS.TWITTER)"
            small
            rounded
          />
          <IconButton
            :icon="InstagramIcon"
            class="social-button"
            alt="Instagram"
            @click.prevent="openExternalUrl(SOCIALS_URLS.INSTAGRAM)"
            small
            rounded
          />
          <IconButton
            :icon="YoutubeIcon"
            class="social-button"
            alt="Youtube"
            @click.prevent="openExternalUrl(SOCIALS_URLS.YOUTUBE)"
            small
            rounded
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LogoHeader from "../components/LogoHeader.vue";
import RoundedButton from "../components/RoundedButton.vue";
import GirlWithBirds from "../assets/images/Girl-with-birds.svg";
import ChevronDownFilledWhite from "../assets/images/Chevron-down-filled-white.svg";
import AppleLogoWhite from "../assets/images/Apple-Logo-white.svg";
import GooglePlayLogoWhite from "../assets/images/Google-Play-Logo-white.svg";
import WindowsLogoWhite from "../assets/images/Windows-Logo-white.svg";
import TranscriptWhite from "../assets/images/Transcript-white.svg";
import NotesWhite from "../assets/images/Notes-white.svg";
import MagicWandWhite from "../assets/images/Magic-wand-white.svg";
import MagicStars from "../assets/images/Magic-stars.svg";
import GirlYogaWithPlants from "../assets/images/Girl-yoga-with-plants.svg";
import PathDoodle from "../assets/images/Path-doodle.svg";
import AppGrid from "../components/AppGrid.vue";
import RoundedSquareButton from "../components/RoundedSquareButton.vue";
import GridLight from "../assets/images/Grid-Light.svg";
import TextButton from "../components/TextButton.vue";
import IconButton from "../components/IconButton.vue";

import { SOCIALS_URLS, LINKS } from "../assets/constants";
import InstagramIcon from "../assets/images/Socials/instagram-dark.svg";
import LinkedInIcon from "../assets/images/Socials/linkedin-dark.svg";
import TwitterIcon from "../assets/images/Socials/twitter-dark.svg";
import YoutubeIcon from "../assets/images/Socials/youtube-dark.svg";

export default {
  components: {
    LogoHeader,
    RoundedButton,
    RoundedSquareButton,
    AppGrid,
    TextButton,
    IconButton,
  },

  data() {
    return {
      GirlWithBirds,
      ChevronDownFilledWhite,
      AppleLogoWhite,
      GooglePlayLogoWhite,
      WindowsLogoWhite,
      TranscriptWhite,
      NotesWhite,
      MagicWandWhite,
      MagicStars,
      GirlYogaWithPlants,
      PathDoodle,
      LINKS,
      SOCIALS_URLS,
      InstagramIcon,
      LinkedInIcon,
      TwitterIcon,
      YoutubeIcon,
      macOSLink: "",
      windowsLink: "",
      GridLight,
      appGridOpen: false,
      iconCollapsed: true,
      currentYear: new Date().getFullYear(),
      faqQuestions: [
        {
          question: "How do I access Messenger Pigeon?",
          answer:
            "Prior to your class you will be sent an email with a link to Launch Messenger Pigeon.",
        },
        {
          question: "Does Messenger Pigeon work for Hybrid learning?",
          answer:
            "Yes, Messenger Pigeon can be used for in-class and remote students, using a combination of Habitat Learn services, such as Launch and Coral.",
        },
        {
          question:
            "What if there is a last minute change to my class schedule?",
          answer:
            "Your captioner will monitor your LMS system for any class updates, however feel free to contact them with the changes.",
        },
        {
          question: "Can I download the transcript?",
          answer:
            "Yes. Use the download button on the bottom left of Messenger Pigeon.",
        },
        {
          question: "Can I customize how my captions look?",
          answer:
            "You can customize the size, font and colour of your captions using the text tool on the bottom left of Messenger Pigeon.",
        },
        {
          question: "Can I communicate with my captioner?",
          answer:
            "Yes. Using the chat feature on the bottom left of Messenger Pigeon.",
        },
        {
          question: "Does Habitat Learn offer captions in foreign languages?",
          answer:
            "Yes. We currently offer the service in French, Spanish, Russian, German. However new language can be set up upon request.",
        },
        {
          question:
            "How long will it take to set up an account for foreign language captioning?",
          answer:
            "It takes about a week to arrange for a foreign language captioner.",
        },
        {
          question: "How accurate are your captions?",
          answer:
            "Messenger Pigeon uses a blend of AI and perfected by people to ensure captions are both fast and accurate and are compliant with ACA and ADA regulations.",
        },
        {
          question: "What is the format of the notes sent to the students?",
          answer: "All notes are sent in a word document.",
        },
      ],
    };
  },

  methods: {
    updateCollapsed(event) {
      this.iconCollapsed = event;
    },
    toggleAppGrid(event) {
      this.$refs.appGrid.toggle(event);
      if (!this.appGridOpen) {
        this.appGridOpen = true;
      } else {
        this.setAppGridClosed();
      }
    },
    setAppGridClosed() {
      this.appGridOpen = false;
    },
    goToLogin() {
      this.$router.push("/login/login");
    },
    goToSignup() {
      this.$router.push("/login/signup");
    },
    getLatestReleaseUrls() {
      let requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        "https://api.github.com/repos/notetakingexpress/mp-desktop-releases/releases/latest",
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          let data = JSON.parse(result);
          const arr = data.assets;

          const macOSArr = arr.filter(
            (obj) =>
              obj.browser_download_url.includes(".dmg") &&
              !obj.name.includes("blockmap")
          );
          this.macOSLink = macOSArr[0].browser_download_url;

          const windowsArr = arr.filter(
            (obj) =>
              obj.browser_download_url.includes(".exe") &&
              !obj.name.includes("blockmap")
          );
          this.windowsLink = windowsArr[0].browser_download_url;
        })
        .catch((error) => console.log("error", error));
    },
  },

  mounted() {
    this.getLatestReleaseUrls();
  },
};
</script>

<style lang="scss">
.landing {
  min-height: 100vh;
  margin: auto;
  width: 100%;
  max-width: 128rem;
  display: flex;
  flex-direction: column;

  // @include max-width(830) {
  //   width: 60rem;
  // }

  @include max-width(700) {
    width: 100%;
    padding: 0 2.4rem;
    height: 100vh;
  }

  .top-section {
    display: flex;
    justify-content: space-between;
    width: auto;
    .logo-header {
      margin-left: 5rem;
      @include max-width(830) {
        margin-left: 2rem;
      }
    }
    .top-menu {
      display: flex;
      align-items: center;
      margin-top: 3rem;
      margin-bottom: 2rem;

      .rounded-square-button {
        background-color: transparent;
        border: solid 1px #d1d1d1;
        margin: 0rem 4.8rem 0rem 2.4rem;
        @include max-width(830) {
          margin-right: 1rem;
        }
      }
      .text-button {
        // font-family: AvertaCY, Averta, Helvetica, Arial, sans-serif;
        font-size: 1.4rem;
        letter-spacing: 0.125rem;
        color: #f4f4f4;
        margin-right: 2.4rem;
      }
      .header-button {
        font-size: 1.4rem;
        letter-spacing: 0.125rem;
        color: #ffffff;
        padding: 1.2rem 2.4rem;
      }
    }
  }

  .section {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    // overflow-x: hidden;
    margin: 12rem 5rem 8rem;
    justify-content: space-between;

    @include max-width(1200) {
      margin: 12rem 5rem 8rem 5rem;
    }

    @include max-width(1000) {
      margin: 10rem 5rem 8rem 5rem;
    }
    @include max-width(700) {
      margin: 5rem 0rem 5rem 0rem;
    }

    .feature-wrapper {
      display: flex;
      flex-direction: column;

      @include max-width(700) {
        width: 100%;
      }
    }
    .catchphrase {
      font-size: 4.8rem;
      font-weight: 500;
      letter-spacing: -0.05rem;
      // width: 40rem;
      line-height: 7.2rem;
      margin: 0;
      color: #ffffff;

      @include max-width(1200) {
        font-size: 4.8rem;
        line-height: 5.6rem;
        width: 30rem;
      }

      @include max-width(700) {
        font-size: 4rem;
        line-height: 4.8rem;
        width: 30rem;
      }
    }

    .description {
      font-family: AvertaCY, Averta, Helvetica, Arial, sans-serif;
      color: map-get($colours, grey-1);
      font-size: 2rem;
      line-height: 2.4rem;
      letter-spacing: 0.015rem;
      width: 56rem;
      margin: 0.8rem 0 4rem;

      @include max-width(1200) {
        font-size: 1.6rem;
        line-height: 2rem;
        width: 32rem;
      }

      @include max-width(700) {
        width: 100%;
      }
    }

    .top-banner {
      color: map-get($colours, grey-1);
    }

    .nav-buttons {
      display: flex;
      flex-direction: row;
      width: 50rem;
      gap: 1.6rem;
      justify-content: center;

      @include max-width(1200) {
        width: 40rem;
      }

      @include max-width(700) {
        width: 100%;
      }

      .download-button {
        .down-chevron {
          width: 2.4rem;

          &.rotate-cc90 {
            transform: rotate(-90deg);
          }
        }

        .option {
          padding: 0rem 1.6rem;
          height: 4rem;
          background: map-get($colours, accent-purple-3);
          color: map-get($colours, white);
          font-size: 1.6rem;
          text-transform: initial;
          font-family: $font-secondary;
          font-weight: initial;
        }

        .menu {
          // padding: 0.8rem 0;
          height: 10rem;
          background: map-get($colours, accent-purple-3);
          border-radius: 0.6rem;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2),
            0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        .platform-options-menu-wrapper {
          position: absolute;
          top: 0;
          display: none;
          border-bottom: 0.8rem solid transparent;
          transform: translateY(-102%);

          &.small-screen-menu {
            @include max-width(550) {
              transform: scale(90%) translateY(-102%);
            }

            @include max-width(500) {
              left: 0;
              transform: scale(90%) translateY(-105%);
            }

            @include max-width(350) {
              left: 0;
              transform: scale(80%) translateY(-110%);
            }
          }

          .platform-options-menu {
            .platform-option {
              background: map-get($colours, accent-purple-3);
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;

              &:hover {
                background: map-get($colours, accent-purple-4);
                cursor: pointer;
              }

              &.mobile-platform {
                &:hover {
                  .mobile-options-menu {
                    display: flex;
                  }
                }
              }

              &.desktop-platform {
                &:hover {
                  .desktop-options-menu {
                    display: flex;
                  }
                }
              }

              .platform-option-menu {
                position: absolute;
                top: 0;
                right: 0;
                transform: translateX(100%);
                display: none;
                flex-direction: column;
                width: 18rem;

                .platform-option {
                  background: map-get($colours, accent-purple-3);
                  color: map-get($colours, white);
                  font-size: 1.6rem;
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: flex-start;
                  cursor: pointer;
                  text-decoration: none;

                  &:hover {
                    background: map-get($colours, accent-purple-4);
                  }

                  .platform-option-logo {
                    margin-right: 0.8rem;
                    margin-left: 0;
                  }
                }
              }
            }
          }
        }

        @include rwd(700) {
          &:hover {
            .large-screen-menu {
              display: flex;
            }
          }
        }

        @include max-width(700) {
          &:hover {
            .small-screen-menu {
              display: flex;
            }
          }
        }
      }
      .demo-button {
        width: 17.1rem;
        height: 5.6rem;
        justify-content: center;
      }
    }

    .feature-img {
      height: 50.2rem;
      width: 41.7rem;
      margin-left: 14.6rem;
      margin-right: 10.6rem;
      margin-top: -5%;
      z-index: -1;

      @include max-width(1200) {
        height: 45rem;
        margin-left: 7rem;
      }

      @include max-width(1000) {
        height: 35rem;
        margin-left: 7rem;
      }

      @include max-width(830) {
        transform: translateX(-25%);
        height: 37rem;
        margin-left: 7rem;
        margin-right: 0rem;
      }

      @include max-width(700) {
        display: none;
      }
    }
    .left {
      margin-right: 9rem;
      margin-left: 0rem;

      @include max-width(830) {
        transform: translateX(10%);
        height: 37rem;
        margin-left: 2rem;
        margin-right: 2rem;
      }
    }
    .center-banner {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 90vw;

      .catchphrase {
        width: 59.5rem;
        text-align: center;
        margin-bottom: 0.6rem;
        font-size: 6rem;
        @include max-width(1200) {
          font-size: 4.8rem;
          line-height: 5.6rem;
        }

        @include max-width(700) {
          font-size: 4rem;
          line-height: 4.8rem;
          width: 30rem;
        }
      }
      .video-wrapper video {
        width: 118.2rem;
        height: 66.5rem;
        border-radius: 1.2rem;
        margin: 2rem;

        @include max-width(1200) {
          width: 77.8rem;
          height: 43.8rem;
        }

        @include max-width(830) {
          width: 64.5rem;
          height: 36.63rem;
        }

        @include max-width(700) {
          width: 36.45rem;
          height: 20.5rem;
        }
      }
      .description {
        color: map-get($colours, grey-1);
        width: 51.4rem;
        height: 4.8rem;
        font-weight: bold;
        text-align: center;
        margin-top: 7.2rem;
        margin-bottom: 5.6rem;

        @include max-width(1200) {
          font-size: 1.6rem;
          line-height: 2rem;
          width: 42rem;
        }

        @include max-width(700) {
          width: 100%;
        }
      }
      .nav-buttons {
        width: 24rem;
      }

      .faq-card {
        width: 100%;
        .p-accordion-tab {
          background-color: map-get($colours, accent-purple-2);
          font-family: AvertaCY, Averta, Helvetica, Arial, sans-serif;
          margin-bottom: 1rem;
          box-shadow: 0px 2px map-get($colours, accent-purple-3);
          border-radius: 0px;
          @include max-width(1200) {
            font-size: 2rem;
            line-height: 2rem;
            width: 100%;
          }

          @include max-width(700) {
            width: 100%;
          }
          .p-accordion-header {
            background-color: map-get($colours, accent-purple-2);
            .p-accordion-header-link {
              background-color: map-get($colours, accent-purple-2);
            }
            .p-accordion-header-text {
              color: map-get($colours, grey-1);
              font-size: 2.4rem;
              line-height: 3.2rem;
              letter-spacing: 0.018rem;
              font-weight: bold;
              margin: 3.2rem 0 4rem;
              @include max-width(700) {
                width: 70vw;
              }
            }
            .p-accordion-toggle-icon {
              color: white;
            }
          }
          .p-accordion-content {
            background-color: map-get($colours, accent-purple-2);
            .faq-answer {
              color: map-get($colours, grey-1);
              font-size: 1.8rem;
              line-height: 2.4rem;
              letter-spacing: 0.015rem;
              margin: 0rem 0 4.2rem;
              width: 100%;
              @include max-width(1200) {
                font-size: 1.6rem;
                line-height: 2rem;
                // width: 100%;
              }

              // @include max-width(700) {
              //   width: 100%;
              // }
            }
          }
        }
      }
    }
  }
  .feature-grid-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10.5rem;
    margin: 14.4rem 5rem 8.4rem;

    @include max-width(1200) {
      grid-template-columns: repeat(2, 1fr);
      margin: auto;
    }

    @include max-width(700) {
      display: inline;
      margin-top: 5rem;
    }
    .circle {
      background: #877594 0.6%;
      border-radius: 50%;
      height: 7rem;
      width: 7rem;
      margin: auto;
      padding: 1.2rem;
      img {
        height: 4.4rem;
        width: 4.4rem;
        margin-left: auto;
        margin-right: auto;
      }
    }
    .feature-description {
      font-family: Manrope;
      font-size: 1.6rem;
      line-height: 2.4rem;
      letter-spacing: 0.015rem;
      font-weight: 500;
      text-align: center;
      width: 21.7rem;
    }
  }
  .footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-family: $font-secondary;
    font-size: 1.6rem;
    letter-spacing: 0.025rem;
    line-height: 2.4rem;
    color: #f4f4f4;
    margin: 0 5rem 1rem;
    @include max-width(700) {
      font-size: 1.4rem;
    }
    .socials {
      .info-row {
        display: flex;
        flex-direction: row;
        gap: 2.4rem;
        align-items: center;

        .social-button {
          background: transparent;

          img {
            height: 2.4rem;
            width: 2.4rem;
          }

          &:hover {
            // filter: brightness(20%);
            // background: transparent;
          }
        }
      }
    }
  }
}
</style>
