<template>
  <div id="waveformContainer" />
</template>

<script setup lang="ts">
import { ref, Ref, onUnmounted, watch } from "vue";
import WaveSurfer from "wavesurfer.js";
import { makeMPServerRequest } from "../../assets/helpers";
import { useStore } from "vuex";
import { AUDIO_VISUALIZER_DEFAULT_PEAKS } from "../../assets/constants";
import RegionsPlugin from "wavesurfer.js/dist/plugins/regions.js";

interface AudioSample {
  timestamp: number;
  meanVolume: number;
}

interface Bookmark {
  color: string;
  id: string;
  text?: string;
  timestamp: number;
  updateAt: string;
  uploadId: string;
  userId: string;
  waveformRegionId?: string;
  paragraphTimestamp: number;
}

interface Props {
  audioComponent: HTMLMediaElement;
}

const store = useStore();
const props = defineProps<Props>();

const wavesurfer: Ref<WaveSurfer | null> = ref(null);
const wavesurferRegions: Ref<RegionsPlugin | null> = ref(null);

const emit = defineEmits(["playbackAt"]);

let peakData: number[] = [];

function filterPeakData(samples: AudioSample[]): number[] {
  return samples.map((item: AudioSample) => {
    return item.meanVolume;
  });
}

/**
 * Normalizes the peak data to <= NORMALIZATION_MAX to ensure the bar heights don't fill entire container height
 */
function normalizePeakData(peakData: number[]): number[] {
  const NORMALIZATION_MAX = 0.8;

  // Find the maximum value in the array
  const maxPeak = Math.max(...peakData);

  // If the maximum is already less than NORMALIZATION_MAX, no scaling is needed
  if (maxPeak <= NORMALIZATION_MAX) {
    return peakData;
  }

  // Calculate the normalization factor such that the highest value becomes NORMALIZATION_MAX
  const normalizationFactor = NORMALIZATION_MAX / maxPeak;

  // Normalize the array
  const normalizedPeaks = peakData.map((value) => value * normalizationFactor);

  return normalizedPeaks;
}

async function initializeWaveform() {
  try {
    const data = await makeMPServerRequest(
      `self/uploads/${store.state.sessionDetails.uploads.id}/audio-samples`,
      "GET",
      "json"
    );

    if (data.status === "error") {
      // If there's an issue loading peak data, use default data
      peakData = AUDIO_VISUALIZER_DEFAULT_PEAKS;
      console.log("Error retrieving audio samples: " + data.message);
    } else {
      // Extract only the meanVolumes, ie. peaks
      const samples: AudioSample[] = data.data.samplesDesktop;
      peakData = filterPeakData(samples);
    }
  } catch (error) {
    peakData = AUDIO_VISUALIZER_DEFAULT_PEAKS;
    console.log("Error retrieving audio samples: " + error);
  }

  wavesurfer.value = WaveSurfer.create({
    container: "#waveformContainer",
    waveColor: "#877594",
    progressColor: "rgba(255, 255, 255, 0.4)",
    barWidth: 2,
    barRadius: 6,
    height: 40,
    width: "100%",
    barGap: 4,
    peaks: [normalizePeakData(peakData)],
    media: props.audioComponent,
  });

  const waveform = document.querySelector(
    "#waveformContainer > div:first-child"
  );

  const shadowRoot = waveform?.shadowRoot;

  if (shadowRoot) {
    const style = document.createElement("style");
    style.textContent = `
          .progress {
              background-color: #6d5b79;
          }
      `;
    shadowRoot.appendChild(style);
  }
  wavesurferRegions.value =
    wavesurfer.value?.registerPlugin(RegionsPlugin.create()) || null;
}

function updateBookmarkList(bookmarkList: Array<Bookmark>) {
  try {
    // For some reason, the remove and clearRegions throws an error
    // TODO: use remove or clearRegions to remove old values
    wavesurferRegions.value?.destroy();
    wavesurferRegions.value =
      wavesurfer.value?.registerPlugin(RegionsPlugin.create()) || null;

    bookmarkList.forEach((bookmark) => {
      const region = wavesurferRegions.value?.addRegion({
        start: bookmark.paragraphTimestamp / 1000,
        resize: false,
        drag: false,
        color: bookmark.color,
      });
      bookmark.waveformRegionId = region?.id || "";
      wavesurferRegions.value?.on("region-clicked", function (e) {
        emit("playbackAt", e.start);
      });
    });
  } catch (error) {
    console.error(error);
  }
}


watch(() => props.audioComponent, function (newComponent: HTMLMediaElement) {
  if (wavesurfer.value && newComponent) {
    wavesurfer.value.setMediaElement(newComponent);
  }
})

initializeWaveform();

onUnmounted(() => {
  try {
    wavesurferRegions.value?.destroy();
  } catch (error) {
    console.error(error);
  }

  try {
    wavesurfer.value?.destroy();
  } catch (error) {
    console.error(error);
  }
});

defineExpose({
  updateBookmarkList,
});
</script>

<style lang="scss">
#waveformContainer {
  width: 100%;
}
</style>
