<template>
  <div class="audio-player">
    <div class="controls">
      <span v-if="!isInfoPanelOpen" class="button-placeholder" />
      <IconButton
        :icon="Rewind10WhiteIcon"
        alt="Rewind audio"
        :onclick="skipBackward"
        xsmall
        v-tooltip.top="'Rewind ' + AUDIO_SKIP_AMOUNT + 's'"
      />
      <IconButton
        :icon="isPaused && audioPaused ? PlayWhiteIcon : PauseWhiteIcon"
        alt="Toggle audio playing"
        :onclick="togglePlay"
        xsmall
        v-tooltip.top="isPaused ? 'Play' : 'Pause'"
      />
      <IconButton
        :icon="SkipForward10WhiteIcon"
        alt="Fast forward audio"
        :onclick="skipForward"
        xsmall
        v-tooltip.top="'Skip forward ' + AUDIO_SKIP_AMOUNT + 's'"
      />

      <div class="audio-timeline" :class="isInfoPanelOpen && 'shrink'">
        <div class="audio-title">{{ mediaName }}</div>
        <div class="timeline-wrapper">
          <div class="time">
            <div class="current">{{ currentTime }}</div>
            <!-- <div class="divider">/</div> -->
            <div class="length">{{ totalLength }}</div>
          </div>
          <div class="timeline">
            <div class="time-preview">{{ previewTime }}</div>
            <div class="progress" />
          </div>
        </div>
      </div>
      <div class="button-with-dropdown-attached gap">
        <IconButton
          :icon="PlaybackSpeedWhiteIcon"
          alt="Playback speed"
          xsmall
          v-tooltip.top="'Playback speed'"
        />
        <div class="dropdown-menu right">
          <button
            class="dropdown-menu-button text-normal"
            @click="setPlaybackSpeed(0.25)"
          >
            0.25x
          </button>
          <button
            class="dropdown-menu-button text-normal"
            @click="setPlaybackSpeed(0.5)"
          >
            0.5x
          </button>
          <button
            class="dropdown-menu-button text-normal"
            @click="setPlaybackSpeed(1)"
          >
            1x
          </button>
          <button
            class="dropdown-menu-button text-normal"
            @click="setPlaybackSpeed(2)"
          >
            2x
          </button>
          <button
            class="dropdown-menu-button text-normal"
            @click="setPlaybackSpeed(3)"
          >
            3x
          </button>
        </div>
      </div>
      <IconButton
        :icon="AudioVisualizationWhiteIcon"
        alt="Audio visualization"
        :onclick="toggleAudioVisualization"
        xsmall
        v-tooltip.top="'Audio visualization'"
      />
      <div class="volume-container">
        <IconButton
          :icon="isMuted ? VolumeMutedWhiteIcon : VolumeOnWhiteIcon"
          alt="Play audio"
          :onclick="toggleMuted"
          xsmall
          v-tooltip.top="'Volume'"
        />
        <div class="volume-slider">
          <div class="volume-percentage"></div>
        </div>
      </div>
      <IconButton
        :icon="TimestampWhiteIcon"
        alt="Insert Timestamp"
        class="text-formatting-button"
        v-tooltip.top="'Insert timestamp'"
        xsmall
        :onclick="insertCurrentTimestamp"
      />
      <IconButton
        :v-if="addBookmark !== null"
        :icon="BookmarkIcon"
        alt="Bookmark"
        class="text-formatting-button"
        v-tooltip.top="'Bookmark'"
        xsmall
        :onclick="addBookmark"
      />
    </div>
  </div>
</template>

<script>
import IconButton from "../IconButton";
import VolumeOnWhiteIcon from "../../assets/images/Volume-on-white.svg";
import VolumeMutedWhiteIcon from "../../assets/images/Volume-muted-white.svg";
import Rewind10WhiteIcon from "../../assets/images/Rewind-10-white.svg";
import SkipForward10WhiteIcon from "../../assets/images/Skip-forward-10-white.svg";
import PlayWhiteIcon from "../../assets/images/Play-white.svg";
import PauseWhiteIcon from "../../assets/images/Pause-white.svg";
import PlaybackSpeedWhiteIcon from "../../assets/images/Playback-speed-white.svg";
import AudioVisualizationWhiteIcon from "../../assets/images/Audio-visualization-white.svg";
import TimestampWhiteIcon from "../../assets/images/Timestamp-white.svg";
import BookmarkIcon from "../../assets/images/Bookmark-white.svg";
import { getTimeCodeFromNum } from "../../assets/helpers_legacy";

export default {
  props: {
    audioComponent: Object,
    audioVisualization: Boolean,
    transcriptAudioLength: Number,
    audioPaused: Boolean,
    audioVolume: Number,
    playbackSpeed: Number,
    setPlaybackSpeed: Function,
    AUDIO_SKIP_AMOUNT: Number,
    insertCurrentTimestamp: Function,
    mediaName: String,
    isInfoPanelOpen: Boolean,
    addBookmark: {
      type: Function,
      default: null,
    },
  },

  components: {
    IconButton,
  },

  data() {
    return {
      VolumeOnWhiteIcon,
      VolumeMutedWhiteIcon,
      Rewind10WhiteIcon,
      SkipForward10WhiteIcon,
      PlayWhiteIcon,
      PauseWhiteIcon,
      PlaybackSpeedWhiteIcon,
      AudioVisualizationWhiteIcon,
      TimestampWhiteIcon,
      BookmarkIcon,
      currentTime: "0:00",
      previewTime: "",
      totalLength: "",
      isMuted: this.audioComponent.muted,
      isPaused: this.audioComponent.paused,
      showAudioVisualization: this.audioVisualization,
      audioPlayer: null,
    };
  },

  watch: {
    showAudioVisualization: function (newVal) {
      this.$emit("updateShowAudioVisualization", newVal);
    },

    audioVolume: function (newVal) {
      this.audioPlayer.querySelector(
        ".controls .volume-percentage"
      ).style.width = newVal * 100 + "%";

      // this.audioPlayer.querySelector(".controls .volume-slider").style.left =
      //   -123 + "px";

      // this.audioPlayer.querySelector(".controls .volume-slider").style.width =
      //   -120 + "px";
    },
  },

  mounted() {
    this.audioPlayer = document.querySelector(".audio-player");

    this.totalLength = getTimeCodeFromNum(this.transcriptAudioLength);

    this.audioComponent.addEventListener(
      "loadeddata",
      () => {
        this.totalLength = getTimeCodeFromNum(this.audioComponent.duration);
        this.$emit("updateVolume", 0.75);
      },
      false
    );

    //click on timeline to skip around
    const timeline = this.audioPlayer.querySelector(".timeline");
    timeline.addEventListener(
      "click",
      (e) => {
        const timelineWidth = window.getComputedStyle(timeline).width;
        const timeToSeek =
          (e.offsetX / parseInt(timelineWidth)) * this.audioComponent.duration;
        this.$emit("updateCurrentTime", timeToSeek);
      },
      false
    );

    timeline.addEventListener(
      "mouseleave",
      () => {
        const timePreview = this.audioPlayer.querySelector(
          ".timeline .time-preview"
        );
        timePreview.style.opacity = 0;
      },
      false
    );

    timeline.addEventListener(
      "mousemove",
      (e) => {
        const timelineWidth = window.getComputedStyle(timeline).width;
        const timePreview = this.audioPlayer.querySelector(
          ".timeline .time-preview"
        );
        const positionPercentage = e.offsetX / parseInt(timelineWidth);
        timePreview.style.left = positionPercentage * 100 + "%";
        timePreview.style.opacity = 1;
        const timeToSeek = positionPercentage * this.audioComponent.duration;
        // this.$emit("updateCurrentTime", timeToSeek);
        this.previewTime = getTimeCodeFromNum(timeToSeek);
      },
      false
    );

    //click volume slider to change volume
    const volumeSlider = this.audioPlayer.querySelector(
      ".controls .volume-slider"
    );
    volumeSlider.addEventListener(
      "click",
      (e) => {
        const sliderWidth = window.getComputedStyle(volumeSlider).width;
        const newVolume = e.offsetX / parseInt(sliderWidth);
        this.$emit("updateVolume", newVolume);
      },
      false
    );

    //check audio percentage and update time accordingly
    setInterval(() => {
      const progressBar = this.audioPlayer.querySelector(".progress");
      progressBar.style.width =
        (this.audioComponent.currentTime / this.audioComponent.duration) * 100 +
        "%";
      this.currentTime = getTimeCodeFromNum(this.audioComponent.currentTime);
    }, 500);
  },

  methods: {
    toggleMuted() {
      const newVal = !this.audioComponent.muted;
      this.$emit("updateMuted", newVal);
      this.isMuted = newVal;
    },

    togglePlay() {
      if (this.audioComponent.paused) {
        this.audioComponent.play();
        this.isPaused = false;
      } else {
        this.audioComponent.pause();
        this.isPaused = true;
      }
    },

    toggleAudioVisualization() {
      this.showAudioVisualization = !this.showAudioVisualization;
      this.$emit("toggleAudioVisualization", this.showAudioVisualization);
    },

    skipForward() {
      this.$emit(
        "updateCurrentTime",
        this.audioComponent.currentTime + this.AUDIO_SKIP_AMOUNT
      );
    },

    skipBackward() {
      this.$emit(
        "updateCurrentTime",
        this.audioComponent.currentTime - this.AUDIO_SKIP_AMOUNT
      );
    },
  },
};
</script>

<style lang="scss">
.audio-player {
  // background: map-get($colours, accent-purple-4);
  // border-radius: 0.8rem;
  // box-shadow: 0 0 20px 0 #000a;
  // box-shadow: 0 0.4rem 0.5rem rgba(0, 0, 0, 0.14),
  //   0 0.1rem 1rem rgba(0, 0, 0, 0.12), 0 0.2rem 0.4rem rgba(0, 0, 0, 0.2);
  font-family: arial;
  color: white;
  font-size: 1.2rem;
  // overflow: hidden;

  display: grid;
  grid-template-rows: 6px auto;

  .controls {
    height: 6.4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    // column-gap: 1.6rem;

    > * {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.8rem;
      height: initial;

      @media only screen and (max-width: 950px) {
        padding: 0.6rem;
      }
    }

    .button-placeholder {
      width: 4rem;

      @media only screen and (max-width: 1000px) {
        display: none;
      }
    }

    .audio-timeline {
      // width: 20rem;
      display: flex;
      flex-direction: column;
      height: 4rem;
      background: map-get($colours, white);
      border-radius: 0.4rem;
      padding: 0 0.4rem;
      margin: 0 0.8rem;
      width: 20rem;

      @media only screen and (max-width: 1000px) {
        &.shrink {
          width: 15rem;
        }
      }

      .audio-title {
        color: map-get($colours, black);
        @include caption;
        max-width: 90%;
        text-overflow: ellipsis;
        color: black;
        overflow: hidden;
        white-space: nowrap;
      }

      .timeline-wrapper {
        width: 100%;

        .time {
          display: flex;
          justify-content: space-between;
          width: 100%;
          font-size: 1rem;
          color: map-get($colours, grey-7);
          font-family: $font-secondary;

          > * {
            padding: 2px;
          }

          .length {
            color: map-get($colours, grey-5);
          }
        }

        .timeline {
          // background: map-get($colours, accent-purple-1);
          background: map-get($colours, grey-2);
          width: 100%;
          height: 0.3rem;
          position: relative;
          cursor: pointer;
          // border-radius: 0.8rem 0.8rem 0 0;
          // box-shadow: 0 2px 10px 0 #0008;

          .time-preview {
            // height: 2rem;
            // width: 3rem;
            padding: 0.8rem;
            position: absolute;
            background: map-get($colours, accent-purple-4);
            font-size: 1.2rem;
            // top: -100%;
            // left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: translateX(-50%) translateY(-100%);
            pointer-events: none;
            opacity: 0;
            transition: opacity 0.2s;
            border-radius: 0.8rem;
          }

          .progress {
            background: map-get($colours, accent-purple-3);
            width: 0%;
            height: 100%;
            transition: 0.25s;
          }
        }
      }
    }

    .volume-container {
      cursor: pointer;
      position: relative;
      z-index: 2;

      .volume-button {
        height: 2.6rem;
        display: flex;
        align-items: center;

        .volume {
          transform: scale(0.7);
        }
      }

      .volume-slider {
        position: absolute;
        left: -0.2rem;
        z-index: -1;
        width: 0;
        height: 15px;
        background: white;
        // box-shadow: 0 0 20px #000a;
        transition: 0.25s;

        .volume-percentage {
          background: map-get($colours, accent-purple-3);
          height: 100%;
          width: 75%;
        }
      }

      &:hover {
        .volume-slider {
          left: -123px;
          width: 120px;
        }
      }
    }
  }

  .p-inputswitch {
    width: 3.75rem;
    height: 1.5rem;
  }

  .p-inputswitch .p-inputswitch-slider {
    border-radius: 1.5rem;
    background: map-get($colours, grey-3);

    &:hover {
      background: map-get($colours, grey-3);
    }
  }

  .p-inputswitch-checked .p-inputswitch-slider {
    background: map-get($colours, accent-purple-1);

    &:hover {
      background: map-get($colours, accent-purple-1);
    }
  }

  .p-inputswitch .p-inputswitch-slider:before {
    width: 2.4rem;
    height: 2.4rem;
    margin-top: -1.2rem;
    background: map-get($colours, white);
    background-image: url("../../assets/images/AudioLines-grey.svg");
    background-repeat: no-repeat;
    background-position: center;
  }

  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    background: map-get($colours, white);
    background-image: url("../../assets/images/AudioLines.svg");
    background-repeat: no-repeat;
    background-position: center;
  }
}
</style>
