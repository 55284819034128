<template>
  <Dialog
    :visible="visible"
    modal
    class="onboarding-dialog"
    :header="false"
    :closable="false"
    v-if="step <= NUM_INSTRUCTIONAL_STEPS"
  >
    <IconButton
      :icon="CloseWhiteIcon"
      class="close-button"
      alt="Close menu"
      :onclick="onClose"
      dark
      tiny
    />
    <div class="top-content" :class="step === 0 && 'welcome-page'">
      <div v-if="step === 0" class="welcome-wrapper">
        <img :src="LogoFlat" class="logo" />
        <h1 class="welcome">Welcome to Messenger Pigeon</h1>
      </div>
      <img
        v-else-if="step === 1"
        :src="OnboardingRecordingImage"
        class="laptop-image"
      />
      <img
        v-else-if="step === 2"
        :src="OnboardingNotetakingViewImage"
        class="laptop-image"
      />
      <img
        v-else-if="step === 3"
        :src="OnboardingRecordNotesImage"
        class="record-notes-image"
      />
      <img
        v-else-if="step === 4"
        :src="OnboardingSessionBrowserImage"
        class="laptop-image"
      />
      <img
        v-else-if="step === 5"
        :src="OnboardingStudyAssistantImage"
        class="laptop-image"
      />
    </div>
    <div class="bottom-content">
      <div class="text-content">
        <p class="title">
          {{
            step === 0
              ? "Let's take a quick tour"
              : step === 1
              ? "Record or upload"
              : step === 2
              ? "Like magic,"
              : step === 5
              ? "With Study Assistant"
              : ""
          }}
        </p>
        <p
          class="subtitle"
          :style="step === 4 && 'margin-left: 5.6rem; margin-right: 5.6rem'"
        >
          {{
            step === 0
              ? ""
              : step === 1
              ? "classes, meetings, or anything you like!"
              : step === 2
              ? "a transcript will be generated by our Ai"
              : step === 3
              ? "Then, you can order notes from our professional note-takers"
              : step === 4
              ? "Your notes will be delivered directly to the app."
              : step === 5
              ? "access to insightful analysis, keywords, and quizzes to enhance your learning journey!"
              : ""
          }}
        </p>
      </div>
      <div
        class="button-section"
        :style="step === 0 && 'justify-content: flex-end'"
      >
        <div class="progress-dots" v-if="step > 0">
          <button
            class="dot"
            :class="step === 1 && 'current-dot'"
            @click="step = 1"
          />
          <button
            class="dot"
            :class="step === 2 && 'current-dot'"
            @click="step = 2"
          />
          <button
            class="dot"
            :class="step === 3 && 'current-dot'"
            @click="step = 3"
          />
          <button
            class="dot"
            :class="step === 4 && 'current-dot'"
            @click="step = 4"
          />
          <button
            class="dot"
            :class="step === 5 && 'current-dot'"
            @click="step = 5"
          />
        </div>
        <RoundedButton
          :text="
            step === 0
              ? 'Start tour'
              : step === NUM_INSTRUCTIONAL_STEPS
              ? 'Let\'s go!'
              : 'Next'
          "
          fitWidth
          accent
          lessRound
          uppercase
          class="action-button"
          @click="nextStep"
        />
        <UnderlinedButton
          text="Skip"
          class="skip-button"
          mediumLight
          noUnderline
          @click="visible = false"
          v-if="step < NUM_INSTRUCTIONAL_STEPS"
        />
      </div>
    </div>
  </Dialog>
  <SubscriptionSignup :setJustSubscribed="setJustSubscribed" v-else />
</template>

<script>
import LogoFlat from "../../assets/logo/icon-flat.svg";
import RoundedButton from "../../components/RoundedButton";
import UnderlinedButton from "../../components/UnderlinedButton";
import IconButton from "../../components/IconButton";
import SubscriptionSignup from "./SubscriptionSignup";
import OnboardingRecordingImage from "../../assets/images/Onboarding-recording.png";
import OnboardingNotetakingViewImage from "../../assets/images/Onboarding-notetaking-view.png";
import OnboardingRecordNotesImage from "../../assets/images/Onboarding-record-notes.png";
import OnboardingSessionBrowserImage from "../../assets/images/Onboarding-session-browser.png";
import OnboardingStudyAssistantImage from "../../assets/images/Onboarding-study-assistant.png";
import ChevronLeftWhiteIcon from "../../assets/images/Chevron-left-white-2.svg";
import CloseWhiteIcon from "../../assets/images/Close-white.svg";

export default {
  props: {
    setJustSubscribed: Function,
  },

  components: {
    RoundedButton,
    UnderlinedButton,
    IconButton,
    SubscriptionSignup,
  },

  data() {
    return {
      LogoFlat,
      OnboardingRecordingImage,
      OnboardingNotetakingViewImage,
      OnboardingRecordNotesImage,
      OnboardingSessionBrowserImage,
      OnboardingStudyAssistantImage,
      ChevronLeftWhiteIcon,
      CloseWhiteIcon,

      NUM_INSTRUCTIONAL_STEPS: 5,

      visible: true,
      step: 0,
    };
  },

  methods: {
    onClose() {
      this.visible = false;
    },

    nextStep() {
      this.step++;
    },
  },
};
</script>

<style lang="scss">
.onboarding-dialog {
  background-color: map-get($colours, accent-purple-3);
  color: map-get($colours, white);
  border-radius: 1.2rem;
  overflow: hidden;
  box-shadow: 0 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.2),
    0 0.3rem 1.4rem 0 rgba(0, 0, 0, 0.12), 0 0.8rem 1rem 0 rgba(0, 0, 0, 0.14);
  width: 45rem;

  .close-button {
    position: absolute;
    top: 1.6rem;
    right: 1.6rem;
  }

  .p-dialog-header {
    display: none;
  }

  .p-dialog-content {
    background-color: inherit;
    color: inherit;
    padding: 0;
    overflow-x: hidden;

    .top-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0;
      height: 33rem;
      background-color: map-get($colours, accent-purple-2);

      &.welcome-page {
        background-color: inherit;
        padding: 5.6rem 2.4rem 0;
        justify-content: initial;
      }

      .welcome-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        .logo {
          height: 8rem;
          width: 8rem;
        }

        .welcome {
          margin-top: 6rem;
          text-align: center;
          padding: 0 2.4rem;
        }
      }

      .laptop-image {
        width: 38rem;
      }

      .record-notes-image {
        width: 36rem;
        margin-top: 3.2rem;
      }
    }

    .bottom-content {
      height: 24rem;
      padding: 2.4rem 2.4rem 3rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .text-content {
        text-align: center;

        .title {
          font-size: 2rem;
          font-weight: bold;
          margin: 0;
        }

        .subtitle {
          font-size: 1.8rem;
          margin: 0.8rem 1.6rem 2.4rem;
        }
      }

      .button-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 10rem;

        .progress-dots {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: flex-start;
          margin-bottom: 1.6rem;

          .dot {
            height: 0.8rem;
            width: 0.8rem;
            border-radius: 1.4rem;
            background-color: map-get($colours, accent-purple-4);
            margin: 0 0.6rem;
            padding: 0;
          }

          .current-dot {
            width: 1.8rem;
            background-color: map-get($colours, accent-purple-1);
          }
        }

        .skip-button {
          margin-top: 1.8rem;
        }
      }
    }
  }
}
</style>
