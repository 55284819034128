<template>
  <div class="language-settings">
    <SettingsBlock title="Captions language">
      <div class="main">
        <div class="restart-stt-note">
          <p>Restarting STT is required to apply the change of language.</p>
        </div>
        <div class="language-selection-section">
          <p class="subtitle">
            Choose which language to be displayed by the Speech-to-Text AI.
          </p>
          <div
            class="language-option"
            v-for="languageOption in availableLanguages"
            :key="languageOption.code"
          >
            <RadioButton
              :id="languageOption.code"
              name="captionsLanguage"
              @change="changeLanguage(this.language)"
              v-model="language"
              :value="languageOption"
            />
            <label :for="languageOption.code">{{
              languageOption.language
            }}</label>
          </div>
        </div>
      </div>
    </SettingsBlock>
  </div>
</template>

<script>
import SettingsBlock from "./SettingsBlock";

export default {
  props: {
    closeSettings: Function,
    setShowSettingsMainMenu: Function,
    changeLanguage: Function,
    parentLanguage: Object,
  },

  components: {
    SettingsBlock,
  },

  watch: {
    parentLanguage: function (newVal) {
      this.language = newVal;
    },
  },

  data() {
    return {
      language: this.parentLanguage,
      availableLanguages: [
        { language: "English (Canada)", code: "en-CA" },
        { language: "English (UK)", code: "en-GB" },
        { language: "English (US)", code: "en-US" },
        { language: "French", code: "fr-FR" },
        { language: "French (Canada)", code: "fr-CA" },
        { language: "Spanish", code: "es-ES" },
        { language: "Spanish (US)", code: "es-US" },
      ],
    };
  },
};
</script>

<style lang="scss">
.language-settings {
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;

  .main {
    flex: 1;

    h2 {
      font-size: 1.8rem;
      font-family: $font-default;
      font-weight: bold;
      margin: 0;
    }

    .restart-stt-note {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px 16px 0px 24px;
      gap: 16px;
      margin-top: 0.8rem;
      background: map-get($colours, accent-purple-2);
      box-shadow: inset 0.8rem 0 0 #00c9af;
    }

    .language-option {
      margin-top: 1.8rem;

      label {
        font-family: $font-secondary;
        margin-left: 1rem;
        letter-spacing: 0.03rem;
      }
    }

    .language-selection-section {
      display: flex;
      flex-direction: column;
      align-items: stretch;

      .subtitle {
        color: map-get($colours, grey-3);
        margin: 1.6rem 0 0;
        font-family: $font-secondary;
        font-size: 1.2rem;
      }
    }
  }
}
</style>
