<template>
  <button
    :class="['text-button', light && 'light']"
    :disabled="disabled"
    @click="onClick"
    :type="type"
  >
    <span
      :class="[
        'text',
        underline ? 'underline' : '',
        small ? 'small' : '',
        uppercase ? 'uppercase' : '',
      ]"
      >{{ text }}</span
    ><span class="arrows" v-if="arrows">>></span>
  </button>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
    onClick: Function,
    text: String,
    type: String,
    light: Boolean,
    underline: Boolean,
    arrows: Boolean,
    small: Boolean,
    uppercase: Boolean,
  },
};
</script>

<style lang="scss">
.text-button {
  font-size: 1.6rem;
  color: map-get($component-colours, text-button-font-colour-dark);
  font-family: $font-secondary;
  background: none;
  padding: 0;
  font-weight: 500;

  &:hover {
    // color: lighten(
    //   map-get($component-colours, text-button-font-colour-dark),
    //   30%
    // );
  }

  &.light {
    color: #d1d1d1;
  }

  .text {
    &.underline {
      text-decoration: underline;
    }

    &.small {
      @include callout;
    }

    &.uppercase {
      text-transform: uppercase;
      letter-spacing: 0.125rem;
    }
  }

  .arrows {
    margin-left: 0.3rem;
    letter-spacing: -0.3rem;
  }
}
</style>
