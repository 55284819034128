<template>
  <div class="pdf-viewer-control-bar">
    <IconButton
      :icon="ArrowLastLeftWhite"
      alt="First page"
      xsmall
      @click="switchToPdfPage(1)"
    />
    <IconButton
      :icon="ChevronLeftWhite"
      alt="Previous page"
      xxxsmall
      @click="switchToPdfPage(currentPageNumber - 1)"
    />
    <div class="page-numbers">
      {{ currentPageNumber + " / " + numPages }}
    </div>
    <IconButton
      :icon="ChevronLeftWhite"
      alt="Next page"
      xxxsmall
      rotated
      @click="switchToPdfPage(currentPageNumber + 1)"
    />
    <IconButton
      :icon="ArrowLastLeftWhite"
      alt="Last page"
      xsmall
      rotated
      @click="switchToPdfPage(numPages)"
    />
    <div class="divider" />
    <IconButton
      :icon="ThumbnailsWhite"
      alt="Page overview"
      xsmall
      rotated
      v-tooltip.top="'Page overview'"
      class="page-overview-button"
      :class="overviewPanelVisible && 'active'"
      @click="setOverviewPanelVisible(true)"
    />
  </div>
</template>

<script>
import IconButton from "../../components/IconButton.vue";
import ChevronLeftWhite from "../../assets/images/Chevron-left-white-2.svg";
import ArrowLastLeftWhite from "../../assets/images/Arrow-last-left-white.svg";
import ThumbnailsWhite from "../../assets/images/Thumbnails-white.svg";

export default {
  props: {
    currentPageNumber: Number,
    numPages: Number,
    switchToPdfPage: Function,
    overviewPanelVisible: Boolean,
    setOverviewPanelVisible: Function,
  },

  components: {
    IconButton,
  },

  data() {
    return {
      ChevronLeftWhite,
      ArrowLastLeftWhite,
      ThumbnailsWhite,
    };
  },
};
</script>

<style lang="scss">
.pdf-viewer-control-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;

  .page-numbers {
    font-size: 1.4rem;
    font-weight: bold;
    background-color: map-get($colours, accent-purple-3);
    height: 4rem;
    width: 7rem;
    border-radius: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .divider {
    width: 0.1rem;
    background-color: rgba(25, 25, 25, 0.3);
    height: 2.4rem;
  }

  .page-overview-button {
    transition: all 0.4s ease;

    &.active {
      background-color: transparent;
      filter: brightness(60%);
    }
  }
}
</style>
