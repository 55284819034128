<template>
  <Dialog
    :visible="props.visible"
    :modal="props.modal"
    class="paywall-modal"
    :closable="false"
  >
    <template #header>
      <IconButton
        :icon="CloseGreyIcon"
        class="close-button"
        alt="Close dialog"
        :onclick="
          () => {
            setVisible(false);
          }
        "
        xsmall
      />
      <h1>Get full access</h1>
      <img :src="PersonWithPigeonBalloon" class="header-image" />
    </template>
    <div class="content">
      <div class="features">
        <div class="feature">
          <div class="image-wrapper">
            <img :src="MicIcon" alt="Microphone" />
          </div>
          <div class="feature-text">
            <p class="feature-title">Unlimited Audio Recording</p>
            <p class="feature-description">
              Record or upload without limits and capture every moment on our
              cloud
            </p>
          </div>
        </div>
        <div class="feature">
          <div class="image-wrapper">
            <img :src="WandIcon" alt="Magic wand" />
          </div>
          <div class="feature-text">
            <p class="feature-title">Study Assistant</p>
            <p class="feature-description">
              Access to insightful analysis, keywords, and quizzes to enhance
              your learning
            </p>
          </div>
        </div>
        <div class="feature">
          <div class="image-wrapper">
            <img :src="CaptioningNotesIcon" alt="Captioning" />
          </div>
          <div class="feature-text">
            <p class="feature-title">Live Captioning</p>
            <p class="feature-description">
              Enhance accessibility and understanding with real-time
              transcriptions during lectures
            </p>
          </div>
        </div>
        <div class="feature">
          <div class="image-wrapper">
            <img :src="NotesIcon" alt="Notes" />
          </div>
          <div class="feature-text">
            <p class="feature-title">Personal Notes</p>
            <p class="feature-description">
              Seamlessly add your own insights and comments while recording or
              viewing live captions
            </p>
          </div>
        </div>
      </div>
      <div class="subscription-options">
        <div class="subscription-option">
          <input
            type="radio"
            id="monthly"
            name="subscriptionType"
            v-model="selectedSubscriptionType"
            :value="SUBSCRIPTION_MODELS.MONTHLY.type"
          />
          <label for="monthly">
            <span class="subscription-title">{{
              currencySymbol + monthlySubscriptionPrice + " / month"
            }}</span>
            <span class="subscription-description"
              >Charged monthly after 30-day free trial</span
            >
          </label>
        </div>

        <div class="subscription-option">
          <input
            type="radio"
            id="yearly"
            name="subscriptionType"
            v-model="selectedSubscriptionType"
            :value="SUBSCRIPTION_MODELS.YEARLY.type"
          />
          <label for="yearly">
            <div class="save-more-label">Save More + Get 1 Month Free</div>
            <span class="subscription-title">
              {{ currencySymbol + yearlySubscriptionPrice + " / year" }}
            </span>
            <span class="subscription-description"
              >Charged yearly after 30-day free trial</span
            >
          </label>
        </div>
      </div>
      <p class="cancel-anytime">Cancel anytime</p>
    </div>
    <template #footer>
      <RoundedButton
        text="Start Plan"
        lessRound
        accent
        fitWidth
        uppercase
        @click="
          () => {
            setShowPaymentModal(true);
          }
        "
      />
    </template>
  </Dialog>
  <PaySubscriptionModal
    :visible="showPaymentModal"
    :setVisible="setShowPaymentModal"
    :formattedSelectedSubscriptionPrice="formattedSelectedSubscriptionPrice"
    :selectedSubscriptionType="selectedSubscriptionType"
    @subscriptionSuccess="onSubscriptionSuccess"
  />
</template>

<script setup lang="ts">
import { computed, ref, onBeforeMount, Ref } from "vue";
import {
  COUNTRIES_CURRENCY,
  DEFAULT_COUNTRY_ISO,
  SUBSCRIPTION_MODELS,
} from "../../assets/constants";
import { makeMPServerRequest } from "../../assets/helpers";
import { useStore } from "vuex";
import RoundedButton from "../RoundedButton.vue";
import IconButton from "../IconButton.vue";
import PaySubscriptionModal from "./PaySubscriptionModal.vue";

const PersonWithPigeonBalloon =
  require("../../assets/images/Person-with-pigeon-balloon.png") as string;
const MicIcon = require("../../assets/images/MicIcon.svg") as string;
const WandIcon = require("../../assets/images/Magic-wand-white.svg") as string;
const CaptioningNotesIcon =
  require("../../assets/images/MicIcon.svg") as string;
const NotesIcon = require("../../assets/images/Notes-white.svg") as string;
const CloseGreyIcon = require("../../assets/images/Close-grey.svg") as string;

interface Props {
  visible: boolean;
  setVisible: Function;
  modal: boolean;
}

type SubscriptionModelTypes = typeof SUBSCRIPTION_MODELS;
type SubscriptionTypes =
  SubscriptionModelTypes[keyof SubscriptionModelTypes]["type"];

const props = defineProps<Props>();
const store = useStore();
const emit = defineEmits(["subscriptionSuccess"]);

const monthlySubscriptionPrice: Ref<number | null> = ref(null);
const yearlySubscriptionPrice: Ref<number | null> = ref(null);
const selectedSubscriptionType: Ref<SubscriptionTypes> = ref(
  SUBSCRIPTION_MODELS.MONTHLY.type
);
const showPaymentModal = ref(false);

onBeforeMount(async () => {
  const myHeaders = new Headers();
  myHeaders.append(
    "Authorization",
    `Bearer ${JSON.parse(localStorage.getItem("tokenMP") || "").accessToken}`
  );

  const subscriptionPrices = await makeMPServerRequest(
    "self/stripe-subscription-prices",
    "GET",
    "json",
    null
  );

  monthlySubscriptionPrice.value =
    subscriptionPrices.data.monthly.unit_amount_decimal / 100;
  yearlySubscriptionPrice.value =
    subscriptionPrices.data.yearly.unit_amount_decimal / 100;
});

function formattedSubscriptionPrice(price: number | null): string {
  return price === null
    ? "Issue retrieving subscription prices"
    : currencySymbol.value + price.toString();
}

function setShowPaymentModal(bool: boolean) {
  showPaymentModal.value = bool;
}

function onSubscriptionSuccess() {
  emit("subscriptionSuccess");
  props.setVisible(false);
}

/***** Computed values *****/
const currentUserCountryISO = computed(() => {
  return store.state.currentUser.location || DEFAULT_COUNTRY_ISO;
});

const currencySymbol = computed(() => {
  return COUNTRIES_CURRENCY[
    currentUserCountryISO.value as keyof typeof COUNTRIES_CURRENCY
  ].symbol;
});

const formattedSelectedSubscriptionPrice = computed(() => {
  return formattedSubscriptionPrice(
    selectedSubscriptionType.value === SUBSCRIPTION_MODELS.MONTHLY.type
      ? monthlySubscriptionPrice.value
      : yearlySubscriptionPrice.value
  );
});
/***** End of computed values *****/
</script>

<style lang="scss">
$side-padding: 2.4rem;

.paywall-modal {
  background-color: map-get($colours, accent-purple-3);
  border-radius: 1.2rem;
  overflow: hidden;
  overflow-y: auto;

  .p-dialog-header {
    background-color: inherit;
    display: flex;
    flex-direction: column;
    color: map-get($colours, white);
    padding: 1.6rem $side-padding 4.8rem;
    background-image: url(../../assets/images/BG-Curve-1.svg);
    background-size: cover;

    .close-button {
      position: absolute;
      top: 2rem;
      right: 2rem;
    }

    h1 {
      font-size: 2.4rem;
      margin-bottom: 2rem;
    }

    h1,
    .header-image {
      z-index: 1;
    }

    .header-image {
      height: 18rem;
    }
  }

  .p-dialog-content {
    background-color: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 $side-padding;
    overflow-y: initial;

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;

      .features {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .feature {
          display: flex;
          flex-direction: row;
          gap: 0.8rem;

          .image-wrapper {
            background-color: map-get($colours, accent-purple-1);
            height: 3.2rem;
            width: 3.2rem;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 2rem;
              height: 2rem;
              position: relative;
              display: block; /* Removes bottom spacing/line-height issues */
              z-index: 1;
            }
          }

          .feature-text {
            width: 24rem;

            .feature-title {
              color: white;
              font-weight: bold;
              margin: 0;
              margin-bottom: 0.4rem;
              font-size: 1.4rem;
            }

            .feature-description {
              color: map-get($colours, translucent-white-60);
              margin: 0;
              font-size: 1rem;
            }
          }
        }
      }

      .subscription-options {
        display: flex;
        flex-direction: row;
        gap: 0.8rem;
        margin-top: 3.2rem;

        .subscription-option {
          label {
            border: 0.1rem solid map-get($colours, purple-600);
            padding: 1.4rem 1.6rem;
            display: flex;
            flex-direction: column;
            cursor: pointer;
            border-radius: 1.4rem;
            transition: 0.3s ease-in-out;
            font-family: $font-secondary;
            position: relative;

            &:hover {
              border-color: map-get($colours, accent-purple);

              .save-more-label {
                border-color: map-get($colours, accent-purple);
              }
            }

            .save-more-label {
              background-color: map-get($colours, accent-purple-1);
              font-size: 1rem;
              padding: 0.4rem 0.6rem;
              border-radius: 1.4rem;
              border: 0.1rem solid map-get($colours, purple-600);
              position: absolute;
              top: 0;
              left: 50%;
              transform: translateY(-50%) translateX(-50%);
              white-space: nowrap;
              color: map-get($colours, white);
            }

            .subscription-title {
              color: map-get($colours, white);
              font-size: 1.6rem;
              letter-spacing: 0.015rem;
              font-weight: 500;
            }

            .subscription-description {
              color: map-get($colours, accent-purple);
              font-size: 1rem;
              letter-spacing: 0.015rem;
              margin-top: 0.2rem;
              transition: inherit;
            }
          }

          input[type="radio"] {
            display: none;
          }

          input[type="radio"]:checked + label {
            background-color: map-get($colours, transparent-turquoise);
            border-color: map-get($colours, turquoise);

            .subscription-description {
              color: map-get($colours, white);
            }
          }
        }
      }

      .cancel-anytime {
        font-size: 1rem;
        color: map-get($colours, translucent-white-40);
        margin: 1.6rem 0 0.8rem;
      }
    }
  }

  .p-dialog-footer {
    background-color: inherit;
    padding: 0 $side-padding $side-padding;

    button {
      width: 100%;
      margin: 0;
    }
  }
}
</style>
