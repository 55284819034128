<template>
  <Dialog
    :visible="isOpen"
    :modal="true"
    :showHeader="false"
    :closable="false"
    closeOnEscape
    class="settings-modal"
  >
    <header class="header">Settings</header>
    <div class="topbar">
      <IconButton
        :icon="CloseWhiteIcon"
        alt="Close menu"
        :onclick="closeSettings"
        dark
        xxxsmall
      />
    </div>
    <div class="sidebar">
      <button
        v-for="value in settingsPagesToShow"
        :key="value.index"
        :class="[activeTabIndex === value.index && 'active']"
        @click="setActiveTabIndex(value.index)"
        class="settings-menu-item"
      >
        {{ value.title }}
      </button>
      <div class="app-version">
        {{ "v" + appVersion }}
      </div>
    </div>
    <TabView :activeIndex="activeTabIndex" class="main">
      <TabPanel header="Account"><Account class="settings-page" /></TabPanel>
      <TabPanel header="Subscription"
        ><Subscription class="settings-page"
      /></TabPanel>
      <TabPanel header="Appearance & Animations" />
      <TabPanel header="Font and Colours" />
      <TabPanel header="Captions Language">
        <LanguageSettings
          class="settings-page"
          :closeSettings="closeSettings"
          :setShowSettingsMainMenu="setShowMainMenu"
          :changeLanguage="changeLanguage"
          :parentLanguage="parentLanguage"
        />
      </TabPanel>
      <TabPanel header="Speech-to-Text Model">
        <STTModel
          :selectedSttType="selectedSttType"
          :changeSttType="changeSttType"
          class="settings-page"
        />
      </TabPanel>
      <TabPanel header="Keywords Dictionary"> </TabPanel>
      <TabPanel header="Account"><Support class="settings-page" /></TabPanel>
    </TabView>
  </Dialog>
</template>

<script>
import Account from "../Settings/Account.vue";
import Subscription from "../Settings/Subscription.vue";
import Support from "../Settings/Support.vue";
import LanguageSettings from "./LanguageSettings";
import STTModel from "./STTModel";
import IconButton from "../../components/IconButton.vue";
import CloseWhiteIcon from "../../assets/images/Close-white.svg";
import { editorConstants, SETTINGS_PAGES } from "../../assets/constants";

export default {
  props: {
    isOpen: Boolean,
    closeSettings: Function,
    showMainMenu: Boolean,
    setShowMainMenu: Function,
    changeLanguage: Function,
    parentLanguage: Object,
    appearanceOption: Boolean,
    fontOption: Boolean,
    captionsLanguageOption: Boolean,
    sessionInfoOption: Boolean,
    feedbackAndHelpOption: Boolean,
    sttOption: Boolean,
    selectedSttType: String,
    changeSttType: Function,
    accountOption: Boolean,
    subscriptionOption: Boolean,
    supportOption: Boolean,
    activeTabIndex: Number,
    setActiveTabIndex: Function,
    pagesToShow: Array,
  },

  components: {
    LanguageSettings,
    STTModel,
    IconButton,
    Account,
    Subscription,
    Support,
  },

  computed: {
    settingsPagesToShow() {
      return Object.fromEntries(
        Object.entries(SETTINGS_PAGES).filter(([key]) =>
          this.pagesToShowState.includes(key)
        )
      );
    },

    appVersion() {
      return process.env.VUE_APP_VERSION;
    },
  },

  data() {
    return {
      editorConstants,
      SETTINGS_PAGES,
      CloseWhiteIcon,

      // So we have a fallback default set of pages
      pagesToShowState: this.pagesToShow || [
        "ACCOUNT",
        "SUBSCRIPTION",
        "SUPPORT",
      ],
    };
  },
};
</script>

<style lang="scss">
$border: 0.1rem solid map-get($component-colours, border-light-faint);
$side-padding: 2.4rem;

.settings-modal {
  background: map-get($colours, accent-purple-2);
  width: 80rem;
  max-width: 80vw;
  height: 60rem;
  max-height: 80vh;
  border-radius: 0.6rem;
  overflow: hidden;

  .p-dialog-content {
    background: inherit;
    min-height: 100%;
    display: grid;
    grid-template-columns: 21rem auto;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      "header topbar"
      "sidebar main";
    color: white;
    padding: 0;

    .header {
      grid-area: header;
      font-size: 1.6rem;
      padding: 1.6rem $side-padding;
      border-right: $border;
      border-bottom: $border;
      letter-spacing: 0.04rem;
      line-height: 2.3rem;
    }

    .topbar {
      grid-area: topbar;
      padding: 1.6rem $side-padding;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: flex-end;
      border-bottom: $border;
    }

    .sidebar {
      grid-area: sidebar;
      border-right: $border;
      position: relative;

      .settings-menu-item {
        text-align: left;
        padding: 1.2rem $side-padding;
        width: 100%;
        font-size: 1.4rem;
        font-weight: bold;
        background-color: inherit;
        color: map-get($colours, translucent-white-60);
        transition: all 0.2s ease;

        .language-selection {
          padding-top: 0.8rem;
          display: flex;
          font-size: 1.2rem;
          letter-spacing: 0.01rem;
          font-weight: 400;
          color: map-get($colours, grey-4);
          font-family: $font-secondary;
        }

        &.active,
        &:hover:not(:disabled) {
          background-color: rgba(135, 117, 148, 0.2);
          color: map-get($colours, white);
          filter: none;
        }

        &:disabled {
          // filter: brightness(150%);
          color: map-get($colours, grey-5);
        }
      }

      .app-version {
        position: absolute;
        bottom: 0.8rem;
        left: $side-padding;
        font-size: 1.2rem;
        color: rgba(255, 255, 255, 0.4);
        letter-spacing: 0.04rem;
      }
    }

    .main {
      grid-area: main;
      height: 100%;
      overflow: auto;

      .settings-page {
        padding: 4rem;
        overflow: auto;
      }

      .p-tabview-nav-container {
        display: none;
      }

      .p-tabview-panels {
        padding: 0;
        height: 100%;
        background: inherit;

        .p-tabview-panel {
          height: 100%;
        }
      }

      .stt-panel-content {
        padding: 1.6rem 2.4rem;

        p {
          margin: 0;
        }

        .secondary-text {
          @include body-2;
          color: map-get($colours, grey-2);
        }

        .stt-options {
          display: flex;
          flex-direction: column-reverse;
          gap: 3.2rem;
          margin-top: 2.4rem;

          .radio-row {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            font-weight: bold;
            color: map-get($colours, white);
            @include h3;
          }
        }
      }
    }
  }
}
</style>
