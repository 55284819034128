<template>
  <div class="text-formatting-bar">
    <IconButton
      :icon="UndoIcon"
      alt="Undo"
      :onclick="undo"
      xxsmall
      class="text-formatting-button"
      v-tooltip.top="'Undo'"
      :disabled="disabledAll"
    />
    <IconButton
      :icon="RedoIcon"
      alt="Redo"
      :onclick="redo"
      xxsmall
      class="text-formatting-button"
      v-tooltip.top="'Redo'"
      :disabled="disabledAll"
    />
    <div class="button-with-dropdown-attached">
      <button class="value-button" v-tooltip.top="'Text Size'">
        <span class="value-text">{{
          highlightedFontSize || NOTES_PANEL_DEFAULTS.FONT_SIZE
        }}</span
        ><img class="chevron" :src="ChevronWhiteLeftIcon" />
      </button>
      <div class="dropdown-menu">
        <button
          v-for="(fontSize, index) in FONT_SIZES"
          :key="index"
          class="dropdown-menu-button text-normal"
          @click="setFontSize(fontSize)"
        >
          {{ fontSize }}
        </button>
      </div>
    </div>
    <IconButton
      :icon="BoldIcon"
      alt="Bold"
      :onclick="toggleBold"
      xxsmall
      class="text-formatting-button"
      v-tooltip.top="'Bold'"
      :disabled="disabledAll"
    />
    <IconButton
      :icon="ItalicsIcon"
      alt="Italics"
      :onclick="toggleItalic"
      xxsmall
      class="text-formatting-button"
      v-tooltip.top="'Italics'"
      :disabled="disabledAll"
    />
    <IconButton
      :icon="UnderlineIcon"
      alt="Underline"
      :onclick="toggleUnderline"
      xxsmall
      class="text-formatting-button"
      v-tooltip.top="'Underline'"
      :disabled="disabledAll"
    />
    <IconButton
      :icon="LeftAlignIcon"
      alt="Left align"
      :onclick="setLeftAlign"
      xxsmall
      class="text-formatting-button"
      v-tooltip.top="'Left align'"
      :disabled="disabledAll"
    />
    <IconButton
      :icon="CenterAlignIcon"
      alt="Center align"
      :onclick="setCenterAlign"
      xxsmall
      class="text-formatting-button"
      v-tooltip.top="'Center align'"
      :disabled="disabledAll"
    />
    <IconButton
      :icon="NumberedListIcon"
      alt="Numbered list"
      :onclick="toggleNumberedList"
      xxsmall
      class="text-formatting-button"
      v-tooltip.top="'Numbered list'"
      :disabled="disabledAll"
    />
    <IconButton
      :icon="InsertLinkIcon"
      alt="Hyperlink"
      :onclick="toggleHyperlink"
      xxsmall
      class="text-formatting-button"
      v-tooltip.top="'Insert hyperlink'"
      :disabled="disabledAll"
    />
    <OverlayPanel
      class="hyperlink-panel"
      ref="hyperlinkOP"
      @hide="
        () => {
          this.hyperlinkToInsert = null;
        }
      "
    >
      <InputText v-model="hyperlinkToInsert" ref="hyperlinkInput" />
      <UnderlinedButton text="Insert" small light :onClick="insertHyperlink" />
      <IconButton
        :icon="XSmallWhiteIcon"
        @click="
          () => {
            this.$refs.hyperlinkOP.hide();
          }
        "
        xxsmall
      />
    </OverlayPanel>
    <IconButton
      :icon="ImageManagerIcon"
      alt="Insert Image"
      :onclick="selectImage"
      xxsmall
      class="text-formatting-button"
      v-tooltip.top="'Insert image'"
      :disabled="disabledAll"
    />
    <input
      type="file"
      ref="imageSelector"
      accept="image/*"
      @change="uploadImage"
      hidden
    />
    <div class="button-with-dropdown-attached">
      <IconButton
        :icon="EmojiWhiteIcon"
        alt="Insert Emoji"
        xxsmall
        class="text-formatting-button"
        v-tooltip.top="'Insert emoji'"
        :disabled="disabledAll"
      />
      <div class="dropdown-menu right emoji-picker-dropdown-menu">
        <Picker
          :data="emojiIndex"
          set="twitter"
          @select="showEmoji"
          :perLine="9"
          :style="{ height: '32rem' }"
        />
      </div>
    </div>
    <div class="button-with-dropdown-attached">
      <button class="value-button" v-tooltip.top="'Zoom'">
        <span class="value-text">{{ zoomFactor * 100 + "%" }}</span
        ><img class="chevron" :src="ChevronWhiteLeftIcon" />
      </button>
      <div class="dropdown-menu">
        <button class="dropdown-menu-button" @click="setZoomFactor(0.5)">
          50%
        </button>
        <button class="dropdown-menu-button" @click="setZoomFactor(0.75)">
          75%
        </button>
        <button class="dropdown-menu-button" @click="setZoomFactor(1)">
          100%
        </button>
        <button class="dropdown-menu-button" @click="setZoomFactor(1.25)">
          125%
        </button>
        <button class="dropdown-menu-button" @click="setZoomFactor(1.5)">
          150%
        </button>
        <button class="dropdown-menu-button" @click="setZoomFactor(1.75)">
          175%
        </button>
        <button class="dropdown-menu-button" @click="setZoomFactor(2)">
          200%
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import IconButton from "../IconButton";
import UnderlinedButton from "../UnderlinedButton";
import UndoIcon from "../../assets/images/TextFormatting/Undo.svg";
import RedoIcon from "../../assets/images/TextFormatting/Redo.svg";
import BoldIcon from "../../assets/images/TextFormatting/Bold.svg";
import ItalicsIcon from "../../assets/images/TextFormatting/Italic.svg";
import UnderlineIcon from "../../assets/images/TextFormatting/Underline.svg";
import LeftAlignIcon from "../../assets/images/TextFormatting/AlignLeft.svg";
import CenterAlignIcon from "../../assets/images/TextFormatting/AlignCenter.svg";
import RightAlignIcon from "../../assets/images/TextFormatting/AlignCenter.svg";
import BulletListIcon from "../../assets/images/TextFormatting/OrderedList.svg";
import NumberedListIcon from "../../assets/images/TextFormatting/OrderedList.svg";
import ParagraphMoreIcon from "../../assets/images/TextFormatting/ParagraphMore.svg";
import TextMoreIcon from "../../assets/images/TextFormatting/TextMore.svg";
import TextColourIcon from "../../assets/images/TextFormatting/TextColour.svg";
import TextSizeIcon from "../../assets/images/TextFormatting/TextSize.svg";
import InsertLinkIcon from "../../assets/images/TextFormatting/InsertLink.svg";
import ImageManagerIcon from "../../assets/images/TextFormatting/ImageManager.svg";
import XSmallWhiteIcon from "../../assets/images/X-small-white.svg";
import ChevronWhiteLeftIcon from "../../assets/images/Chevron-left-white.svg";
import EmojiWhiteIcon from "../../assets/images/Emoji-white.svg";
import { isSelectionEmpty } from "../../assets/editor_helpers.js";
import { NOTES_PANEL_DEFAULTS } from "../../assets/constants";
import { Picker, EmojiIndex } from "emoji-mart-vue-fast/src";
import data from "emoji-mart-vue-fast/data/all.json";
import "emoji-mart-vue-fast/css/emoji-mart.css";

let emojiIndex = new EmojiIndex(data);

export default {
  components: {
    IconButton,
    UnderlinedButton,
    Picker,
  },

  props: {
    editor: Object,
    zoomFactor: Number,
    setZoomFactor: Function,
    addImage: Function,
    startImageUpload: Function,
    showEmoji: Function,
    highlightedFontSize: String,
    disabledAll: Boolean,
    setHighlightedFontSize: Function,
  },

  computed: {
    selectedTextColour() {
      if (this.editor) {
        return this.editor.getAttributes("textStyle").color ?? "#FFFFFF";
      } else {
        return null;
      }
    },
  },

  watch: {
    textColour: {
      handler(newColour) {
        // this.$emit("colourChange", newColour);
        this.setColour(newColour);
      },
    },

    selectedTextColour: {
      handler(newColour) {
        this.textColour = newColour;
      },
    },
  },

  data() {
    return {
      FONT_SIZES: [8, 9, 10, 11, 12, 14, 18, 24, 30, 36, 48, 60, 72, 96],
      UndoIcon,
      RedoIcon,
      BoldIcon,
      ItalicsIcon,
      UnderlineIcon,
      LeftAlignIcon,
      CenterAlignIcon,
      RightAlignIcon,
      BulletListIcon,
      NumberedListIcon,
      ParagraphMoreIcon,
      TextMoreIcon,
      TextColourIcon,
      TextSizeIcon,
      InsertLinkIcon,
      ImageManagerIcon,
      XSmallWhiteIcon,
      ChevronWhiteLeftIcon,
      EmojiWhiteIcon,
      NOTES_PANEL_DEFAULTS,
      textColour: null,
      hyperlinkToInsert: null,
      emojiIndex: emojiIndex,
    };
  },

  methods: {
    undo() {
      this.editor.chain().focus().undo().run();
    },

    redo() {
      this.editor.chain().focus().redo().run();
    },

    toggleBold() {
      this.editor.chain().focus().toggleBold().run();
    },

    toggleItalic() {
      this.editor.chain().focus().toggleItalic().run();
    },

    toggleUnderline() {
      this.editor.chain().focus().toggleUnderline().run();
    },

    setLeftAlign() {
      this.editor.chain().focus().setTextAlign("left").run();
    },

    setCenterAlign() {
      this.editor.chain().focus().setTextAlign("center").run();
    },

    toggleBulletList() {
      this.editor.chain().focus().toggleBulletList().run();
    },

    toggleNumberedList() {
      this.editor.chain().focus().toggleOrderedList().run();
    },

    setColour(colour) {
      this.editor.chain().focus().setColor(colour).run();
    },

    setFontSize(fontSize) {
      const fontSizePt = fontSize + "pt";
      this.editor
        .chain()
        .focus()
        .setMark("textStyle", { fontSize: fontSizePt })
        .run();
      this.setHighlightedFontSize(fontSizePt);
    },

    toggleHyperlink(event) {
      if (this.editor.isActive("link")) {
        this.editor.chain().focus().unsetLink().run();
      } else {
        this.$refs.hyperlinkOP.toggle(event);

        this.$nextTick(() => {
          if (this.$refs.hyperlinkInput) {
            this.$refs.hyperlinkInput.$el.focus();
          }
        });
      }
    },

    insertHyperlink() {
      if (!this.hyperlinkToInsert) {
        this.$refs.hyperlinkOP.hide();
        return;
      }
      if (isSelectionEmpty(this.editor)) {
        // Insert link as text first then hyperlink it
        this.editor.chain().focus().insertContent(this.hyperlinkToInsert).run();
      }

      const httpsRegex = /^(https?:\/\/)/i;
      let hyperlink = this.hyperlinkToInsert;
      if (!this.hyperlinkToInsert.match(httpsRegex)) {
        hyperlink = "https://" + hyperlink;
      }

      this.editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: hyperlink })
        .run();
      this.$refs.hyperlinkOP.hide();
    },

    selectImage() {
      let fileInputElement = this.$refs.imageSelector;
      fileInputElement.click();
    },

    async uploadImage(event) {
      console.log(event.target.files);
      console.log(event.target.files[0].name);

      const getFileUrl = (file) => {
        return new Promise((res, rej) => {
          const reader = new FileReader();
          reader.onload = (e) => res(e.target.result);
          reader.onerror = (e) => rej(e);
          reader.readAsDataURL(file);
        });
      };

      const file = event.target.files[0];
      const url = await getFileUrl(file);

      console.log(url);

      this.editor.chain().focus().setImage({ src: url }).run();
    },
  },
};
</script>

<style lang="scss">
.text-formatting-bar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 0.1rem solid map-get($colours, translucent-black-30);
  .text-formatting-button {
    padding: 0.4rem 0.6rem 0.8rem;

    img {
      width: auto;
    }
  }

  .value-button {
    background: transparent;
    color: map-get($colours, white);
    font-size: 1.2rem;
    padding: 0.6rem 0.8rem 0.8rem;
    align-items: center;
    display: flex;

    .chevron {
      width: 2rem;
      height: 2rem;
      transform: rotate(270deg);
    }
  }
  .dropdown-menu {
    margin-bottom: 0.5rem !important;
  }

  .highlighted-font-size-preview {
    min-width: 2rem;
  }
}

.hyperlink-panel {
  padding: 0.8rem;
  background: map-get($component-colours, background-primary);
  border: 0.1rem solid map-get($component-colours, note-taker-border);
  border-radius: 0.8rem;

  .p-overlaypanel-content {
    padding: 0;
    display: flex;
    align-items: center;
  }

  input {
    border-radius: 0.4rem;
    border: none;
    box-shadow: none;
    font-size: 1.2rem;
    padding: 0.6rem;
    width: 20rem;

    &:focus {
      outline: 1px solid pink;
      border: 1px solid red;
      box-shadow: none;
    }
  }

  button {
    margin-left: 0.8rem;
  }
}
</style>
