<template>
  <button
    v-if="icon"
    class="icon-button"
    :class="[
      background ? 'background' : '',
      transparentBackground ? 'transparent-background' : '',
      dark ? 'dark' : '',
      addPadding ? 'add-padding' : '',
      xxsmall ? 'xxsmall' : '',
      xsmall ? 'xsmall' : '',
      small ? 'small' : '',
      medium ? 'medium' : '',
      big ? 'big' : '',
      inheritSize ? 'inherit-size' : '',
    ]"
    :onclick="onclick"
    :disabled="disabled"
  >
    <img
      :src="icon"
      :alt="alt"
      :class="[
        rotated ? 'rotated' : '',
        tiny && 'tiny',
        xxxsmall ? 'xxxsmall' : '',
        xxsmall ? 'xxsmall' : '',
        xsmall ? 'xsmall' : '',
        small ? 'small' : '',
        big ? 'big' : '',
        inheritSize ? 'inherit-size' : '',
        rounded && 'rounded',
      ]"
    />
  </button>
</template>

<script>
export default {
  props: {
    icon: String,
    alt: String,
    onclick: Function,
    rotated: Boolean,
    tiny: Boolean,
    xxxsmall: Boolean,
    xxsmall: Boolean,
    xsmall: Boolean,
    small: Boolean,
    medium: Boolean,
    big: Boolean,
    addPadding: Boolean,
    background: Boolean,
    transparentBackground: Boolean,
    dark: Boolean,
    inheritSize: Boolean,
    rounded: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.icon-button {
  background: none;
  padding: 0;

  &[disabled] {
    filter: brightness(50%);
  }

  &.inherit-size {
    height: 100%;
  }

  &.add-padding {
    padding: 1.6rem;
  }

  // &.circular {
  // border-radius: 50%;
  // }

  &.background {
    background: map-get($component-colours, button-colour-secondary);
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;

    img {
      height: 100%;
      width: 100%;
    }

    &.dark {
      background: map-get($component-colours, button-colour-dark);
    }

    &.transparent-background {
      background: transparent;
    }

    &.xxxsmall {
      width: 1.4rem;
      height: 1.4rem;
    }

    &.xxsmall {
      width: 1.6rem;
      height: 1.6rem;
    }

    &.xsmall {
      width: 2.4rem;
      height: 2.4rem;
    }

    &.small {
      width: 3.2rem;
      height: 3.2rem;
    }

    &.medium {
      width: 3.6rem;
      height: 3.6rem;
    }

    &.big {
      width: 4.8rem;
      height: 4.8rem;
    }
  }

  img {
    transition: transform 0.5s ease-in-out;
    display: block;
    width: 4rem;
    height: 4rem;

    &.inherit-size {
      width: 100%;
      height: 100%;
    }

    &.rotated {
      transform: rotate(-180deg);
    }

    &.tiny {
      width: 1rem;
      height: 1rem;
    }

    &.xxxsmall {
      width: 1.4rem;
      height: 1.4rem;
    }

    &.xxsmall {
      width: 1.6rem;
      height: 1.6rem;
    }

    &.xsmall {
      width: 2.4rem;
      height: 2.4rem;
    }

    &.small {
      width: 3.6rem;
      height: 3.6rem;
    }

    &.big {
      width: 4.8rem;
      height: 4.8rem;
    }

    &.rounded {
      // border-radius: 0.4rem;
      // overflow: hidden;
    }
  }
}
</style>
