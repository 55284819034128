<template>
  <div class="text-editor-wrapper transcript-editor">
    <editor-content
      :editor="transcriptEditor"
      class="text-editor"
      :style="{
        color: fontColour,
        fontWeight: fontBold ? 'bold' : 'normal',
        fontFamily: fontFamily,
        fontSize: fontSize + 'px',
        lineHeight: lineHeight,
        letterSpacing: letterSpacing + 'px',
      }"
    />
    <div class="gradient" :class="isScrolledToBottom ? 'hide' : ''" />
  </div>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import Typography from "@tiptap/extension-typography";
import Underline from "@tiptap/extension-underline";
import Highlight from "@tiptap/extension-highlight";
import { Color } from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";
import Subscript from "@tiptap/extension-subscript";
import Superscript from "@tiptap/extension-superscript";
import Paragraph from "@tiptap/extension-paragraph";
import TextAlign from "@tiptap/extension-text-align";
import { dbConstants } from "../../assets/constants";

export default {
  components: {
    EditorContent,
  },

  props: {
    id: String,
    setNumServiceProviders: Function,
    setNumStudents: Function,
    setNumOthers: Function,
    username: String,
    savedTranscript: String,
    handleKeypress: Function,
    transcriptEditor: Object,
    setTranscriptEditor: Function,
    fontColour: String,
    fontBold: Boolean,
    fontFamily: String,
    fontSize: Number,
    lineHeight: Number,
    letterSpacing: Number,
  },

  data() {
    return {
      dbConstants,
      isScrolledToBottom: true,
    };
  },

  // computed: {
  //   editor() {
  //     return this.$store.state.transcriptEditor;
  //   },
  // },

  mounted() {
    const extensions = [
      Typography,
      Underline,
      Highlight.configure(
        {
          multicolor: true,
          HTMLAttributes: {
            class: 'text-editor-highlight',
          },
        }, 
      ),
      Subscript,
      Superscript,
      TextAlign,
      Color,
      TextStyle,
      Paragraph.extend({
        addAttributes() {
          return {
            id: {
              default: null, // change this if you want a different default value
              parseHTML: (element) => {
                // Get the attribute value from markup if it's available, otherwise assign null as the attribute value
                return element.hasAttribute("id") ? element.getAttribute("id") : null;
              },
            },
          }
        },
      }),
    ];

    this.setTranscriptEditor(
      new Editor({
        content: this.savedTranscript,
        extensions: [StarterKit, ...extensions],
      })
    );
  },

  methods: {},

  beforeUnmount() {
    this.transcriptEditor.destroy();
  },
};
</script>

<style lang="scss">
.transcript-editor {
  .text-editor {
    .text-editor-highlight {
      border-radius: 0;
      font-size: 1.8rem;
      padding: 0;
    }
    .ProseMirror {
      font-family: inherit;
      font-size: inherit;
      height: calc(100% - 3.6rem);

      mark {
        background-color: transparent;
        color: inherit;
      }
    }
  }
}
</style>
