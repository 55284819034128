<template>
  <div class="message" :class="isMe ? 'me' : ''">
    <div class="body-text">{{ text }}</div>
    <footer>
      <span class="author">{{ author }}</span>
      <span class="spacer" />
      <span class="timestamp">{{ timestamp }}</span>
    </footer>
  </div>
</template>

<script>
export default {
  props: {
    text: String,
    author: String,
    timestamp: String,
    isMe: Boolean,
  },
};
</script>

<style lang="scss">
.message {
  border-radius: 1.6rem;
  background: map-get($component-colours, chat-bubble-them-background);
  color: map-get($component-colours, chat-bubble-them-font-primary);
  font-size: 1.6rem;
  font-family: $font-secondary;
  padding: 1rem 1.6rem;
  box-shadow: $small-crisp-box-shadow;

  &.me {
    background: map-get($component-colours, chat-bubble-me-background);
    color: map-get($component-colours, chat-bubble-me-font-primary);

    footer {
      color: map-get($component-colours, chat-bubble-me-font-secondary);
      flex-direction: row-reverse;

      .author {
        text-align: right;
      }
    }
  }

  .body-text {
    white-space: pre-line;
    overflow-wrap: break-word;
  }

  footer {
    color: map-get($component-colours, chat-bubble-them-font-secondary);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 1.4rem;
    margin-top: 0.8rem;

    .author {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .timestamp {
      white-space: nowrap;
    }

    .spacer {
      min-width: 3rem;
    }
  }
}
</style>
