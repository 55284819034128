<template>
  <div class="dashboard">
    <Card>
      <template #header>
        <div class="p-card-image">
          <img alt="Messenger Pigeon" :src="MPLogo" />
        </div>
      </template>
      <template #content>
        <span v-if="this.status != 'submitted'" class="header">
          <h4>Notes Feedback</h4>
          <p>
            We're always looking for ways to provide you with better notes. Let
            us know how we're doing.
          </p>
        </span>
        <div v-if="this.status === 'submitted'">
          <p class="top-text">Thank you for your feedback!</p>
        </div>
        <form v-else id="form" @submit.prevent="submitForm">
          <span class="p-float-label">
            <InputText
              name="sessionId"
              id="session-id"
              type="text"
              v-model.trim="form.sessionId"
              :readonly="isReadonly(this.$route.query.session)"
            />
            <label for="session-id">Session ID</label>
          </span>
          <span class="p-float-label">
            <InputText
              name="recordingName"
              id="recording-name"
              type="text"
              v-model.trim="form.recordingName"
              :readonly="isReadonly(this.$route.query.recording)"
            />
            <label for="recording-name">Recording</label>
          </span>
          <span class="p-float-label">
            <InputText
              name="notetakerId"
              id="notetaker-id"
              type="text"
              v-model.trim="form.notetakerId"
              :readonly="isReadonly(this.$route.query.notetaker)"
            />
            <label for="notetaker-id">Notetaker ID</label>
          </span>
          <span class="p-float-label">
            <InputText
              name="emailAddress"
              id="email-address"
              type="email"
              v-model.trim="form.emailAddress"
            />
            <label for="email-address">Email Address</label>
          </span>
          <span class="p-card-content">
            <label for="rating-syntax"
              >Please rate the grammar, spelling, and punctuation.</label
            >
            <Rating
              name="ratingSyntax"
              v-model="form.ratingSyntax"
              :cancel="false"
            />
          </span>
          <span class="p-card-content">
            <label for="rating-detail">Please rate the level of detail.</label>
            <Rating
              name="ratingDetail"
              v-model="form.ratingDetail"
              :cancel="false"
            />
          </span>
          <span class="p-card-content">
            <label for="rating-clarity"
              >Please rate the clarity and presentation.</label
            >
            <Rating
              name="ratingClarity"
              v-model="form.ratingClarity"
              :cancel="false"
            />
          </span>
          <span class="p-card-content">
            <label for="rating-speed">Please rate the speed of service.</label>
            <Rating
              name="ratingSpeed"
              v-model="form.ratingSpeed"
              :cancel="false"
            />
          </span>
          <span class="p-card-content">
            <label for="additional-comments">Any additional comments?</label>
            <Textarea
              name="additionalComments"
              id="additional-comments"
              type="text"
              v-model.trim="additionalComments"
              rows="5"
              cols="42"
              autoResize
            ></Textarea>
          </span>
          <Button
            label="Submit"
            type="submit"
            class="form button"
            :disabled="!isEmpty || isSubmitting"
          >
            <ProgressSpinner v-if="isSubmitting" class="submit-spinner"
          /></Button>
          <p v-if="errorMessage !== ''" class="error-message">
            {{ errorMessage }}
          </p>
        </form>
      </template>
    </Card>
  </div>
</template>

<script>
import MPLogo from "../assets/images/logos/MP-Habitat-Logo-Dark.svg";

export default {
  computed: {
    isEmpty() {
      return Object.values(this.form).every((x) => !!x);
    },
  },
  data() {
    return {
      MPLogo,
      isSubmitting: false,
      errorMessage: "",
      form: {
        sessionId: this.$route.query.session,
        recordingName: this.$route.query.recording,
        notetakerId: this.$route.query.notetaker,
        emailAddress: "",
        ratingSyntax: null,
        ratingDetail: null,
        ratingClarity: null,
        ratingSpeed: null,
      },
      additionalComments: "",
      feedbackSubmitted: [],
      status: "",
    };
  },
  created() {
    document.title = "Session Feedback";
  },

  mounted() {
    this.feedbackSubmitted = JSON.parse(
      localStorage.getItem("feedbackSubmitted")
    );
    if (this.feedbackSubmitted === null) {
      this.feedbackSubmitted = [];
    }
    if (this.feedbackSubmitted.includes(this.$route.query.session)) {
      // Already submitted feedback for this session
      this.status = "submitted";
    }
  },
  methods: {
    submitForm() {
      this.errorMessage = "";
      const form = document.getElementById("form");
      const formData = new FormData(form);
      const url =
        "https://script.google.com/macros/s/AKfycbwzVX_ifc03Ep1KLQ5ajzvPGg1zdwJk0P0eNurtqYtOnu3zR-GRMSC5OH0RRA1rBUp1/exec";

      formData.append("ratingSyntax", this.form.ratingSyntax);
      formData.append("ratingDetail", this.form.ratingDetail);
      formData.append("ratingClarity", this.form.ratingClarity);
      formData.append("ratingSpeed", this.form.ratingSpeed);
      this.isSubmitting = true;

      const data = new URLSearchParams(formData);
      fetch(url, {
        method: "post",
        body: data,
      })
        .then((response) => {
          console.log(response.status);
          if (response.status === 200) {
            this.isSubmitting = false;
            this.feedbackSubmitted.push(this.$route.query.session);
            this.status = "submitted";
            localStorage.setItem(
              "feedbackSubmitted",
              JSON.stringify(this.feedbackSubmitted)
            );
          }
        })
        .catch((error) => {
          console.log(error);
          this.isSubmitting = false;
          this.errorMessage =
            "There was an error submitting your feedback. Please try again.";
        });
    },
    isReadonly(arg) {
      if (arg) {
        return true;
      }
    },
  },
};
</script>
<style lang="scss">
.dashboard {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  padding: 30px;

  .p-rating-icon {
    font-size: 30px;
  }
  .p-rating-icon.pi.pi-star {
    transition: background-color 0.2s, border-color 0.2s, color 0.2s,
      box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
    color: #504e5f4b;
    &:hover {
      color: #53435fb5;
    }
  }

  .p-card {
    margin: auto;
    width: 50rem;
    border-radius: 0.8rem;
    padding: 30px 60px 30px 60px;
    max-height: 90vh;
    overflow: auto;

    @media (max-width: 560px) {
      width: 90vw;
      padding: 2.4rem 1.8rem 1.6rem;
    }
  }
  .p-card-image {
    width: 30rem;
    margin: auto;
    align-items: center;
    @media (max-width: 560px) {
      width: 24rem;
    }
  }

  .p-card-header {
    line-height: 0;
    margin-top: 20px;
    width: auto;
  }

  .p-card-body {
    padding: 0;
    margin-top: 0;
    max-width: auto;

    .p-card-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      // padding: 0;
      width: auto;
      size: 50px 50px;
      margin-top: 1rem;
      .p-inputtextarea {
        max-width: max-content;
        max-height: max-content;
        min-height: auto;
        max-height: auto;
        @media (max-width: 560px) {
          width: 100%;
        }
      }
    }

    form {
      width: auto;
      @media (max-width: 560px) {
        width: 80vw;
      }

      button {
        width: 7.2rem;
        height: 3.6rem;
        margin-top: 1.2rem;
        font-size: 1.4rem;
        background-color: map-get($colours, accent-purple-3);

        &:hover {
          background-color: map-get($component-colours, button-colour-accent);
          filter: brightness(80%);
        }
      }

      .p-float-label {
        width: 100%;
        font-size: 1.2rem;
        margin: 1rem;

        input {
          width: 100%;
          padding: 1rem 1rem 0.6rem 1rem;
        }
        :read-only {
          box-shadow: grey;
          color: grey;
          cursor: not-allowed;
        }
      }
    }
  }
  .error-message {
    color: red;
    font-size: 12px;
  }
  .submit-spinner {
    height: 100%;

    .p-progress-spinner-circle {
      // Hide spinner before animation starts
      stroke: none;
      animation: p-progress-spinner-dash 1.5s ease-in-out infinite,
        p-progress-spinner-color-solid-white 6s ease-in-out infinite;
      -webkit-animation: p-progress-spinner-dash 1.5s ease-in-out infinite,
        p-progress-spinner-color-solid-white 6s ease-in-out infinite;
    }
  }
}
</style>
