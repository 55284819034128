<template>
  <div class="plus-minus-button">
    <IconButton
      :icon="Minus"
      alt="-"
      @click="this.$emit('decrement')"
      inheritSize
      class="minus-button button"
    />
    <IconButton
      :icon="Plus"
      alt="+"
      @click="this.$emit('increment')"
      inheritSize
      class="plus-button button"
    />
  </div>
</template>

<script>
import Plus from "../assets/images/Plus-purple.svg";
import Minus from "../assets/images/Minus-purple.svg";
import IconButton from "../components/IconButton";

export default {
  components: {
    IconButton,
  },

  data() {
    return {
      Plus,
      Minus,
    };
  },
};
</script>

<style lang="scss">
.plus-minus-button {
  border: 0.1rem solid map-get($colours, accent-purple);
  border-radius: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 6rem;
  height: 2.4rem;

  .button {
    &:hover {
      filter: brightness(150%);
    }
  }

  .minus-button {
    height: 2.4rem;
    width: 2.4rem;
    padding: 0.7rem;
    // padding: 0.4rem 1rem 0.4rem 1.2rem;
  }

  .plus-button {
    height: 2.4rem;
    width: 2.4rem;
    padding: 0.7rem;
    // padding: 0.4rem 1.2rem 0.4rem 1rem;
  }
}
</style>
