<template>
  <div :style="{ minWidth: size.width, maxWidth: size.width, minHeight: size.height}" class="feedback-form" :class="this.$store.state.lightModeActive ? 'light-mode-active' : ''">
    <Transition name="slide-up" mode="out-in">
    <div v-if="status === FEEDBACK_FORM_STATUSES.SUCCESS">    
      <p class="top-text">Thank you for your feedback!</p>
      <footer>
        <Button label="Close" class="p-button-text" @click="close" />
      </footer>
    </div>
    <form v-else class="form-ctn" id="feedbackForm" name="feedback-form" @submit="submitForm">
      <h4 class="top-text">Have some feedback? Please let us know!</h4>
      <Textarea
        v-model.trim="feedbackText"
        name="text"
        class="feedback-input"
        rows="6"
        placholder="Enter your feedback here!"
        autofocus
        v-tooltip.top="
          'Feedback can only be submitted once per session and is anonymous'
        "
      />
      <p v-if="status === FEEDBACK_FORM_STATUSES.ERROR" class="error-message">
        {{this.errorMsg}}
      </p>
      <footer>
        <Button label="Cancel" class="p-button-text" @click="close"/>
        <Button type="submit" class="form-button primary" :disabled="this.feedbackText === ''">
          <ProgressSpinner v-if="isSubmitting" class="submit-spinner" />
          <span v-else>Submit</span>
        </Button>
      </footer>
    </form>
    </Transition>
  </div>
</template>

<script>
import axios from "axios";
import { FEEDBACK_FORM_STATUSES } from "../assets/constants";
import feedbackTemplate from "../assets/feedback-template.json"

export default {
  props: {
    close: Function,
    size: Object
  },

  data() {
    return {
      show: false,
      status: FEEDBACK_FORM_STATUSES.INITIAL,
      FEEDBACK_FORM_STATUSES: FEEDBACK_FORM_STATUSES,
      isSubmitting: false,
      feedbackSubmitted: [],
      feedbackText: "",
      errorMsg: ""
    };
  },

  mounted() {
    this.feedbackSubmitted = JSON.parse(
      localStorage.getItem("feedbackSubmitted")
    );
    if (this.feedbackSubmitted === null) {
      this.feedbackSubmitted = [];
    }
    if (this.feedbackSubmitted.includes(this.$route.params.document)) {
      // Already submitted feedback for this session
      this.status = FEEDBACK_FORM_STATUSES.SUCCESS;
    }
  },

  methods: {
    submitForm(e) {
      e.preventDefault();
      if (this.feedbackText === "") {
        this.status = FEEDBACK_FORM_STATUSES.ERROR;
        this.errorMsg = "Submission cannot be blank, dont forget your feedback!"
        return false
      }
      if(this.feedbackText.length > 1000){
        this.status = FEEDBACK_FORM_STATUSES.ERROR;
        this.errorMsg = "Feedback must be less than 1000 characters"
        return false
      }
      this.isSubmitting = true;
      let feedbackData = feedbackTemplate

      feedbackData.blocks[2].text.text = ">\"" + this.feedbackText + "\"";
      feedbackData.blocks[3].elements[0].text = "Session ID: " + this.$route.params.document
      const jsonData = JSON.stringify(feedbackData);

      const config = {
        method: 'post',
        url: process.env.VUE_APP_FEEDBACK_SLACK_WEBHOOK,
        data : jsonData
      };

      axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
          // handle success
        this.isSubmitting = false;
        this.status = FEEDBACK_FORM_STATUSES.SUCCESS;
        this.feedbackSubmitted.push(this.$route.params.document);
        localStorage.setItem(
          "feedbackSubmitted",
          JSON.stringify(this.feedbackSubmitted)
        );
      })
      .catch((error) => {
        console.log(error);
        this.isSubmitting = false;
        this.errorMsg = "Uh Oh! Something went wrong when submitting your feedback. Please try again later!"
        this.status = FEEDBACK_FORM_STATUSES.ERROR;
      });
    },
  },
};
</script>

<style lang="scss">
.feedback-form {
  width: 50%;
  padding: 2rem;

  .feedback-input {
    display: flex;
    width: 90%;
  }

  .error-message {
    color: map-get($component-colours, font-colour-error);
    font-size: 1.2rem;
  }

  .top-text {
    color: map-get($colours, white);
    margin-left: 1rem;
  }

  .p-inputtextarea { 
    display: flex;
    min-width: 90%;
    max-width: 90%;
    max-height: 40rem;
    align-content: center;
  }

  .p-inputtext {
    margin: 0 auto;
  }

  footer {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 2.3rem;
  }

  .form-button {
    width: 7.2rem;
    height: 3.6rem;
    background-color: map-get($component-colours, button-colour-accent);

    &:hover {
      background-color: map-get($component-colours, button-colour-accent);
      filter: brightness(80%);
    }
    
    &:disabled {
      color: map-get($colours, grey-2);
    }
  }
  
  .p-button-text {
    color: map-get($component-colours, text-button-font-colour-light);
    :hover {
      color: map-get($component-colours, font-grey-2);
    }
  }

  &.light-mode-active {
    .p-button-text {
      color: map-get($component-colours,  button-font-colour-secondary);

      &:hover {
        color: map-get($component-colours,  accent-purple);
      }
    }
    

    .top-text {
      color: map-get($colours, black);
    }
  }

  [v-cloak] {
    display: none;
  }

  .slide-up-enter-active,
  .slide-up-leave-active {
    transition: all 0.1s ease-out;
  }

  .slide-up-enter-from {
    opacity: 0;
    transform: translateX(20px);
  }

  .slide-up-leave-to {
    opacity: 0;
    transform: translateX(-20px);
  }

  .submit-spinner {
    height: 100%;

    .p-progress-spinner-circle {
      // Hide spinner before animation starts
      stroke: none;
      animation: p-progress-spinner-dash 1.5s ease-in-out infinite,
        p-progress-spinner-color-solid-white 6s ease-in-out infinite;
      -webkit-animation: p-progress-spinner-dash 1.5s ease-in-out infinite,
        p-progress-spinner-color-solid-white 6s ease-in-out infinite;
    }
  }
}
</style>
