import {
  toTitleCase,
  convertDateToHoursMinutes,
} from "../../assets/helpers_legacy";
// import { ipcRenderer } from "electron";
import { dbConstants } from "../../assets/constants";

export default {
  methods: {
    async init(serviceType) {
      try {
        this.username = self.name.fullName;
      } catch (err) {
        console.log(err);
      }

      setTimeout(() => {
        this.minLoadingTimeElapsed = true;
      }, this.MIN_LOADING_TIME);

      const myHeaders = new Headers();
      myHeaders.append("content-type", "application/json");
      myHeaders.append(
        "x-hasura-admin-secret",
        process.env.VUE_APP_HASURA_ADMIN_SECRET
      );
      const graphql = JSON.stringify({
        query: `query GetBookingInfo($id: uuid!) {
                    calendar_tasks_by_pk(id: $id) {
                      start_time,
                      end_time,
                      tz,
                      name,
                      service_type,
                      labels
                    }
                    booking_labels(where: {calendar_tasks: {id: {_eq: $id}}}) {
                      id
                      course_crn
                      course_name
                      booking_labels_keywords {
                        content
                        phrases
                      }
                    }
                  }
                `,
        variables: {
          id: this.$route.params.id,
        },
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: graphql,
        redirect: "follow",
      };
      fetch(process.env.VUE_APP_HASURA_URL, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          const res = JSON.parse(result);
          const noBooking = "Booking not found";
          const table = "calendar_tasks_by_pk";
          const bookingLabels = "booking_labels";

          if (res.errors) {
            this.loading = false;
            this.errorLoading = true;
            if (
              res.errors[0].message.includes(
                "invalid input syntax for type uuid"
              )
            ) {
              this.errorLoadingMessage = noBooking;
            } else {
              this.errorLoadingMessage =
                "Received error: " + res.errors[0].message;
            }
          } else if (
            res.data[table] &&
            res.data[table].service_type === serviceType
          ) {
            this.bookingName =
              (res.data[table].labels["Course Code"]
                ? res.data[table].labels["Course Code"] + ": "
                : "") + toTitleCase(res.data[table].name);

            const startDate = new Date(res.data[table].start_time);
            const endDate = new Date(res.data[table].end_time);
            this.bookingEndTime = convertDateToHoursMinutes(endDate);

            if (
              res.data[bookingLabels][0].booking_labels_keywords &&
              res.data[bookingLabels][0].booking_labels_keywords.length > 0
            ) {
              this.bookingLabelsKeywords =
                res.data[bookingLabels][0].booking_labels_keywords[0].content;
              this.bookingLabelsKeywordsDictionary = res.data[bookingLabels][0]
                .booking_labels_keywords[0].phrases || { phrases: [] };
            }
            this.bookingLabelId = res.data[bookingLabels][0].id;

            const split = res.data[table].tz.split("/");
            if (split.length === 2) {
              this.bookingTimezone = split[1];
            } else {
              this.bookingTimezone = res.data[table].tz;
            }
            this.bookingTimezone = this.bookingTimezone.replaceAll("_", " ");
            this.loading = false;
            // Remove class that disables animations on page load
            setTimeout(
              () => {
                this.disableTransitions = false;
              },
              this.minLoadingTimeElapsed ? 500 : this.MIN_LOADING_TIME + 500
            );

            // Booking type specific settings
            // Captioning booking
            if (serviceType === dbConstants.SERVICE_TYPE_CAPTIONING) {
              this.bookingStartTime = convertDateToHoursMinutes(startDate);
            }
            // Note-taking booking
            else {
              this.turnaroundTime = (endDate - startDate) / 3.6e6;
              this.bookingEndDate = endDate.toLocaleString("default", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
            }
          } else {
            this.loading = false;
            this.errorLoading = true;
            this.errorLoadingMessage = noBooking;
          }
        })
        .catch((error) => {
          console.log("error", error);
          this.loading = false;
          this.errorLoadingMessage = "Error retrieving booking information";
          // this.errorLoading = true;
          this.errorLoading = false;
        });
    },

    confirmLeaveSession() {
      this.$confirm.require({
        message: "Are you sure you want to leave the session?",
        header: "Confirmation",
        icon: false,
        acceptLabel: "LEAVE",
        rejectLabel: "STAY",
        rejectClass:
          "rounded-button shadow medium-wide secondary slim monospace smaller-font",
        acceptClass:
          "rounded-button shadow medium-wide slim monospace smaller-font",
        accept: () => {
          //callback to execute when user confirms the action
          this.$router.push("/standby");
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },

    setNumServiceProviders(numServiceProviders) {
      this.numServiceProviders = numServiceProviders;
    },

    setNumStudents(numStudents) {
      this.numStudents = numStudents;
    },

    setNumOthers(numOthers) {
      this.numOther = numOthers;
    },
  },
};
