<template>
  <MessageScreen v-if="loading || errorLoading" class="message-loading-screen">
    <div class="loading-screen" v-if="loading">
      <span>Loading...</span>
      <ProgressSpinner class="spinner bottom" />
    </div>
    <div class="loading-screen" v-else>
      <span class="error-message">{{ errorLoadingMessage }}</span>
      <RoundedButton text="Go back" shadow @click="goBack" class="bottom" />
    </div>
  </MessageScreen>
  <div v-else-if="!loading" class="main-content">
    <InfoPanel
      :isOpen="infoPanelOpen"
      :toggleInfoPanelOpen="toggleInfoPanelOpen"
    />
    <div class="session-setup">
      <button
        v-if="!hideBackButton"
        class="back-button"
        @click="
          () => {
            this.$router.push('/standby');
          }
        "
      >
        <img :src="ChevronLeftGreyIcon" />
        <span>Back</span>
      </button>
      <div class="content">
        <div class="name-session content-section">
          <header>Name your session</header>
          <main>
            <InputText
              v-model="sessionTitle"
              class="session-name-input"
              v-tooltip.top="'Leave this blank to use the default session name'"
            />
            <p class="note">You can change the name later</p>
          </main>
        </div>
        <div class="set-up-audio-devices content-section">
          <header>Set up your audio devices</header>
          <main>
            <p class="subtitle">
              Tell us which audio source you want to caption
              <ExtraInfo class="choose-audio-device-info" alignRight>
                <template #title>
                  <img :src="LightbulbPurpleIcon" />
                  <span>Tips for choosing an audio device</span>
                </template>
                <template #content>
                  <p>
                    If you are physically in class, you will likely choose a
                    microphone.
                  </p>
                  <p>
                    If you are joining a class remotely (eg: through Google
                    Meet), you should select “System Audio”.
                  </p>
                </template>
              </ExtraInfo>
            </p>
            <div class="input-device-selector-row">
              <Dropdown
                v-model="selectedAudioInputDevice"
                :options="audioInputDevices"
                optionLabel="name"
                placeholder="Select an audio input device"
                class="input-device-dropdown"
              />
              <RoundedButton
                class="test-input-button"
                text="Test"
                lessRound
                mediumwide
                largerFont
                fitHeight
                :image="
                  testAudioPlaying ? SpeakerLoudPurpleIcon : SpeakerPurpleIcon
                "
                imageWithoutPadding
                @click="playTestSound"
                v-if="
                  selectedAudioInputDevice.name !=
                  editorConstants.INPUT_DEVICES.DEFAULT_MICROPHONE
                "
              />
            </div>
            <div class="field-row">
              <label for="input-detected">Input level:</label>
              <div
                id="input-detected"
                class="input-detected-visualization-track"
              >
                <div
                  v-if="gotInputLevels"
                  class="input-detected-visualization"
                  :style="{ width: inputDetectedPercent + '%' }"
                />
              </div>
            </div>
          </main>
        </div>
        <RoundedButton
          class="start-session-button"
          text="Start Session"
          :onClick="startInstantSession"
          lessRound
          :image="ArrowRightBlackIcon"
          reverse
          bold
          extrawide
        />
      </div>
    </div>
  </div>
</template>

<script>
import InfoPanel from "../components/InfoPanel";
import MessageScreen from "../components/MessageScreen.vue";
import RoundedButton from "../components/RoundedButton";
import ExtraInfo from "../components/ExtraInfo";
import LightbulbPurpleIcon from "../assets/images/Lightbulb-purple.svg";
import ArrowRightBlackIcon from "../assets/images/Arrow-right-black.svg";
import SpeakerPurpleIcon from "../assets/images/Speaker-purple.svg";
import SpeakerLoudPurpleIcon from "../assets/images/Speaker-loud-purple.svg";
import ChevronLeftGreyIcon from "../assets/images/Chevron-left-grey.svg";
import AlarmGentleSound from "../assets/sounds/alarm_gentle.wav";
import { editorConstants } from "../assets/constants";
import { createSession } from "../assets/uploadFile";

export default {
  components: {
    InfoPanel,
    MessageScreen,
    RoundedButton,
    ExtraInfo,
  },

  data() {
    return {
      MIN_LOADING_TIME: 1000,

      editorConstants: editorConstants,
      LightbulbPurpleIcon,
      ArrowRightBlackIcon,
      SpeakerPurpleIcon,
      SpeakerLoudPurpleIcon,
      ChevronLeftGreyIcon,
      infoPanelOpen: false,
      sessionTitle: "",
      audioInputDevices: [],
      selectedAudioInputDevice: {
        name: editorConstants.INPUT_DEVICES.DEFAULT_MICROPHONE,
      },
      loading: false,
      errorLoading: false,
      errorLoadingMessage: "",
      minLoadingTimeElapsed: false,
      inputDetectedPercent: 0,
      gotInputLevels: false,
      testAudioPlaying: false,
      testAudio: null,
      fadeTimeout: null,
      fadeInterval: null,
      populatedDropdown: false,

      hideBackButton: false,
    };
  },

  async created() {
    if (this.$store.state.platform === "win32") {
      if (!this.populatedDropdown) {
        this.populateAudioInputDropdown();
      }
    }
  },

  async mounted() {
    const defaultMicOption = {
      name: editorConstants.INPUT_DEVICES.DEFAULT_MICROPHONE,
    };

    this.audioInputDevices.push(defaultMicOption);

    await this.getInputLevels();
    this.gotInputLevels = true;
  },

  methods: {
    populateAudioInputDropdown() {
      this.audioInputDevices.push(
        {
          name: editorConstants.INPUT_DEVICES.SYSTEM_AUDIO,
        },
        {
          name: editorConstants.INPUT_DEVICES.MIC_AND_SYSTEM_AUDIO,
        }
      );
      this.populatedDropdown = true;
    },

    async getInputLevels() {
      /**
       * From Stackoverflow
       * https://stackoverflow.com/questions/33322681/checking-microphone-volume-in-javascript
       */
      let volumeCallback = null;
      let volumeInterval = null;
      try {
        const audioStream = await navigator.mediaDevices.getUserMedia({
          audio: {
            echoCancellation: true,
          },
        });
        const audioContext = new AudioContext();
        const audioSource = audioContext.createMediaStreamSource(audioStream);
        const analyser = audioContext.createAnalyser();
        analyser.fftSize = 512;
        analyser.minDecibels = -127;
        analyser.maxDecibels = 0;
        analyser.smoothingTimeConstant = 0.4;
        audioSource.connect(analyser);
        const volumes = new Uint8Array(analyser.frequencyBinCount);
        volumeCallback = () => {
          analyser.getByteFrequencyData(volumes);
          let volumeSum = 0;
          for (const volume of volumes) volumeSum += volume;
          const averageVolume = volumeSum / volumes.length;
          this.inputDetectedPercent = (averageVolume * 100) / 127;
        };
      } catch (e) {
        console.error("Failed to initialize volume visualizer", e);
      }

      if (volumeCallback !== null && volumeInterval === null)
        volumeInterval = setInterval(volumeCallback, 100);
    },

    playTestSound() {
      if (this.testAudio) {
        // If already playing, pause and restart
        this.testAudio.pause();
        clearTimeout(this.fadeTimeout);
        clearInterval(this.fadeInterval);
      }
      this.testAudio = new Audio(AlarmGentleSound);
      this.testAudio.play();
      this.testAudioPlaying = true;

      const audioDuration = 6000;
      const fadeDuration = 2000;
      const fadeInterval = 100;

      // Start fade out at 4 seconds
      this.fadeTimeout = setTimeout(() => {
        this.fadeInterval = setInterval(() => {
          if (this.testAudio.volume > 0) {
            this.testAudio.volume -= Math.min(
              this.testAudio.volume,
              fadeInterval / fadeDuration
            );
          } else {
            this.testAudio.pause();
            this.testAudio = null;
            this.testAudioPlaying = false;
            clearInterval(this.fadeInterval);
          }
        }, fadeInterval);
      }, audioDuration - fadeDuration);
    },

    toggleInfoPanelOpen() {
      this.infoPanelOpen = !this.infoPanelOpen;
    },

    goBack() {
      this.$router.push("/standby");
    },

    async startInstantSession() {
      let sessionTitle =
        this.sessionTitle !== "" ? this.sessionTitle : "Untitled Session";
      const sessionId = await createSession(sessionTitle);
      this.$store.commit("setSessionDetails", {
        id: sessionId,
        title: sessionTitle,
        audioInputDevice: this.selectedAudioInputDevice.name,
        isInstant: true,
      });

      this.$router.push({
        name: "Captioner",
      });
    },
  },
};
</script>

<style lang="scss">
$row-width: 32rem;

.message-loading-screen {
  .loading-screen {
    .error-message {
      white-space: pre-wrap;
    }
  }
}

.main-content {
  height: 100%;
  width: 100%;
  display: flex;
}

.standby-card-wrapper {
  padding: 3.2rem;
  margin: auto;

  .card {
    width: 34rem;
    height: 50rem;
    padding: 3.2rem 2.4rem;
  }

  .standby-card {
    font-size: 1.4rem;
    font-family: $font-secondary;
    display: flex;
    flex-direction: column;
    align-items: center;

    .p-card-body {
      height: 100%;
      display: flex;
      flex-direction: column;

      .p-card-content {
        flex: 1;
        padding: 0;

        .content {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }
    }

    .title {
      text-align: center;
      margin: 0;
    }

    .placeholder {
      font-size: 1rem;
      visibility: hidden;
    }

    .buttons {
      > * {
        width: 33%;
        // margin-left: 1rem;
      }
    }

    .subtitle {
      @include section-header-3;
      margin-top: 3.2rem;
      margin-bottom: 0.8rem;
    }
  }
}

.session-setup {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;

  .back-button {
    position: absolute;
    top: 2.4rem;
    left: 1.6rem;
    display: flex;
    align-items: center;
    color: map-get($colours, grey-3);
    background: transparent;
    padding: 0;
    @include section-header-3;

    &:hover {
      text-decoration: underline;
    }
  }

  .p-inputtext {
    font-size: 1.6rem;
    padding: 1.6rem;
  }

  .p-dropdown {
    border-radius: 0.8rem;
  }

  .content {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .content-section {
      width: 100%;
    }

    input {
      padding: 1.6rem;
      border-radius: 0.8rem;
    }

    header {
      @include h2;
      width: 100%;

      overflow: hidden;
      text-align: center;
      &:before,
      &:after {
        background-color: map-get($colours, accent-purple);
        content: "";
        display: inline-block;
        height: 1px;
        position: relative;
        vertical-align: middle;
        width: 50%;
      }
      &:before {
        right: 0.5em;
        margin-left: -50%;
      }
      &:after {
        left: 0.5em;
        margin-right: -50%;
      }
    }

    .session-name-input {
      width: $row-width;
    }

    .set-up-audio-devices {
      main {
        padding: 2.4rem 0;
      }
    }

    main {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 3.2rem 0;

      .subtitle {
        @include section-sub-header;
        display: flex;
      }

      .note {
        @include callout;
        color: map-get($colours, grey-3);
      }

      .input-device-selector-row {
        display: flex;
        height: 5.6rem;
        margin-bottom: 2.4rem;
        min-width: $row-width;

        .test-input-button {
          margin-left: 1.6rem;
        }

        .input-device-dropdown {
          width: 100%;
        }
      }

      .field-row {
        display: flex;
        align-items: center;
        width: $row-width;

        label {
          margin-right: 1.6rem;
          white-space: nowrap;
          @include callout;
        }

        .input-detected-visualization-track {
          height: 0.8rem;
          background-color: map-get($colours, grey-2);
          width: 36rem;
          border-radius: 0.8rem;
          overflow: hidden;

          .input-detected-visualization {
            height: 100%;
            background-color: map-get($colours, turquoise);
            transition: width 100ms linear;
          }
        }
      }
    }

    .start-session-button {
      margin-top: 4.8rem;
    }
  }

  .choose-audio-device-info {
    margin-left: 0.8rem;

    .info-card {
      width: 23rem;
    }
  }
}
</style>
