<template>
  <div class="loading-screen" v-if="loading">
    <span>Loading...</span>
    <ProgressSpinner class="spinner bottom" />
  </div>
  <div class="study-assistant" v-else v-bind="$attrs">
    <div class="locked-panel" v-if="!currentUserSubscriptionDetails.isActive">
      <img :src="LockGreyPurpleBackgroundIcon" />
      <div class="questions-answers-list">
        <div class="bubble subscription-prompt">
          Hello there! 👋 We’re grateful for your interest in our Study
          Assistant. It is designed to assist you in comprehending your lecture
          material better. Take a look at our subscription plan, which grants
          you access to full power of supercharging your learning.
        </div>
        <RoundedButton
          text="Explore Subscription Plans"
          accent
          smallerFont
          slim
          @click="
            () => {
              this.showSubscriptionSignupDialog = true;
            }
          "
          class="subscription-prompt-button"
        />
        <RoundedButton
          text="Learn More About This Feature"
          accent
          smallerFont
          slim
          @click="
            () => {
              this.showDetailsDialog = true;
            }
          "
          class="subscription-prompt-button"
        />
      </div>
    </div>
    <div class="questions-answers-list" ref="questionsAnswersList" v-else>
      <div
        v-for="item in questionsAnswersList"
        :key="item.timestamp"
        class="bubble"
        :class="item.type"
        @click="
          item.type === editorConstants.STUDY_ASSISTANT_TYPES.SELECTABLE &&
            clickSelectable(item.prompt)
        "
      >
        <hr v-if="item.type === 'padded-divider'" class="padded-divider-hr" />
        <div v-else>
          <span v-html="trimText(item.text || item.prompt.text)" />
          <div v-if="item.viewAll">
            <div class="show-more-border" />
            <button class="show-more" @click="this.clickViewAll(item)">
              <div>view all</div>
              <img :src="ArrowNextWhiteIcon" />
            </button>
          </div>
          <div v-if="item.type === 'answer' && !item.viewAll">
            <div class="bubble-icons">
              <IconButton
                :icon="AddToNotesIcon"
                class="close-button"
                alt="AddToNotesIcon"
                v-tooltip.bottom="'Add this to notes'"
                :onclick="
                  () => {
                    pasteToNote(item.text || item.prompt.text);
                  }
                "
                xsmall
              />
              <IconButton
                :icon="CopyIcon"
                class="close-button"
                alt="CopyIcon"
                v-tooltip.bottom="'Copy text'"
                :onclick="
                  () => {
                    copyFormattedHtml(item.text || item.prompt.text);
                  }
                "
                xsmall
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Dialog
    :visible="showDetailsDialog"
    :modal="true"
    :header="false"
    :closable="false"
    closeOnEscape
    class="study-assistant-details"
  >
    <header>
      <h1>
        Introducing Study Assistant
        <img :src="MagicWandWhiteIcon" class="magic-wand-icon" />
      </h1>
      <IconButton
        :icon="CloseGreyIcon"
        class="close-button"
        alt="Close"
        :onclick="
          () => {
            this.showDetailsDialog = false;
          }
        "
        xsmall
      />
    </header>
    <img :src="StudyAssistantDetailedImage" class="poster-image" />
    <div class="bottom-section">
      <p>
        With our Study Assistant feature, you gain access to a wealth of
        knowledge from over 300K hours of lecture recordings, providing
        invaluable insights and assistance to optimize your learning process.
        📖💡
      </p>
      <p>
        Whether you need help with understanding complex concepts, reviewing
        important information, or preparing for exams.
      </p>
      <p>Explore it now and elevate your academic experience! 🚀</p>
      <RoundedButton
        text="Explore Plans"
        accent
        lessRound
        tall
        monospace
        @click="
          () => {
            this.showDetailsDialog = false;
            this.showSubscriptionSignupDialog = true;
          }
        "
        class="explore-plans-button"
      />
    </div>
  </Dialog>
  <Dialog
    v-model:visible="showViewAllDialog"
    :modal="true"
    :header="false"
    :closable="false"
    closeOnEscape
    class="show-more-dialog"
  >
    <div class="show-more-dialog-header">
      <div class="temp" />
      View all
      <div class="temp">
        <IconButton
          :icon="CloseGreyIcon"
          class="close-button"
          alt="Close"
          :onclick="
            () => {
              this.showViewAllDialog = false;
            }
          "
          xsmall
        />
      </div>
    </div>
    <div class="show-more-dialog-body">
      <span v-html="this.textViewAll" />
    </div>
    <div class="show-more-dialog-icons">
      <IconButton
        :icon="AddToNotesIcon"
        class="close-button"
        alt="AddToNotesIcon"
        v-tooltip.bottom="'Add this to notes'"
        :onclick="
          () => {
            pasteToNote(this.textViewAll);
          }
        "
        xsmall
      />
      <IconButton
        :icon="CopyIcon"
        class="close-button"
        alt="CopyIcon"
        v-tooltip.bottom="'Copy text'"
        v-tooltip.pressed="'Copied text'"
        :onclick="
          () => {
            copyFormattedHtml(this.textViewAll);
          }
        "
        xsmall
      />
    </div>
  </Dialog>
  <SubscriptionSignup
    :visible="showSubscriptionSignupDialog"
    :setVisible="setShowSubscriptionSignupDialog"
  />
</template>

<script>
import IconButton from "../IconButton";
import RoundedButton from "../RoundedButton";
import SendMessageWhiteIcon from "../../assets/images/Send-message-white.svg";
import CopyPurpleIcon from "../../assets/images/Copy-purple.svg";
import {
  editorConstants,
  STUDY_ASSISTANT_INITIAL_PROMPTS,
} from "../../assets/constants";
import {
  getCurrentUserSubscriptionDetails,
  copyFormattedHtml,
  convertOrderedListStringToHtml,
  delay,
} from "../../assets/helpers_legacy";
import CloseGreyIcon from "../../assets/images/Close-grey.svg";
import LockGreyPurpleBackgroundIcon from "../../assets/images/Lock-grey-purple-background.svg";
import MagicWandWhiteIcon from "../../assets/images/Magic-wand-white.svg";
import StudyAssistantDetailedImage from "../../assets/images/Study-assistant-detailed.png";
import SubscriptionSignup from "../../components/Onboarding/SubscriptionSignup.vue";
import ArrowNextWhiteIcon from "../../assets/images/ArrowWhiteIcon.svg";
import CopyIcon from "../../assets/images/Copy-purple.svg";
import AddToNotesIcon from "../../assets/images/AddToNotes.svg";

export default {
  inheritAttrs: false,

  components: {
    IconButton,
    RoundedButton,
    SubscriptionSignup,
  },

  props: {
    questionsAnswersList: Array,
    pushToQuestionsAnswersList: Function,
    quizList: Array,
    summarizeList: Array,
    keywordsList: Object,
    recommendationsList: Array,
    pasteToNote: Function,
    tipsString: String,
  },

  data() {
    return {
      copyFormattedHtml,
      editorConstants,
      SendMessageWhiteIcon,
      CopyPurpleIcon,
      LockGreyPurpleBackgroundIcon,
      MagicWandWhiteIcon,
      CloseGreyIcon,
      StudyAssistantDetailedImage,
      currentInput: "",
      showDetailsDialog: false,
      showSubscriptionSignupDialog: false,
      showViewAllDialog: false,
      ArrowNextWhiteIcon,
      textViewAll: "",
      CopyIcon,
      AddToNotesIcon,
      currentUserSubscriptionDetails: {},
      loading: true,
    };
  },

  watch: {
    questionsAnswersList: {
      handler() {
        this.$nextTick(function () {
          // Scroll to the bottom once the message is rendered
          this.$refs.questionsAnswersList.scrollTop =
            this.$refs.questionsAnswersList.scrollHeight;
        });
      },
      deep: true,
    },
  },

  async created() {
    this.currentUserSubscriptionDetails =
      await getCurrentUserSubscriptionDetails();
    this.loading = false;
  },

  methods: {
    input(val) {
      this.currentInput = val;
    },

    setShowSubscriptionSignupDialog(bool) {
      this.showSubscriptionSignupDialog = bool;
    },

    copyText(e) {
      try {
        navigator.clipboard.writeText(e);
      } catch (error) {
        console.error(error);
      }
    },

    sendInput() {
      if (this.currentInput.trim().length === 0) {
        return;
      }
      const input = {
        text: this.currentInput,
        type: editorConstants.STUDY_ASSISTANT_TYPES.INQUIRY,
        timestamp: new Date(),
      };
      this.pushToQuestionsAnswersList(input);
      this.currentInput = "";

      const answer = {
        text: "This would be the response",
        type: editorConstants.STUDY_ASSISTANT_TYPES.ANSWER,
        timestamp: new Date(),
      };

      setTimeout(() => {
        this.pushToQuestionsAnswersList(answer);
      }, 2000);
    },

    trimText(text) {
      if (typeof text === "undefined") {
        return "";
      }
      return typeof text === "string"
        ? text.substring(0, 500) + (text.length > 500 ? "..." : "")
        : "";
    },

    closeViewAllModal() {
      this.showViewAllDialog = false;
    },

    clickViewAll(item) {
      this.textViewAll = item.text || item.prompt.text;
      this.showViewAllDialog = true;
    },

    clickSelectable(prompt) {
      const selected = {
        prompt: prompt,
        type: editorConstants.STUDY_ASSISTANT_TYPES.SELECTED,
        timestamp: new Date(),
      };

      this.pushToQuestionsAnswersList(selected);
      if (prompt.response) {
        const response = {
          text: prompt.response,
          type: "",
          timestamp: new Date(),
        };
        setTimeout(() => {
          this.pushToQuestionsAnswersList(response);
        }, 500);
      }

      if (prompt.selectables) {
        prompt.selectables.map((selectable) => {
          const item = {
            prompt: selectable,
            requestValue: selectable.requestValue,
            type: editorConstants.STUDY_ASSISTANT_TYPES.SELECTABLE,
            timestamp: new Date(),
          };
          setTimeout(() => {
            this.pushToQuestionsAnswersList(item);
          }, 1000);
        });
      }

      if (
        prompt.requestValue === "keywords" &&
        this.keywordsList &&
        this.keywordsList.length > 0
      ) {
        const text = this.keywordsList.join("<br>");
        const answer = {
          text: text,
          type: editorConstants.STUDY_ASSISTANT_TYPES.ANSWER,
          timestamp: new Date(),
          viewAll: text.length > 500,
        };

        this.showAnswerThenReprompt(answer);
      } else if (prompt.requestValue === "summerize") {
        const text = this.summarizeList
          .map((highlight) => highlight.text)
          .join("\n");
        const answer = {
          text: text,
          type: editorConstants.STUDY_ASSISTANT_TYPES.ANSWER,
          timestamp: new Date(),
          viewAll: text.length > 500,
        };

        this.showAnswerThenReprompt(answer);
      } else if (
        prompt.requestValue === "quiz" &&
        this.quizList &&
        this.quizList.length > 0
      ) {
        let htmlList = "<ol>";
        for (let item of this.quizList) {
          htmlList += "<li>" + item.question + "</li>";
        }
        htmlList += "</ol>";
        const isOverTextLimit = htmlList.length > 500;
        const answer = {
          text: htmlList,
          fullText: htmlList,
          type: editorConstants.STUDY_ASSISTANT_TYPES.ANSWER,
          timestamp: new Date(),
          viewAll: isOverTextLimit,
        };

        setTimeout(() => {
          this.pushToQuestionsAnswersList(answer);
        }, 2000);

        const selectablePrompt = {
          prompt: {
            text: "Show me the answers",
            timestamp: new Date(),
            requestValue: "questionAnswers",
          },
          type: editorConstants.STUDY_ASSISTANT_TYPES.SELECTABLE,
        };

        setTimeout(() => {
          this.pushToQuestionsAnswersList(selectablePrompt);
        }, 2000);
      } else if (
        prompt.requestValue === "quizFive" &&
        this.quizList &&
        this.quizList.length > 0
      ) {
        let htmlList = "<ol>";
        for (let item of this.quizList.slice(0, 5)) {
          htmlList += "<li>" + item.question + "</li>";
        }
        htmlList += "</ol>";
        const isOverTextLimit = htmlList.length > 500;
        const answer = {
          text: htmlList,
          fullText: htmlList,
          type: editorConstants.STUDY_ASSISTANT_TYPES.ANSWER,
          timestamp: new Date(),
          viewAll: isOverTextLimit,
        };

        setTimeout(() => {
          this.pushToQuestionsAnswersList(answer);
        }, 2000);

        const selectablePrompt = {
          prompt: {
            text: "Show me the answers",
            timestamp: new Date(),
            requestValue: "questionAnswers",
            fiveQuestions: true,
          },
          type: editorConstants.STUDY_ASSISTANT_TYPES.SELECTABLE,
        };

        setTimeout(() => {
          this.pushToQuestionsAnswersList(selectablePrompt);
        }, 2000);
      } else if (
        prompt.requestValue === "questionAnswers" &&
        this.quizList &&
        this.quizList.length > 0
      ) {
        let htmlList = "<ol>";
        for (let item of prompt.fiveQuestions
          ? this.quizList.slice(0, 5)
          : this.quizList) {
          htmlList += "<li>" + item.answer + "</li>";
        }
        htmlList += "</ol>";
        const isOverTextLimit = htmlList.length > 500;
        const answer = {
          text: htmlList,
          fullText: htmlList,
          type: editorConstants.STUDY_ASSISTANT_TYPES.ANSWER,
          timestamp: new Date(),
          viewAll: isOverTextLimit,
        };

        this.showAnswerThenReprompt(answer);
      } else if (
        prompt.requestValue === "recommendations" &&
        this.recommendationsList &&
        this.recommendationsList.length > 0
      ) {
        let htmlList = "<ol>";
        for (let item of this.recommendationsList) {
          htmlList +=
            "<li>" +
            "<b>" +
            item.name +
            "</b>" +
            "<br>" +
            item.description +
            "<br>" +
            "<a href='" +
            item.url +
            "'>" +
            item.url +
            "</a>";
          ("</li>");
        }
        htmlList += "</ol>";
        const isOverTextLimit = htmlList.length > 500;
        const answer = {
          text: htmlList,
          fullText: htmlList,
          type: editorConstants.STUDY_ASSISTANT_TYPES.ANSWER,
          timestamp: new Date(),
          viewAll: isOverTextLimit,
        };

        this.showAnswerThenReprompt(answer);
      } else if (
        prompt.requestValue === "tips" &&
        this.tipsString &&
        this.tipsString !== ""
      ) {
        const text = convertOrderedListStringToHtml(this.tipsString);
        const isOverTextLimit = text.length > 500;
        const answer = {
          text: text,
          fullText: text,
          type: editorConstants.STUDY_ASSISTANT_TYPES.ANSWER,
          timestamp: new Date(),
          viewAll: isOverTextLimit,
        };

        this.showAnswerThenReprompt(answer);
      } else if (
        prompt.requestValue === "flashcard-prompt" &&
        this.quizList &&
        this.quizList.length > 0
      ) {
        const answer = {
          text: "",
          type: editorConstants.STUDY_ASSISTANT_TYPES.FLASHCARD,
          timestamp: new Date(),
        };

        this.showAnswerThenReprompt(answer);
      } else if (prompt.requestValue) {
        // requested value is not available
        console.error(`value ${prompt.requestValue} does not exist`);
        const answer = {
          text:
            "Apologies, I'm having trouble retrieving the prompt response " +
            "at the moment. Please try again later. Thank you for your under" +
            "standing!",
          type: "error",
          timestamp: new Date(),
        };
        this.showAnswerThenReprompt(answer);
      }
    },

    /**
     * Shows the generated answer after 2 seconds then reprompts
     * with the initial prompts
     */
    showAnswerThenReprompt(answer) {
      delay(2000)
        .then(() => {
          this.pushToQuestionsAnswersList(answer);
        })
        .then(() => {
          delay(2000).then(() => {
            this.pushToQuestionsAnswersList({
              type: "padded-divider",
            });
            this.pushToQuestionsAnswersList({
              text: "Ask another question?",
              type: "",
              timestamp: new Date(),
            });
            STUDY_ASSISTANT_INITIAL_PROMPTS.forEach((prompt) => {
              this.pushToQuestionsAnswersList(prompt);
            });
          });
        });
    },
  },
};
</script>

<style lang="scss">
.study-assistant {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
  overflow: auto;
  position: relative;

  .questions-answers-list {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    padding: 1.6rem;
    flex: 1;
    overflow: auto;
    scroll-behavior: smooth;

    .bubble {
      background-color: map-get($colours, accent-purple-3);
      max-width: 85%;
      border: 0.1rem solid map-get($colours, accent-purple);
      border-radius: 1.6rem;
      font-size: 1.6rem;
      padding: 0.8rem 1.2rem;
      align-self: flex-start;
      box-shadow: $small-crisp-box-shadow;
      word-wrap: break-word;

      &.selectable {
        cursor: pointer;
        background-color: map-get($colours, accent-purple);
        border-radius: 1.6rem;
      }

      &.selected {
        align-self: flex-end;
        background-color: map-get($colours, white);
        color: map-get($colours, grey-7);
      }

      &.padded-divider {
        all: unset;

        .padded-divider-hr {
          margin: 1.2rem 0;
          height: 0.2rem;
          color: rgba(25, 25, 25, 0.1);
          background-color: rgba(25, 25, 25, 0.1);
          border: none;
        }
      }

      &.answer {
        max-width: 100%;
        white-space: pre-wrap;

        a {
          color: rgba(210, 200, 250, 1);

          &:hover {
            filter: brightness(90%);
          }
        }

        ol,
        ul {
          list-style-position: inside;
          padding: 0;

          li {
            margin-top: 0.8rem;
          }
        }
      }

      &._answer {
        max-width: 100%;
        border-radius: 0;
        border: none;
        border-left: 0.4rem solid map-get($colours, accent-purple);
        white-space: pre-wrap;
        background: pink;
      }

      &.error {
        color: map-get($colours, white);
        border-color: rgba(237, 64, 53, 0.32);
        background-color: rgba(237, 64, 53, 0.18);
      }

      .show-more-border {
        padding-top: 1.4rem;
        border-top: 0.1rem solid rgba(25, 25, 25, 0.6);
        margin-top: 1.6rem;
      }

      .show-more {
        background: none;
        margin: 0 0 1.6rem;
        display: flex;
        justify-content: space-between;
        color: white;
        width: 100%;

        button {
          color: white;
        }
      }
    }
  }

  .input-area {
    display: flex;
    flex-direction: row;
    border-top: 0.1rem solid map-get($component-colours, border-light-faint);

    .input-field {
      flex: 1;
      background: transparent;
      border: none;
      color: map-get($colours, white);
      font-family: $font-secondary;
      padding: 1.2rem 1.6rem;
      resize: none;
      outline: none;

      &::placeholder {
        color: map-get($colours, grey-2);
      }
    }
  }

  .locked-panel {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 999;
    background-color: map-get($colours, accent-purple-2);
    display: flex;
    flex-direction: column;
    align-items: center;

    .background-circle-1 {
      height: 7.2rem;
      width: 7.2rem;
      background-color: transparentize(
        map-get($colours, accent-purple-3),
        0.46
      );
      position: relative;

      .background-circle-2 {
        height: 6.4rem;
        width: 6.4rem;
        background-color: transparentize(
          map-get($colours, accent-purple-3),
          0.22
        );

        img {
          width: 4rem;
          height: 4rem;
        }
      }
    }

    .background-circle-1,
    .background-circle-2 {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }

    .subscription-prompt-button {
      align-self: flex-start;
    }
  }
}

.study-assistant-details {
  background-color: map-get($colours, accent-purple-4);
  width: 54rem;
  border-radius: 1.2rem;
  overflow: hidden;

  .p-dialog-header {
    display: none;
  }

  header {
    color: map-get($colours, white);
    text-align: center;
    position: relative;
    width: 100%;

    h1 {
      font-size: 2rem;
      margin: 2.4rem 0 3.2rem;
      letter-spacing: 0.05rem;
    }

    .magic-wand-icon {
      width: 2.8rem;
      top: 0.5rem;
      position: relative;
    }

    .close-button {
      position: absolute;
      top: 1.6rem;
      right: 1.6rem;
    }
  }

  .p-dialog-content {
    background-color: map-get($colours, accent-purple-4);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: map-get($colours, white);
    font-family: $font-secondary;
    padding: 0;

    .poster-image {
      width: 46rem;
    }

    .bottom-section {
      background-color: map-get($colours, accent-purple-3);
      padding: 1.6rem 2.4rem 3.2rem;

      p {
        margin: 0;
        letter-spacing: 0.04rem;
      }

      > *:not(:first-child) {
        margin-top: 1.6rem;
      }

      .explore-plans-button {
        width: 25rem;
        margin: 4rem auto 0;
      }
    }
  }
}

.show-more-dialog {
  .p-dialog {
    border-radius: 1.2rem;
  }

  .p-dialog-header {
    display: flex;
    justify-content: flex-end;
  }

  .show-more-dialog-header {
    font-size: 1.8rem;
    display: flex;
    justify-content: space-between;
    padding: 1.9rem 0;
    color: white;
    font-family: "Averta";
    font-size: 1.4rem;
    font-weight: bold;

    .temp {
      width: 20%;
      display: flex;
      justify-content: flex-end;
    }
  }

  .show-more-dialog-body {
    font-size: 1.4rem;
    height: 38.4rem;
    width: 30rem;
    padding-right: 0.8rem;
    overflow-y: scroll;
    color: white;
    white-space: pre-wrap;
    word-wrap: break-word;

    a {
      color: rgba(210, 200, 250, 1);

      &:hover {
        filter: brightness(90%);
      }
    }

    ol,
    ul {
      list-style-position: inside;
      padding: 0;

      li {
        margin-top: 0.8rem;
      }
    }
  }

  .show-more-dialog-icons {
    display: flex;
    justify-content: flex-end;
    margin: 2.4rem 0px;

    button {
      margin-left: 1rem;
    }
  }

  .p-dialog-header {
    display: none;
  }

  .p-dialog-content {
    background-color: map-get($colours, accent-purple-2);
    border-radius: 1.2rem;
    margin: 0;
    padding: 0 0.8rem 0 1.8rem;
  }
}

.bubble-icons {
  display: flex;
  justify-content: flex-end;
  margin-top: 1.4rem;

  button {
    margin-left: 1rem;
  }
}

.flashcard-prompt {
  width: 24rem;
  height: 22.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: map-get($colours, accent-purple-3);
  border-radius: 1.6rem;
  font-size: 1.4rem;
  box-shadow: $small-crisp-box-shadow;

  .flashcard-prompt-box {
    width: 20.8rem;
    height: 19.6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: map-get($colours, accent-purple-4);
    border-radius: 1.6rem;
    padding: 1.6rem 1.6rem 1.6rem;

    .flashcard-prompt-box-text {
      text-align: center;
      padding: 1.4rem 0 1.4rem;
    }

    .flashcard-prompt-box-button {
      background-color: map-get($colours, accent-purple);
      width: 100%;
      height: 4rem;
      font-size: 1.4rem;
    }
  }
}

.flashcard {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40.8rem;
  overflow: hidden;

  .flashcard-top {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 3.2rem;

    .flashcard-top-page {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.4rem;
      width: 71px;
      height: 40px;
      background-color: map-get($colours, accent-purple);
      border-radius: 0.8rem;

      .flashcard-top-page-prev {
        transform: rotateY(180deg);
      }
    }
  }

  .flashcard-cards {
    display: flex;
    justify-content: space-between;

    .flashcard-cards-left {
      width: 29.8rem;
      height: 44.6rem;

      transform: rotate(-3.15deg) translateX(28rem) translateY(2.2rem);
      background-color: map-get($colours, accent-purple-4);

      border-radius: 3rem;
      z-index: -999;
    }

    .flashcard-cards-center {
      width: 29.8rem;
      height: 44.6rem;
      background-color: map-get($colours, accent-purple);
      border-radius: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 3.4rem;
    }

    .flashcard-cards-back {
      background-color: map-get($colours, accent-purple-3);
      font-size: 1.4rem;
    }

    .flashcard-cards-right {
      width: 29.8rem;
      height: 44.6rem;

      transform: rotate(3.15deg) translateX(-28rem) translateY(2.2em);
      background-color: map-get($colours, accent-purple-4);

      border-radius: 3rem;
      z-index: -999;
    }
  }

  .flashcard-icons {
    display: flex;
    align-items: center;
    margin-top: 3.2rem;
    .flashcard-icons-icon {
      margin: 0 1.6rem 0;
      height: 4rem;
      width: 4rem;
      background-color: white;
      border-radius: 0.8rem;

      display: flex;
      align-items: center;
      justify-content: center;

      img {
        color: black;
      }
    }
  }
}

.flashcard-end {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 6.1rem 4.1rem 6.8rem;

  .flashcard-end-title {
    padding: 2.4rem 0 1.6rem;
    font-size: 2.4rem;
  }

  .flashcard-end-body {
    padding-bottom: 9.6rem;
    font-size: 1.4rem;
  }

  .flashcard-end-button {
    width: 100%;
    height: 4.8rem;
    font-size: 1.4rem;
    border: 0.1rem map-get($colours, accent-purple);
  }

  .flashcard-end-startover {
    color: map-get($colours, accent-purple-2);
    background: #fbfbfa;
  }

  .flashcard-end-generatenewcards {
    margin-top: 1.6rem;
    color: white;
    background: map-get($colours, accent-purple);
  }
}

// .flashcard-prompt {
//   width: 24rem;
//   height: 22.4rem;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background-color: map-get($colours, accent-purple-3);
//   border-radius: 1.6rem;
//   font-size: 1.4rem;
//   box-shadow: $small-crisp-box-shadow;

//   .flashcard-prompt-box {
//     width: 20.8rem;
//     height: 19.6rem;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     // align-items: center;
//     background-color: map-get($colours, accent-purple-4);
//     border-radius: 1.6rem;
//     padding: 1.6rem 1.6rem 1.6rem;

//     .flashcard-prompt-box-icon {
//     }

//     .flashcard-prompt-box-text {
//       text-align: center;
//       padding: 1.4rem 0 1.4rem;
//     }

//     .flashcard-prompt-box-button {
//       background-color: map-get($colours, accent-purple);
//       width: 100%;
//       height: 4rem;
//       font-size: 1.4rem;
//     }

//   }

//   // background-color: blue;
// }
</style>
