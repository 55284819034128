<template>
  <div class="logo-header">
    <img :src="MPLogoWhite" class="logo" />
    <div class="text">
      <div class="app-name">Messenger Pigeon</div>
      <div class="company-name">By Habitat Learn</div>
    </div>
  </div>
</template>

<script>
import MPLogoWhite from "../assets/images/logos/MP-Logo-Light.svg";

export default {
  data() {
    return {
      MPLogoWhite,
    };
  },
};
</script>

<style lang="scss">
.logo-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 8.8rem;

  .logo {
    height: 4rem;
    margin-right: 1.2rem;
  }

  .text {
    display: flex;
    flex-direction: column;

    .app-name {
      font-family: $font-accent;
      font-weight: 500;
      font-size: 2rem;
      letter-spacing: 0.015rem;
      line-height: 2.4rem;
    }

    .company-name {
      font-family: $font-secondary;
      font-size: 1rem;
      line-height: 1.6rem;
    }
  }
}
</style>
