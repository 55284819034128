<template>
  <div class="loading-screen" v-if="loading">
    <span>Loading...</span>
    <ProgressSpinner class="spinner bottom" />
  </div>
  <div class="panels-wrapper" v-else>
    <SubscriptionSignup
      :visible="
        this.firstLogin &&
        !currentUserSubscriptionDetails.isActive &&
        !isServiceProvider &&
        !isOrgUser &&
        showSubscriptionSignupDialog
      "
      :setVisible="setShowSubscriptionSignupDialog"
    />

    <Onboarding
      v-if="this.firstLogin && !showSubscriptionSignupDialog"
      :setJustSubscribed="setJustSubscribed"
    />

    <div class="left-wrapper" style="outline: none" tabindex="0">
      <InfoPanel
        class="info-panel"
        :isOpen="infoPanelOpen"
        :toggleInfoPanelOpen="toggleInfoPanelOpen"
        :toggleSettingsOpen="toggleSettingsOpen"
        sessionBrowserPage
        :openNewSessionModal="setNewSessionModalVisible"
      />
    </div>
    <div class="standby-content content-panel">
      <!-- Master Wrapper -->
      <div v-if="!showPrebookedMenu && !showInstantMenu" class="sessions-panel">
        <Dialog
          v-model:visible="enterSessionModal"
          modal
          :draggable="false"
          dismissableMask
          header="Enter your session ID"
          class="session-id-dialog"
        >
          <p class="title">Please enter the session ID you wish to access</p>
          <div class="input-row">
            <InputText
              id="document-name"
              placeholder="Session ID"
              type="text"
              v-model.trim="documentName"
              v-tooltip.top="'Enter a session ID as found on Beehive'"
              @keyup.enter="goToSession"
            />
            <div class="error-container">
              <Transition name="slide-up" mode="out-in">
                <p v-if="errorMessage" class="input-error">
                  {{ errorMessage }}
                </p>
              </Transition>
            </div>
          </div>
          <template #footer>
            <RoundedButton
              text="Cancel"
              @Click="enterSessionModal = false"
              grey
              morePadding
              lessRound
              wide
            />
            <RoundedButton
              text="Join"
              :onClick="goToSession"
              accent
              morePadding
              lessRound
              wide
            />
          </template>
        </Dialog>
        <h2 class="header">Home</h2>
        <StandbySessions />
        <div class="button-wrapper" v-if="isServiceProvider">
          <RoundedButton
            :text="sessionButtonText"
            @Click="sessionButton()"
            reverse
            accent
            secondaryFont
            slim
            bold
            monospace
            morePadding
          />
        </div>
      </div>
      <div class="standby-card-wrapper" v-else>
        <Card class="join-session-card card">
          <template #content>
            <button
              v-if="!hideBackButton"
              class="back-button"
              @click="
                () => {
                  this.showPrebookedMenu = false;
                  this.showInstantMenu = false;
                }
              "
            >
              <img :src="ChevronLeftGreyIcon" />
              <span>Back</span>
            </button>
            <div v-if="this.showPrebookedMenu" class="content">
              <p class="title">
                Please enter the session ID you wish to access
              </p>
              <div class="input-row">
                <InputText
                  id="document-name"
                  placeholder="Session ID"
                  type="text"
                  v-model.trim="documentName"
                  v-tooltip.top="'Enter a session ID as found on Beehive'"
                  @keyup.enter="goToSession"
                />
                <RoundedButton
                  text="Join"
                  :onClick="goToSession"
                  slim
                  fitWidth
                  lessRound
                  accent
                />
              </div>
              <div class="error-container">
                <Transition name="slide-up" mode="out-in">
                  <p v-if="errorMessage" class="input-error">
                    {{ errorMessage }}
                  </p>
                </Transition>
              </div>
            </div>
            <div v-else class="content">
              <p class="title">Instant Session</p>
              <p class="subtitle">
                Instantly generate a new Messenger Pigeon session
              </p>
              <RoundedButton
                text="Start Session"
                :onClick="goToSessionSetup"
                slim
                fitWidth
                lessRound
                accent
              />
            </div>
          </template>
        </Card>
      </div>
    </div>
  </div>
  <NewSessionModal
    :visible="newSessionModalVisible"
    :setVisible="setNewSessionModalVisible"
  />
</template>
<script>
import {
  logout,
  getCurrentUserSubscriptionDetails,
} from "../assets/helpers_legacy";
import InfoPanel from "../components/InfoPanel";
import RoundedButton from "../components/RoundedButton";
import ChevronRightPurpleIcon from "../assets/images/Chevron-right-purple.svg";
import ChevronLeftGreyIcon from "../assets/images/Chevron-left-grey.svg";
import StandbySessions from "../components/StandbySessions.vue";
import Onboarding from "../components/Onboarding/Onboarding.vue";
import SubscriptionSignup from "../components/Onboarding/SubscriptionSignup.vue";
import NewSessionModal from "../components/NewSessionModal.vue";

const uuidV4Regex =
  /^[A-F\d]{8}-[A-F\d]{4}-4[A-F\d]{3}-[89AB][A-F\d]{3}-[A-F\d]{12}$/i;

export default {
  components: {
    InfoPanel,
    RoundedButton,
    StandbySessions,
    Onboarding,
    SubscriptionSignup,
    NewSessionModal,
  },

  data() {
    return {
      ChevronRightPurpleIcon,
      ChevronLeftGreyIcon,
      documentName: "",
      notetakerId: "",
      errorMessage: null,
      showPrebookedMenu: false,
      showInstantMenu: false,
      hideBackButton: false,
      loading: true,
      enterSessionModal: false,
      justSubscribed: false,
      serviceProvider: null,
      infoPanelOpen: false,
      settingsShowMainMenu: true,
      settingsActiveTabIndex: 0,
      activeSidePanel: null,
      PANEL_SETTINGS: "SETTINGS",
      showSubscriptionSignupDialog: true,
      sessionButtonText: "Enter Session Id",
      currentUserSubscriptionDetails: {},
      newSessionModalVisible: false,
    };
  },

  // watchers below are to clear error message for blank captioning id
  watch: {
    documentName(newName) {
      if (newName !== "") {
        this.errorMessage = null;
      }
    },
    notetakerId(newName) {
      if (newName !== "") {
        this.errorMessage = null;
      }
    },
  },

  async created() {
    switch (this.$store.state.currentUser.role.toLowerCase()) {
      case "worker":
      case "service_provider":
        this.serviceProvider = true;
        break;
      default:
        break;
    }

    this.currentUserSubscriptionDetails =
      await getCurrentUserSubscriptionDetails();
    this.loading = false;
  },

  methods: {
    setShowSubscriptionSignupDialog(bool) {
      this.showSubscriptionSignupDialog = bool;
    },

    sessionButton() {
      this.enterSessionModal = true;
    },

    goToSession() {
      if (this.documentName === "") {
        this.errorMessage =
          "Blank session id! Please enter a valid session ID.";
      } else {
        const isValidUUID = uuidV4Regex.test(this.documentName);
        if (isValidUUID) {
          this.$store.commit("setSessionDetails", {
            id: this.documentName,
          });
          this.$router.push({
            name: "SessionInitializer",
          });
        } else {
          this.errorMessage = "The session id format is invalid.";
        }
      }
    },

    goToSessionSetup() {
      this.$router.push({
        name: "SessionSetup",
        query: { hideBackButton: this.hideBackButton },
      });
    },

    clickedPrebooked() {
      this.showPrebookedMenu = true;
    },

    clickedInstant() {
      this.showInstantMenu = true;
    },

    logout() {
      logout(this.$router);
    },

    toggleInfoPanelOpen() {
      this.infoPanelOpen = !this.infoPanelOpen;
      this.shouldCloseInfoPanel = false;
    },
    toggleSettingsOpen() {
      if (!this.settingsOpen) {
        if (!this.infoPanelOpen) {
          this.infoPanelOpen = true;
          this.shouldCloseInfoPanel = true;
        }
        this.activeSidePanel = this.PANEL_SETTINGS;
      } else {
        this.closeSettings();
      }
    },
    closeSettings() {
      if (this.shouldCloseInfoPanel) {
        this.infoPanelOpen = false;
      }
      this.activeSidePanel = null;
      this.settingsShowMainMenu = true;
    },

    settingsSetActiveTabIndex(index) {
      this.settingsActiveTabIndex = index;
    },

    settingsSetShowMainMenu(boolean) {
      this.settingsShowMainMenu = boolean;
    },

    setJustSubscribed(bool) {
      this.justSubscribed = bool;
    },

    setNewSessionModalVisible(visible) {
      this.newSessionModalVisible = visible;
    },
  },

  computed: {
    settingsOpen() {
      return this.activeSidePanel === this.PANEL_SETTINGS;
    },

    isServiceProvider() {
      return (
        this.$store.state.currentUser.role.toLowerCase().trim() === "worker" ||
        this.$store.state.currentUser.role.toLowerCase().trim() ===
          "service_provider"
      );
    },

    isOrgUser() {
      return (
        this.$store.state.currentUser.role.toLowerCase().trim() === "org user"
      );
    },
  },

  mounted() {
    this.firstLogin = JSON.parse(localStorage.getItem("firstLogin"));
  },

  unmounted() {
    localStorage.setItem("firstLogin", false);
  },
};
</script>

<style lang="scss">
.left-wrapper {
  position: relative;

  .side-panel {
    background-color: map-get($component-colours, chat-background);
    color: map-get($component-colours, chat-font-primary);
    min-width: $side-panel-width;
    position: absolute;
    top: 0;
    left: 0;
    width: $side-panel-width;
    height: 100%;
    z-index: 2;

    @media only screen and (max-width: 1000px) {
      min-width: $side-panel-width-mobile;
      width: $side-panel-width-mobile;
    }

    ::selection {
      background-color: map-get(
        $component-colours,
        font-background-colour-highlighted
      );
    }
    ::-moz-selection {
      background-color: map-get(
        $component-colours,
        font-background-colour-highlighted
      );
    }
  }
}
.standby-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 4rem 5.6rem;
  overflow: auto;

  .sessions-panel {
    .header {
      @include h2-accent;
      font-weight: 500;
    }

    .subheader {
      @include section-sub-header;
    }

    .button-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      overflow: auto;
      gap: 1rem 1.5rem;

      @media screen and (max-width: 600px) {
        justify-content: flex-start;
        * {
          flex: 1 0 100%;
        }
      }
    }
  }

  .standby-card-wrapper {
    padding: 3.2rem;
    margin: auto;

    .card {
      width: 34rem;
      height: 50rem;
      padding: 3.2rem 2.4rem;
    }

    .standby-card {
      font-size: 1.4rem;
      font-family: $font-secondary;
      display: flex;
      flex-direction: column;
      align-items: center;

      .p-card-body {
        height: 100%;
        display: flex;
        flex-direction: column;

        .p-card-content {
          flex: 1;
          padding: 0;

          .content {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
        }
      }

      .title {
        text-align: center;
        margin: 0;
      }

      .pigeon {
        font-size: 2rem;
      }

      .placeholder {
        font-size: 1rem;
        visibility: hidden;
      }

      .buttons {
        > * {
          width: 33%;
          // margin-left: 1rem;
        }
      }

      .subtitle {
        @include section-header-3;
        margin-top: 3.2rem;
        margin-bottom: 0.8rem;
      }

      .session-type-button {
        text-align: left;
        border-bottom: 0.1rem solid grey;
        padding: 1.6rem 0;
        display: flex;
        flex-direction: row;
        align-items: center;

        img {
          transition: 0.4s;
        }

        &:hover,
        &:active {
          &:not([disabled]) {
            filter: none;
          }

          img {
            transform: scale(1.5) translateX(0.4rem);
          }
        }

        .text-content {
          header {
            @include h3;
          }

          main {
            @include callout;
            color: map-get($colours, grey-5);
            margin-top: 0.8rem;
          }
        }
      }
    }

    .join-session-card {
      padding: 0 2rem;

      .back-button {
        display: flex;
        align-items: center;
        color: map-get($colours, grey-3);
        padding: 0;
        margin-left: -0.8rem;
        @include section-header-3;
        margin-top: 0.4rem;

        &:hover,
        &:active {
          &:not([disabled]) {
            filter: none;
            text-decoration: underline;
          }
        }

        span {
          margin-top: 0.2rem;
        }
      }

      .content {
        margin-top: 4.8rem;

        .title {
          @include section-header-3;
          margin-bottom: 1.6rem;
        }

        .subtitle {
          @include callout;
          color: map-get($colours, grey-5);
          margin: 1.6rem 0;
        }

        .input-row {
          display: flex;

          input {
            flex: 1;
            height: 3.6rem;
            font-size: 1.4rem;
            margin-right: 0.8rem;
          }
        }
      }
    }
  }
}
.document-wrapper {
  display: flex;
  margin-bottom: 0.6rem;

  .p-float-label {
    flex: 1;
    margin-right: 0.6rem;

    input {
      width: 100%;
    }
  }

  button {
    font-size: 1.4rem;
  }
}

.input-error {
  color: map-get($component-colours, font-colour-error);
  font-size: 1.4rem;
}

.session-id-dialog {
  border-radius: 6px;
  position: relative;

  .p-dialog-header {
    padding: 4.5rem 4rem 1.6rem 4rem;
    border-radius: 6px 6px 0 0;

    .p-dialog-title {
      flex-grow: 1;
      text-align: center;
      @include h1;
      font-family: $font-accent;
    }

    .p-dialog-header-icons {
      position: absolute;
      right: 1.25rem;
      top: 1.25rem;
    }
  }

  .p-dialog-content {
    padding: 0 4rem 0 4rem;

    .p-inputtext {
      width: 100%;
    }
  }
  .p-dialog-footer {
    padding: 4rem 4rem 4rem 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1.5rem;
  }
}
</style>
