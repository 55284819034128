import HTMLtoDOCX from "html-to-docx";
export const downloadFile = (
  docHTML,
  sessionCurrentName,
  typeSuffix
) => {
  generateTranscript(
    docHTML,
    "Arial",
    sessionCurrentName + "-" + typeSuffix + ".docx"
  ).catch((error) => console.error(error));
};

/**
 * Parses HTML string and makes any necessary modifications to ensure
 * proper docx output
 */
const filterHtml = (htmlString) => {
  const fontColourRegex = /color:([^"";]*)/gm;

  // Strip any font colour changes (likely from from copy-pasted text)
  let filteredHTML = htmlString.replace(fontColourRegex, "");

  filteredHTML = filteredHTML.replaceAll("<em>", "<i>");
  filteredHTML = filteredHTML.replaceAll("</em>", "</i>");

  filteredHTML = filteredHTML.replaceAll(
    "<strong>",
    "<span style='font-weight:bold'>"
  );
  filteredHTML = filteredHTML.replaceAll("</strong>", "</span>");

  return filteredHTML;
};

export const generateTranscript = async (
  transcriptHTML,
  fontFile,
  filePath
) => {
  let today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = String(today.getFullYear());

  today = mm + "-" + dd + "-" + yyyy;

  const filteredHtml = filterHtml(transcriptHTML.toString());

  const fileBuffer = await HTMLtoDOCX(filteredHtml, null, {
    table: { row: { cantSplit: true } },
    footer: false,
    pageNumber: false,
  });

  const url = window.URL.createObjectURL(fileBuffer);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filePath); //or any other extension
  document.body.appendChild(link);
  link.click();
  URL.revokeObjectURL(link.href);
};
