<template>
  <div
    class="document"
    :class="this.$store.state.lightModeActive ? 'light-mode-active' : ''"
  >
    <Editor
      :unreadMessages="unreadMessages"
      :numTotalUnreadMessages="numTotalUnreadMessages"
      :incrementNumUnreadMessages="incrementNumUnreadMessages"
      :clearNumUnreadMessages="clearNumUnreadMessages"
      :users="users"
      :setUsers="setUsers"
      :currentUser="currentUser"
      :setEditor="setEditor"
      :editor="editor"
      :updateCurrentUser="updateCurrentUser"
    />
  </div>
</template>

<script>
import Editor from "../components/Editor.vue";
import ChatIcon from "../assets/images/Chat.svg";

export default {
  name: "App",

  props: {
    db: Object,
  },

  components: {
    Editor,
  },

  created() {
    document.title = "Messenger Pigeon | " + this.$route.params.document;
  },

  data() {
    return {
      users: [],
      numTotalUnreadMessages: 0,
      unreadMessages: new Map(),
      messageLists: new Map(),
      disableTransitions: true,
      ChatIcon,
      editor: null,
    };
  },

  beforeUnmount() {
    if (this.editor) {
      this.editor.destroy();
    }
    if (this.provider) {
      this.provider.destroy();
    }
  },

  methods: {
    setEditor(editor) {
      this.editor = editor;
    },

    popup() {
      window.open(
        "http://localhost:8080/documents/e.ear",
        "popUpWindow",
        "height=500,width=400,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes"
      );
    },

    setUsers(users) {
      this.users = users;
    },

    clearNumUnreadMessages(id) {
      this.numTotalUnreadMessages =
        this.numTotalUnreadMessages - (this.unreadMessages.get(id) || 0);
      this.unreadMessages.set(id, 0);
    },

    incrementNumUnreadMessages(id) {
      this.numTotalUnreadMessages = this.numTotalUnreadMessages + 1;
      if (this.unreadMessages.get(id)) {
        this.unreadMessages.set(id, this.unreadMessages.get(id) + 1);
      } else {
        this.unreadMessages.set(id, 1);
      }
    },

    addMessageToList(id, message) {
      const list = this.messageLists.get(id);
      if (list) {
        this.messageLists.set(id, list.push(message));
      } else {
        this.messageLists.set(id, [message]);
      }
    },
  },
};
</script>

<style lang="scss">
@import "../styles/_mixins.scss";

.document {
  display: flex;
  flex-direction: row;
  height: 100%;

  &.light-mode-active {
    background-color: map-get(
      $component-colours,
      background-primary-light-mode
    );
  }
}

.chat-mobile {
  .p-overlaypanel-content {
    height: 100%;
  }

  // &.show {
  //   width: $side-panel-width-in-rem + rem;
  //   min-width: $side-panel-width-in-rem + rem;
  // }
}
</style>
