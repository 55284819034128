<template>
  <Dialog
    :visible="props.visible"
    header="false"
    :closable="false"
    class="request-notes-dialog"
    modal
  >
    <div class="request-notes-dialog-wrapper">
      <img :src="OnboardingRecordNotesImage" class="record-notes-image" />
      <div class="request-notes-dialog-title">
        Request professional notes?
      </div>
      <div
        v-if="props.showTimeLimitExceeded"
        class="request-notes-dialog-text"
      >
        You’ve reached your 30-hour limit.
        Order professional notes with a one-time payment to reset your hours.
        Or, subscribe for unlimited recording and more features.
      </div>
      <div
        v-else
        class="request-notes-dialog-text"
      >
        Order professional notes and our experts will summarize key points for
        you. 
      </div>
      <div class="buttons">
        <button class="buttons-request" @click="setOrderFormVisible(true)">
          REQUEST NOTES
        </button>
        <button @click="goHome" class="buttons-home">MAYBE LATER</button>
      </div>
    </div>
  </Dialog>
  <RequestNotesOrderForm
    :visible="orderFormVisible"
    :recordingTitle="props.sessionTitle"
    :durationHMS="props.sessionDurationHMS"
    :durationMilliseconds="props.sessionDurationMS"
    :recordingUploadId="props.uploadId"
    :setOrderFormVisible="setOrderFormVisible"
    @order-successful="delayedGoHome"
  ></RequestNotesOrderForm>
</template>

<script setup lang="ts">
import { ref } from "vue";
const OnboardingRecordNotesImage = require("../assets/images/Onboarding-record-notes.png") as string;
import RequestNotesOrderForm from "./Orders/RequestNotesOrderForm.vue";

const props = defineProps({
  sessionTitle: {
    type: String,
    required: true,
  },
  sessionDurationHMS: {
    type: String,
    required: true,
  },
  sessionDurationMS: {
    type: String,
    required: true,
  },
  uploadId: {
    type: String,
    required: true,
  },
  visible: {
    type: Boolean,
    required: true,
  },
  setVisible: {
    type: Function,
    required: true,
  },
  showTimeLimitExceeded: {
    type: Boolean,
    default: false,
  }
});

const emit = defineEmits(["goHome"]);

const orderFormVisible = ref(false);

function setOrderFormVisible(visible: boolean) {
  orderFormVisible.value = visible;
}

function goHome() {
  emit("goHome");
  props.setVisible(false);
}

function delayedGoHome() {
  setTimeout(() => {
    goHome();
  }, 3000);
}
</script>

<style lang="scss">
.request-notes-dialog {
  width: 46.4rem;

  .p-dialog-header {
    display: none;
  }

  .p-dialog-content {
    background-color: map-get($colours, accent-purple-2);
    border-radius: 1.4rem;
    margin: 0;
    padding: 0;
  }

  .request-notes-dialog-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 0 4rem;
    margin: 0;
    padding: 0 2.4rem;
    background-color: map-get($colours, accent-purple-2);
    font-family: $font-secondary;
    border-radius: 1.4rem;

    img {
      width: 21.8rem;
      margin-bottom: 1.6rem;
      // height: 15rem;
    }

    .request-notes-dialog-title {
      font-family: $font-accent;
      margin: 0;
      padding: 0;
      margin-bottom: 2.4rem;
      text-align: left;
      font-size: 2.4rem;
    }

    .request-notes-dialog-text {
      font-size: 1.4rem;
      margin-bottom: 4rem;
    }

    .buttons {
      margin: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 0.8rem;
      width: 100%;
      .buttons-request {
        width: 30.4rem;
        height: 4.8rem;
        border-radius: 0.8rem;
        background-color: map-get($colours, accent-purple-1);
        color: white;
      }
      .buttons-home {
        color: #bababa;
        background-color: transparent;
        margin: 1.6rem 0;
      }
    }
  }
}
</style>
