<template>
  <div class="account">
    <form
      @submit.prevent="
        submitChangeInfo(accountFieldsInvalid, changepasswordFieldsInvalid)
      "
      class="account-form"
    >
      <SettingsBlock title="Profile">
        <div class="row-wrapper">
          <div class="settings-block-inputblock row-wrapper-item">
            <div class="settings-block-inputblock-label">FIRST NAME</div>
            <InputText
              id="firstname"
              type="text"
              v-model="firstname"
              disabled
              :placeholder="$store.state.currentUser.name.firstName"
              class="input-field"
            />
          </div>
          <div class="row-wrapper-gap"></div>
          <div class="settings-block-inputblock row-wrapper-item">
            <div class="settings-block-inputblock-label">LAST NAME</div>
            <InputText
              id="lastname"
              type="text"
              v-model="lastname"
              disabled
              :placeholder="$store.state.currentUser.name.lastName"
              class="input-field"
            />
          </div>
        </div>
        <div class="settings-block-inputblock">
          <div class="settings-block-inputblock-label">EMAIL</div>
          <InputText
            id="email"
            type="text"
            :placeholder="$store.state.currentUser.email"
            disabled
            v-model="v$.email.$model"
            class="input-field"
          />
        </div>
        <div class="settings-block-inputblock">
          <div class="settings-block-inputblock-label">LOCATION</div>
          <div>
            <Dropdown
              v-model="country"
              :options="COUNTRIES"
              optionLabel="name"
              :placeholder="'Select a country...'"
              class="input-field"
              :class="{
                'p-invalid': accountErrorMessage,
              }"
            />
          </div>
        </div>
      </SettingsBlock>
      <SettingsBlock title="Change password" class="change-password">
        <div class="settings-block-description">
          Once your password has been successfully updated, you'll be required
          to login again.
        </div>
        <div>
          <div class="settings-block-inputblock">
            <div class="settings-block-inputblock-label">CURRENT PASSWORD</div>
            <InputText
              id="currentPassword"
              type="password"
              v-model="v$.currentPassword.$model"
              class="input-field"
              :class="{
                'p-invalid': changepasswordErrorMessage,
              }"
            />
            <div class="settings-block-underlinedbutton">
              <UnderlinedButton
                text="Forgot password?"
                class="forgot-password-button"
                accent
                small
                type="button"
                @click="forgotpassword"
              />
            </div>
          </div>
          <div class="settings-block-inputblock new-password-field">
            <div class="settings-block-inputblock-label">
              NEW PASSWORD
              <PasswordValidatorTooltip />
            </div>
            <InputText
              id="newPassword"
              type="password"
              v-model="v$.newPassword.$model"
              class="input-field"
              :class="{
                'p-invalid': changepasswordErrorMessage,
              }"
            />
          </div>
          <div class="settings-block-inputblock">
            <div class="settings-block-inputblock-label">CONFIRM PASSWORD</div>
            <InputText
              id="newConfirmedPassword"
              type="password"
              v-model="v$.newConfirmedPassword.$model"
              class="input-field"
              :class="{
                'p-invalid': changepasswordErrorMessage,
              }"
            />
          </div>
        </div>
      </SettingsBlock>
      <div
        v-if="changepasswordErrorMessage !== ''"
        class="settings-errormessage settings-message"
      >
        {{ changepasswordErrorMessage }}
      </div>
      <div v-else class="settings-successmessage settings-message">
        {{ changepasswordSuccessMessage }}
      </div>
      <div
        v-if="accountErrorMessage !== ''"
        class="settings-errormessage settings-message"
      >
        {{ accountErrorMessage }}
      </div>
      <div v-else class="settings-successmessage settings-message">
        {{ accountSuccessMessage }}
      </div>
      <RoundedButton
        text="Save changes"
        mediumwide
        bold
        :loading="savechangesLoading"
        lessRound
        type="submit"
        class="save-changes-button"
      />
    </form>
  </div>
</template>

<script>
import SettingsBlock from "./SettingsBlock.vue";
import { logout, refreshCurrentUser } from "../../assets/helpers_legacy";
import useVuelidate from "@vuelidate/core";
import { LOGIN_SCREENS, COUNTRIES } from "../../assets/constants";
import { email, sameAs, required } from "@vuelidate/validators";
import UnderlinedButton from "../../components/UnderlinedButton";
import RoundedButton from "../../components/RoundedButton";
import axios from "axios";
import PasswordValidatorTooltip, {
  passwordValidatorMinimumLength,
  passwordValidatorHasUppercase,
  passwordValidatorHasLowercase,
  passwordValidatorHasNumber,
  passwordValidatorHasSpecial,
} from "../../components/Login/PasswordValidatorTooltip";

export default {
  setup: () => {
    const tokenMP = JSON.parse(localStorage.getItem("tokenMP"));

    return {
      v$: useVuelidate(),
      tokenMP,
    };
  },

  components: {
    RoundedButton,
    UnderlinedButton,
    PasswordValidatorTooltip,
    SettingsBlock,
  },

  data() {
    return {
      firstname: "",
      lastname: "",
      email: "",
      test: "",
      country: COUNTRIES.find((element) => {
        return element.code === this.$store.state.currentUser.location;
      }),

      currentPassword: "",
      newPassword: "",
      newConfirmedPassword: "",

      accountErrorMessage: "",
      changepasswordErrorMessage: "",

      savechangesLoading: false,

      accountSuccessMessage: "",
      changepasswordSuccessMessage: "",

      COUNTRIES,
    };
  },

  computed: {
    accountFieldsInvalid() {
      return {
        isValid: this.v$.email.$invalid,
        hasValue:
          this.email !== "" ||
          this.firstname !== "" ||
          this.lastname !== "" ||
          (this.country !== "" &&
            typeof this.country !== "undefined" &&
            this.country.code !== this.$store.state.currentUser.location),
      };
    },

    changepasswordFieldsInvalid() {
      return {
        isValid:
          this.v$.currentPassword.$invalid ||
          this.v$.newPassword.$invalid ||
          this.v$.newConfirmedPassword.$invalid,
        hasValue: !(
          this.v$.currentPassword.required.$invalid ||
          this.v$.newPassword.required.$invalid ||
          this.v$.newConfirmedPassword.required.$invalid
        ),
      };
    },
  },

  validations() {
    return {
      email: {
        email,
      },

      currentPassword: {
        required,
      },

      newPassword: {
        required,
        passwordValidatorHasLowercase,
        passwordValidatorHasUppercase,
        passwordValidatorMinimumLength,
        passwordValidatorHasNumber,
        passwordValidatorHasSpecial,
      },

      newConfirmedPassword: {
        required,
        sameAsNewPassword: sameAs(this.newPassword),
      },
    };
  },

  methods: {
    logout() {
      alert("Session has expired.");
      logout(this.$router);
    },

    forgotpassword() {
      localStorage.removeItem("token");
      this.$router.push(`/login/${LOGIN_SCREENS.FORGOTPASSWORD}`);
    },

    toggleInfoPanelOpen() {
      this.infoPanelOpen = !this.infoPanelOpen;
      // this.settingsOpen = this.infoPanelOpen;
      this.settingsOpen = false;
    },

    toggleSettingsOpen() {
      if (!this.settingsOpen) {
        // If we're opening the settings, make sure the info panel is open
        if (!this.infoPanelOpen) {
          this.infoPanelOpen = true;
          // this.shouldCloseInfoPanel = true;
        }
        this.settingsOpen = true;
        // this.activeSidePanel = this.PANEL_SETTINGS;
      } else {
        this.settingOpen = false;
      }
    },

    settingsSetActiveTabIndex(index) {
      this.settingsActiveTabIndex = index;
      this.infoPanelOpen = true;
      this.settingsShowMainMenu = true;
    },

    settingsClose() {
      this.settingsOpen = false;
    },

    settingsSetShowMainMenu(boolean) {
      this.settingsShowMainMenu = boolean;
    },

    // TODO: logout when password changed?
    submitChangeInfo(accountFieldCheck, changepasswordFieldCheck) {
      this.accountErrorMessage = "";
      this.changepasswordErrorMessage = "";
      this.accountSuccessMessage = "";
      this.changepasswordSuccessMessage = "";

      this.savechangesLoading = true;

      let jobFinished = 2;

      // check token expiration time code
      if (Date.parse(this.tokenMP.accessTokenExpiresAt) < Date.now()) {
        console.log("Access token expired", this.tokenMP.accessTokenExpiresAt);
        if (Date.parse(this.tokenMP.refreshTokenExpiresAt) < Date.now()) {
          // TODO: implement logout
          console.log("logout");
          this.logout();
          return;
        } else {
          // auth data
          const data = { refreshToken: this.tokenMP.refreshToken };
          axios
            .post(
              process.env.VUE_APP_MP_SERVER_API_URL + "auth/refresh",
              data,
              { withCredentials: false }
            )
            .then((response) => {
              if (response.data.status !== "success") {
                console.error("Error refreshing token", response.data.message);
                this.logout();
                return;
              } else {
                this.tokenMP = response.data.data;
                console.log("Received new token obj", this.tokenMP);
                localStorage.setItem("tokenMP", JSON.stringify(this.tokenMP));
                localStorage.setItem("accessToken", this.tokenMP.accessToken);
              }
            })
            .catch((error) => {
              console.log("Logging out", error);
              this.logout();
              return;
            });
        }
      }

      if (accountFieldCheck.hasValue) {
        if (!accountFieldCheck.isValid) {
          // has at least one valid value in the field
          const data = {
            ...(this.firstname !== "" && { firstName: this.firstname }),
            ...(this.lastname !== "" && { lastName: this.lastname }),
            ...(this.country !== "" &&
              typeof this.country !== "undefined" && {
                location: this.country.code,
              }),
            ...(this.email !== "" &&
              !this.v$.email.$invalid && { email: this.email }),
          };

          axios
            .put(process.env.VUE_APP_MP_SERVER_API_URL + "self", data, {
              withCredentials: false,
              headers: {
                Authorization: "Bearer " + this.tokenMP.accessToken,
              },
            })
            .then((response) => {
              jobFinished -= 1;
              this.savechangesLoading = jobFinished !== 0;
              if (response.data.status !== "success") {
                this.acountErrorMessage =
                  "Received error" + response.data.message;
              } else {
                // update value
                refreshCurrentUser().then(() => {
                  this.accountSuccessMessage =
                    "Your account details have been updated";
                });
              }
            })
            .catch((error) => {
              jobFinished -= 1;
              this.savechangesLoading = jobFinished !== 0;
              if (error.response.status === 500) {
                this.accountErrorMessage = "There was a server error.";
              } else {
                this.accountErrorMessage = `Received error ${error.response.status}`;
              }
            });
        } else {
          jobFinished -= 1;
          this.savechangesLoading = jobFinished !== 0;
          if (this.v$.email.$invalid) {
            this.accountErrorMessage =
              "Please enter an email in the following " +
              "format: email@example.com";
          } else {
            this.accountErrorMessage = "Please try again";
          }
        }
      } else {
        jobFinished -= 1;
        this.savechangesLoading = jobFinished !== 0;
      }

      if (changepasswordFieldCheck.hasValue) {
        if (!changepasswordFieldCheck.isValid) {
          // check current password
          const data = {
            currentPassword: this.currentPassword,
            newPassword: this.newPassword,
          };
          axios
            .put(
              process.env.VUE_APP_MP_SERVER_API_URL + "self/password",
              data,
              {
                withCredentials: false,
                headers: {
                  Authorization: "Bearer " + this.tokenMP.accessToken,
                },
              }
            )
            .then((response) => {
              jobFinished -= 1;
              this.savechangesLoading = jobFinished !== 0;
              if (response.data.status !== "success") {
                this.changepasswordErrorMessage = `${response.data.message}`;
              } else {
                // do password change here
                this.changepasswordSuccessMessage =
                  "Your password has been updated";
              }
            })
            .catch((error) => {
              jobFinished -= 1;
              this.savechangesLoading = jobFinished !== 0;
              if (error.response.status === 403) {
                this.changepasswordErrorMessage = "Incorrect current password";
              } else {
                this.changepasswordErrorMessage = `Server error ${error}`;
              }
            });
        } else {
          jobFinished -= 1;
          this.savechangesLoading = jobFinished !== 0;
          if (this.v$.newPassword.$invalid) {
            this.changepasswordErrorMessage =
              "Please follow the password format";
          } else if (this.v$.newConfirmedPassword.$invalid) {
            this.changepasswordErrorMessage =
              "The confirmed password value is different from the new password";
          } else {
            this.changepasswordErrorMessage =
              "Please enter the current password and new password";
          }
        }
      } else {
        jobFinished -= 1;
        this.savechangesLoading = jobFinished !== 0;
      }

      if (!changepasswordFieldCheck.hasValue && !accountFieldCheck.hasValue) {
        this.changepasswordErrorMessage = "Please enter a value";
        this.accountErrorMessage = "Please enter a value";
        this.savechangesLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.account {
  ::selection {
    background-color: map-get(
      $component-colours,
      font-background-colour-highlighted
    );
  }

  ::-moz-selection {
    background-color: map-get(
      $component-colours,
      font-background-colour-highlighted
    );
  }

  .settings-block {
    &.change-password {
      margin-top: 2.4rem;
      margin-bottom: 0.8rem;
    }

    .settings-block-description {
      margin-top: 0.8rem;
      font-size: 1.2rem;
      font-family: $font-secondary;
      color: map-get($colours, grey-3);
      letter-spacing: 0.05rem;
    }

    .settings-block-inputblock {
      margin: 2rem 0 0;

      .settings-block-inputblock-label {
        font-size: 1.2rem;
        color: map-get($colours, grey-1);
        display: flex;
        letter-spacing: 0.05rem;
        font-family: $font-secondary;

        img {
          color: white;
        }

        .settings-block-inputblock-label-icon {
          color: white;
          margin-left: 10px;
        }
      }

      &.new-password-field {
        margin-top: 0.8rem;
      }

      .p-dropdown {
        margin-top: 10px;
        background: map-get($colours, accent-purple-2);
        width: 100%;
      }

      .p-dropdown-label {
        color: map-get($colours, grey-2);
        padding: 0;
        font-size: 1.4rem;
      }

      .p-dropdown-trigger {
        color: white;
      }
    }

    .settings-block-underlinedbutton {
      display: flex;
      justify-content: flex-end;
      font-size: 1.2rem;
      margin: 8px 0 12px;
    }
  }

  ::placeholder {
    color: map-get($colours, grey-3);
  }

  .input-field {
    margin-top: 10px;
    background: map-get($colours, accent-purple-2);
    color: white;
    width: 100%;
    border-radius: 0.6rem;
    font-size: 1.4rem;
    padding: 1.2rem;
    font-family: $font-secondary;
    letter-spacing: 0.01rem;
  }

  .settings-dropdown {
    margin-top: 10px;
    background: map-get($colours, accent-purple-2);
    color: white;
    width: 100%;
  }

  .p-dropdown {
    background: yellow;
    .p-dropdown-items-wrapper {
      background: red;
      color: white;
    }
  }
}

.settings-message {
  font-size: 1.5rem;
  margin: 0.4rem 0;
  display: flex;
  align-items: center;
  font-family: $font-secondary;
  letter-spacing: 0.03rem;
}

.settings-errormessage {
  color: map-get($colours, red);
}

.settings-successmessage {
  color: map-get($colours, green);
}

.account-form {
  display: flex;
  flex-direction: column;

  .save-changes-button {
    align-self: flex-end;
    margin-top: 0.4rem;
  }
}

.row-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;

  .row-wrapper-gap {
    margin: 16px;
  }

  .row-wrapper-item {
    flex-grow: 1;
  }
}

.settings-account-page {
  padding-bottom: 8rem;

  .manage-subscription {
    p {
      font-size: 1.4rem;
    }

    .subscription-cancelling {
      margin-top: 1.6rem;
    }

    .relog-message {
      margin-top: 1rem;
      font-size: 1.2rem;
      color: map-get($colours, grey-3);
    }

    .modify-subscription-button {
      margin-top: 2.4rem;
    }
  }
}
</style>
