import * as Y from "yjs";
import { Editor } from "@tiptap/vue-3";
import Collaboration from "@tiptap/extension-collaboration";
import CollaborationCursor from "@tiptap/extension-collaboration-cursor";
import { HocuspocusProvider } from "@hocuspocus/provider";
import { EditorView } from "prosemirror-view";
import StarterKit from "@tiptap/starter-kit";
import { getRandomColor } from "../../assets/helpers_legacy";
import _ from "lodash";

export default {
  data() {
    return {
      provider: null,
      users: [],
      status: "connecting",
      ydoc: null,
    };
  },

  methods: {
    mounted(sessionId, additionalExtensions, onCreate, ref) {
      const ydoc = new Y.Doc();
      this.provider = new HocuspocusProvider({
        url: "wss://mp-collab-server.habitatlearn.com/collaboration",
        document: ydoc,
        name: sessionId,
        token: "secret-token",
      });
      this.provider.on("status", (event) => {
        this.status = event.status;
      });

      window.ydoc = ydoc;

      const editor = new Editor({
        extensions: [
          StarterKit.configure({
            history: false,
          }),
          ...additionalExtensions,
          Collaboration.configure({
            document: ydoc,
          }),
          CollaborationCursor.configure({
            provider: this.provider,
            user: {
              id: this.$store.state.currentUser.id,
              name: this.$store.state.currentUser.name.fullName,
              color: getRandomColor(),
            },
          }),
        ],
        onCreate({ editor }) {
          if (onCreate) {
            editor.on("selectionUpdate", () => {
              const { size } = editor.view.state.doc.content;

              // Ignore when the cursor moves when text is added
              if (size != ref.contentLength) {
                ref.contentLength = size;
                return;
              }

              if (ref.manualSelectionChange) {
                // ref.shouldAutoScroll = false;
              }

              // The idea here is that every selection change except for the auto highlighting of last word when autoscroll is enabled is manual
              ref.manualSelectionChange = true;
            });
          }
        },
      });

      EditorView.prototype.updateState = function updateState(state) {
        if (!this.docView) return; // This prevents the matchesNode error on hot reloads
        this.updateStateInner(state, this.state.plugins != state.plugins);
      };

      this.$store.commit("setEditor", editor);
    },

    beforeUnmount() {
      this.$store.commit("setEditor", null);
    },

    handleScroll: _.debounce(function (e) {
      if (
        // there's 1em of margin below the last line that is not scrolled into view with ProseMirror's scrollIntoView()
        e.target.clientHeight + e.target.scrollTop <
        e.target.scrollHeight // +2 is a hack to fix a bug with the largest font size options
      ) {
        this.isScrolledToBottom = false;
      } else {
        this.isScrolledToBottom = true;
      }
    }, 100),
  },
};
