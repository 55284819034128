<template>
  <span class="extra-info">
    <i class="pi pi-info-circle tip-icon" />
    <Card class="info-card" :class="alignRight ? 'align-right' : ''">
      <template #title>
        <slot name="title"></slot>
      </template>
      <template #content>
        <slot name="content"></slot>
      </template>
    </Card>
  </span>
</template>

<script>
export default {
  props: {
    alignRight: Boolean,
  },
};
</script>

<style lang="scss">
.extra-info {
  position: relative;
  line-height: 0;

  &:hover {
    .info-card {
      // display: block;
      opacity: 1;
      pointer-events: all;
    }
  }

  .tip-icon {
    font-size: 2rem;
    color: map-get($colours, grey-1);
  }

  .info-card {
    line-height: normal;
    width: 100%;
    border-radius: 1.2rem;
    position: absolute;
    z-index: 999;
    left: 0;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.5s ease-out;
    margin-top: 0.4rem;

    &.align-right {
      right: 0;
      left: initial;
    }

    .p-card-title {
      margin-bottom: 0.8rem;
      display: flex;
      font-size: 1.6rem;

      img {
        margin-right: 0.8rem;
      }

      span {
        display: flex;
        align-items: center;
      }
    }

    .p-card-content {
      @include callout;
      color: map-get($colours, grey-5);
      margin-top: 0;
      padding: 0;
      margin-left: 3.2rem;

      p {
        margin: 0.8rem 0;
      }
    }
  }
}
</style>
