<template>
  <div
    v-show="readyToShow"
    class="captioner-view panels-wrapper"
    :class="disableTransitions ? 'preload' : ''"
  >
    <div class="left-wrapper">
      <InfoPanel
        class="info-panel"
        :isOpen="infoPanelOpen"
        :toggleInfoPanelOpen="toggleInfoPanelOpen"
        :goToSessionBrowser="goToSessionBrowser"
        keywordsOption
        sttOption
        captionsLanguageOption
        captioningBrowserPage
        :changeSttType="changeSttType"
        :changeLanguage="changeLanguage"
        :parentLanguage="captionLanguage"
        :selectedSttType="sttType"
        :settingsOpen="settingsOpen"
        :settingsActiveTabIndex="settingsActiveTabIndex"
        :settingsPagesToShow="[
          'ACCOUNT',
          'SUBSCRIPTION',
          'CAPTIONS_LANGUAGE',
          'STT_MODEL',
          'SUPPORT',
        ]"
        @updateSettingsVisible="catchUpdateSettingsVisible"
        @updateSettingsActiveTabIndex="catchUpdateSettingsActiveTabIndex"
        pageTitle="Captioning"
        :data="
          isInstantSession
            ? []
            : [
                {
                  title: 'Booking',
                  info: [
                    {
                      text: this.$store.state.sessionDetails.title,
                      type: 'primary',
                    },
                  ],
                },
                {
                  title: 'Time',
                  info: [
                    {
                      text:
                        this.$store.state.sessionDetails.bookingStartTime &&
                        this.$store.state.sessionDetails.bookingEndTime
                          ? this.$store.state.sessionDetails.bookingStartTime +
                            ' - ' +
                            this.$store.state.sessionDetails.bookingEndTime +
                            (this.$store.state.sessionDetails.bookingTimezone
                              ? ' (' +
                                this.$store.state.sessionDetails
                                  .bookingTimezone +
                                ')'
                              : '')
                          : '',
                      type: 'primary',
                    },
                  ],
                  tooltip: 'Planned start and end time for this session',
                },
                {
                  title: 'Participants (including you)',
                  info: [
                    {
                      text: `${numServiceProviders}  Captioner${
                        numServiceProviders === 1 ? '' : 's'
                      } in this room`,
                      type: 'primary',
                    },
                    {
                      text: `${numStudents}  Student${
                        numStudents === 1 ? '' : 's'
                      } in this room`,
                      type: 'primary',
                    },
                    {
                      text: `${
                        numOther > 0
                          ? numOther +
                            ' Other' +
                            (numOther > 1 ? 's' : '') +
                            ' in this room'
                          : ''
                      }`,
                      type: 'primary',
                    },
                  ],
                },
              ]
        "
        tooltipInfo="<div style='font-size:1.4rem;width:min-content;white-space:nowrap;padding:1rem;'>
        <header style='font-size:1.6rem;font-weight:bold;margin-bottom:0.4rem'>Custom keybinds</header>
        <ul style='padding-left:2rem;margin-top:0.4rem;margin-bottom:0;'>
          <li><b>Alt+1</b>: [Instructor]</li>
          <li><b>Alt+2</b>: [Student]</li>
          <li><b>Alt+A</b>: [inaudible]</li>
          <li><b>Alt+C</b>: [crosstalk]</li>
          <li><b>Alt+F</b>: [foreign]</li>
          <li><b>Alt+S</b>: [silence]</li>
          <li><b>Alt+M</b>: [music]</li>
          <li><b>Alt+L</b>: [laughs]</li>
          <li><b>Alt+O</b>: [coughs]</li>
          <li><b>Alt+V</b>: [sneeze]</li>
          <li><b>Alt+R</b>: [cries]</li>
          <li><b>Alt+T</b>: [typing, keyboard strokes]</li>
          <li><b>Alt+B</b>: [noise interruption]</li>
          </ul></div>"
      />
      <div
        class="side-panel slid-out-left"
        :class="chatOpen ? 'slide-in' : 'slide-out'"
      >
        <KeepAlive>
          <ChatList
            v-show="activeSidePanel === this.PANEL_CHAT"
            :isOpen="chatOpen"
            :showChatMainMenu="showChatMainMenu"
            :setShowChatMainMenu="setShowChatMainMenu"
            :toggleChatOpen="toggleChatOpen"
            :closeChat="closeChat"
            :users="users"
            :unreadMessages="unreadMessages"
            :incrementNumUnreadMessages="incrementNumUnreadMessages"
            :clearNumUnreadMessages="clearNumUnreadMessages"
            :pusher="pusher"
            :socketId="socketId"
          />
        </KeepAlive>
      </div>
    </div>
    <Toast position="bottom-right" group="bc">
      <template #message="slotProps">
        <div class="toast-message">
          <i
            v-if="slotProps.message.severity === 'success'"
            class="pi pi-info-circle"
            style="font-size: 2rem"
          />
          <i v-else class="pi pi-times-circle" style="font-size: 2rem" />
          <p>
            <b>{{ slotProps.message.keyword }}</b>
            {{ slotProps.message.detail + "&nbsp;" }}
          </p>
          <a @click="openKeywordsDictionary">{{ slotProps.message.link }}</a>
        </div>
      </template>
    </Toast>
    <Editor
      class="content-panel"
      :toggleChatOpen="toggleChatOpen"
      :toggleSettingsOpen="toggleSettingsOpen"
      :numTotalUnreadMessageos="numTotalUnreadMessages"
      :numUnreadMessages="numUnreadMessages"
      :setNumServiceProviders="setNumServiceProviders"
      :setNumStudents="setNumStudents"
      :setNumOthers="setNumOthers"
      :setUsers="setUsers"
      :captionLanguage="captionLanguage"
      :isInstantSession="isInstantSession"
      @readyToShow="handleReadyToShow"
      :sttType="sttType"
      :changeSttType="changeSttType"
    />
  </div>
</template>

<script>
import Toast from "primevue/toast";
import Editor from "../components/Editor.vue";
import Session from "../components/Helpers/Session";
import InfoPanel from "../components/InfoPanel.vue";
import ChatList from "../components/Chat/ChatList.vue";
import { dbConstants, editorConstants } from "../assets/constants";
import { isSelectionEmpty } from "../assets/editor_helpers.js";
import Pusher from "pusher-js";

export default {
  name: "App",
  extends: Session,

  components: {
    Toast,
    Editor,
    InfoPanel,
    ChatList,
  },

  props: {
    db: Object,
  },

  computed: {
    editor() {
      return this.$store.state.editor;
    },

    chatOpen() {
      return this.activeSidePanel === this.PANEL_CHAT;
    },

    highlightedText() {
      if (this.editor) {
        const state = this.editor.state;
        const word = state.doc.textBetween(
          Math.min(state.selection.$head.pos, state.selection.$anchor.pos),
          Math.max(state.selection.$head.pos, state.selection.$anchor.pos),
          " "
        );
        return word;
      } else {
        return "";
      }
    },
    isInstantSession() {
      return this.$store.state.sessionDetails.isInstant ? true : false;
    },
  },

  data() {
    return {
      PANEL_CHAT: "CHAT",
      PANEL_SETTINGS: "SETTINGS",

      pusher: new Pusher(process.env.VUE_APP_PUSHER_KEY, {
        cluster: process.env.VUE_APP_PUSHER_CLUSTER,
      }),
      socketId: null,

      readyToShow: false,

      isSelectionEmpty,
      infoPanelOpen: false,
      shouldCloseInfoPanel: false,

      settingsOpen: false,
      settingsActiveTabIndex: 0,

      showChatMainMenu: true,
      activeSidePanel: null,
      disableTransitions: true,
      dbConstants,
      username: dbConstants.DEFAULT_USER,
      numUnreadMessages: 0,
      numServiceProviders: 0,
      numStudents: 0,
      numOther: 0,
      bookingLabelId: this.$store.state.sessionDetails.bookingLabelId,
      bookingLabelsKeywords:
        this.$store.state.sessionDetails.bookingLabelsKeywords,
      bookingLabelsKeywordsDictionary: this.$store.state.sessionDetails
        .bookingLabelsKeywordsDictionary
        ? JSON.parse(
            this.$store.state.sessionDetails.bookingLabelsKeywordsDictionary
          )
        : { phrases: [] },
      serverNotReady: true,
      users: [],
      unreadMessages: new Map(),
      numTotalUnreadMessages: 0,
      captionLanguage:
        this.$store.state.currentUser.team === "DSA Instant Service Team"
          ? { language: "English (UK)", code: "en-GB" }
          : { language: "English (US)", code: "en-US" },
      sttType:
        this.$store.state.currentUser.team === "DSA Instant Service Team"
          ? editorConstants.STT_TYPES.DEEPGRAM
          : editorConstants.STT_TYPES.DEEPGRAM_DIARIZATION,
    };
  },

  mounted() {
    this.pusher.connection.bind("connected", () => {
      this.socketId = this.pusher.connection.socket_id;
    });

    // Enable pusher logging - don't include this in production
    if (process.env.NODE_ENV === "development") {
      Pusher.logToConsole = true;
    }
  },

  methods: {
    changeSttType(type) {
      this.sttType = type;
    },

    handleReadyToShow() {
      this.readyToShow = true;
      setTimeout(() => {
        this.disableTransitions = false;
      }, 500);
    },

    toggleInfoPanelOpen() {
      this.infoPanelOpen = !this.infoPanelOpen;

      // If the user manually toggles the info panel, don't want to save this state
      this.shouldCloseInfoPanel = false;
    },

    toggleChatOpen() {
      if (!this.chatOpen) {
        // If we're opening the chat, make sure the info panel is open
        if (!this.infoPanelOpen) {
          this.infoPanelOpen = true;
          this.shouldCloseInfoPanel = true;
        }
        this.activeSidePanel = this.PANEL_CHAT;
      } else {
        this.closeChat();
      }
    },

    closeChat() {
      // This should only be true if the last action with the info panel show/hide
      // was it being closed and the chat was opened, forcing it to open as well.
      // In that case the info panel should be closed when the chat is closed
      if (this.shouldCloseInfoPanel) {
        this.infoPanelOpen = false;
      }
      this.setShowChatMainMenu(true);
      this.activeSidePanel = null;
    },

    toggleSettingsOpen() {
      if (!this.settingsOpen) {
        // If we're opening the settings, make sure the info panel is open
        if (!this.infoPanelOpen) {
          this.infoPanelOpen = true;
          this.shouldCloseInfoPanel = true;
        }
      } else {
        this.closeSettings();
      }
    },

    closeSettings() {
      // This should only be true if the last action with the info panel show/hide
      // was it being closed and the settings was opened, forcing it to open as well.
      // In that case the info panel should be closed when the settings is closed
      if (this.shouldCloseInfoPanel) {
        this.infoPanelOpen = false;
      }
      this.activeSidePanel = null;
      this.settingsShowMainMenu = true;
    },

    catchUpdateSettingsVisible(bool) {
      this.settingsOpen = bool;
    },

    catchUpdateSettingsActiveTabIndex(index) {
      this.settingsActiveTabIndex = index;
    },

    settingsSetActiveTabIndex(index) {
      this.settingsActiveTabIndex = index;
      this.activeSidePanel = this.PANEL_SETTINGS;
      this.infoPanelOpen = true;
      this.settingsShowMainMenu = false;
    },

    settingsSetShowMainMenu(boolean) {
      this.settingsShowMainMenu = boolean;
    },

    setShowChatMainMenu(bool) {
      this.showChatMainMenu = bool;
    },

    changeLanguage(language) {
      this.captionLanguage = language;
    },

    clearNumUnreadMessages(uid) {
      this.numTotalUnreadMessages =
        this.numTotalUnreadMessages - (this.unreadMessages.get(uid) || 0);
      this.unreadMessages.set(uid, 0);
    },

    incrementNumUnreadMessages(uid) {
      this.numTotalUnreadMessages = this.numTotalUnreadMessages + 1;
      if (this.unreadMessages.get(uid)) {
        this.unreadMessages.set(uid, this.unreadMessages.get(uid) + 1);
      } else {
        this.unreadMessages.set(uid, 1);
      }
    },

    goToSessionBrowser() {
      this.$router.push("/standby");
    },

    setUsers(users) {
      this.users = users;
    },
  },
};
</script>

<style lang="scss">
.loading-screen {
  display: flex;
  flex-direction: column;
  align-items: center;

  .spinner {
    stroke: map-get($component-colours, progress-spinner-1);
    height: 10rem;
    width: 10rem;
  }

  .bottom {
    margin-top: 2rem;
  }
}

.captioner-view {
  .left-wrapper {
    position: relative;

    .side-panel {
      background-color: map-get($component-colours, chat-background);
      color: map-get($component-colours, chat-font-primary);
      min-width: $side-panel-width;
      position: absolute;
      top: 0;
      left: 0;
      width: $side-panel-width;
      height: 100%;
      z-index: 2;
      border-right: 0.1rem solid map-get($colours, grey-3);

      ::selection {
        background-color: map-get(
          $component-colours,
          font-background-colour-highlighted
        );
      }

      ::-moz-selection {
        background-color: map-get(
          $component-colours,
          font-background-colour-highlighted
        );
      }
    }
  }
}

.captioner-confirm-ready {
  position: absolute;
}
</style>
