<template>
  <Dialog
    :visible="show"
    :modal="true"
    class="end-session-modal"
    closeOnEscape
    :closable="false"
    :class="step === 1 ? 'smaller' : ''"
  >
    <IconButton
      :icon="CloseGreyIcon"
      class="close-button"
      alt="Close menu"
      :onclick="
        () => {
          this.hide();
        }
      "
      xsmall
    />
    <header>
      {{ step === 1 ? "End session?" : step === 2 ? "Session ended" : "" }}
    </header>
    <p class="end-session content" v-if="step === 1">
      Clicking end will save the transcript and audio you just created. You can
      still use this session for more captioning, just click start again.
    </p>
    <div class="session-ended content" v-else-if="step === 2">
      <div class="session-name-field">
        <label for="sessionName">Session name</label>
        <InputText
          id="sessionName"
          v-model="sessionTitleInput"
          aria-describedby="session-name"
        />
        <div class="filename-disclaimer" v-if="!validFileName">
          <i class="pi pi-exclamation-circle" style="font-size: 2rem"></i>
          <span>Please use only letters, numbers, underscores, or hyphens</span>
        </div>
      </div>
      <div class="session-ended-body">
        The transcript and audio from this session have been saved in Messenger
        Pigeon. <b>Do you want to save a separate copy as well?</b>
      </div>
    </div>
    <template #footer>
      <template v-if="step === 1">
        <div class="end-session-buttons">
          <RoundedButton
            text="Cancel"
            fitHeight
            lessRound
            grey
            extrawide
            @click="hide"
          />
          <RoundedButton
            :text="
              currentUserSubscriptionDetails.isPro
                ? 'End and Save'
                : 'End and Submit'
            "
            fitHeight
            lessRound
            accent
            extrawide
            @click="endSession"
          />
        </div>
      </template>
      <template v-else-if="step === 2">
        <div class="session-ended-buttons">
          <RoundedButton
            text="Save copy of transcript"
            lessRound
            grey
            extrawide
            morePadding
            @click="saveToComputer"
          />
          <!-- <span>
            Removed for patch v1.3.1  until design specification is finalized for audio download
            <RoundedButton
              :style="'width:100%'"
              :text="saveAudioButtonText"
              @click="saveAudioLocally()"
              lessRound
              grey
              extrawide
              :loading="loading"
              :disabled="saveAudioButtonDisabled"
            />
          </span> -->
          <RoundedButton text="Exit" lessRound extrawide @click="exit" accent />
        </div>
      </template>
    </template>
  </Dialog>
</template>

<script>
import RoundedButton from "./RoundedButton";
import DownloadWhiteIcon from "../assets/images/Download-white.svg";
import PDFFileGreyIcon from "../assets/images/PDF-file-grey.svg";
import AudioFileGreyIcon from "../assets/images/Audio-file-grey.svg";
import IconButton from "../components/IconButton";
import CloseGreyIcon from "../assets/images/Close-grey.svg";
import CheckmarkCircleTurquoiseIcon from "../assets/images/Checkmark-circle-turquoise.svg";
import { getCurrentUserSubscriptionDetails } from "../assets/helpers_legacy";
import { downloadFile } from "../assets/downloadFile.js";

export default {
  components: {
    RoundedButton,
    IconButton,
  },

  props: {
    hideModal: Function,
    showDialog: Boolean,
  },

  computed: {
    fileNameWithoutFormat() {
      return this.$store.state.sessionDetails.title + " transcript.";
    },

    fileNamePDF() {
      return this.fileNameWithoutFormat + "pdf";
    },

    fileNameDOCX() {
      return this.fileNameWithoutFormat + "docx";
    },

    fileNameRTF() {
      return this.fileNameWithoutFormat + "rtf";
    },

    validFileName() {
      return this.FILENAME_REGEX.test(this.$store.state.sessionDetails.title);
    },
  },

  watch: {
    showDialog: function (newVal) {
      this.show = newVal;
    },

    show: function (newVal) {
      this.$emit("updateShow", newVal);
      if (newVal === false) {
        this.step = 1;
      }
    },
    // temp fix to save changed file name to download
    sessionTitleInput() {
      this.$store.state.sessionDetails.title = this.sessionTitleInput;
    },
  },

  data() {
    return {
      DownloadWhiteIcon,
      PDFFileGreyIcon,
      AudioFileGreyIcon,
      CheckmarkCircleTurquoiseIcon,
      CloseGreyIcon,

      ENDPOINT: "https://mp-server.habitatlearn.com",
      FILENAME_REGEX: /^[\w,\s-]+$/,
      show: false,
      step: 1,
      sessionTitleInput: this.$store.state.sessionDetails.title.replace(
        /\/|:/g,
        "-"
      ),
      shouldKeepAudioFile: true,
      shouldDownloadTranscript: false,
      shouldDownloadPDF: false,
      shouldDownloadDOCX: true,
      shouldDownloadRTF: false,
      sendLink: false,
      loading: false,
      saveAudioButtonText: "Save audio to computer",
      saveAudioButtonDisabled: true,

      currentUserSubscriptionDetails: {},
    };
  },

  async created() {
    this.currentUserSubscriptionDetails =
      await getCurrentUserSubscriptionDetails();
  },

  methods: {
    saveAudioLocally() {
      this.loading = true;
      this.$emit("saveAudioLocally");
    },
    nextStep() {
      this.step++;
      if (this.step > 2) {
        this.hide();
      }
    },

    endSession() {
      this.$emit("endSTTSession");
      this.nextStep();
    },

    hide() {
      this.show = false;
    },

    handleKeepOrDeleteAudioFile() {
      if (!this.shouldKeepAudioFile) {
        // Delete the audio file
      }

      this.sendFileLinks();
      this.nextStep();
    },

    sendFileLinks() {
      // Send the email containing links to download files
    },

    exit() {
      this.$router.push("/standby");
    },

    saveToComputer() {
      const docHTML = this.$store.state.editor.getHTML();
      let typeSuffix = "InstantSession";
      downloadFile(docHTML, this.$store.state.sessionDetails.title, typeSuffix);
    },
  },
};
</script>

<style lang="scss">
$horizontal-padding: 5.6rem;
$vertical-padding: 4rem;

.end-session-modal {
  width: 64rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: map-get($colours, white);
  border-radius: 1.4rem;
  overflow: hidden;

  .close-button {
    position: absolute;
    top: 1.6rem;
    right: 1.6rem;
  }

  .p-checkbox .p-checkbox-box.p-highlight .p-checkbox-icon.pi-check:before {
    top: 8px;
    left: 2px;
  }

  .p-checkbox .p-checkbox-box.p-highlight {
    border-color: map-get($colours, accent-purple);
    background: map-get($colours, accent-purple);

    &.p-focus {
      border-color: map-get($colours, accent-purple);
    }
  }

  .p-dialog-header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .p-dialog-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 $horizontal-padding;
    width: 100%;

    .content {
      text-align: center;
      margin: 4.8rem 0;
      width: 100%;
    }

    .end-session {
      max-width: 44rem;
    }

    .session-ended.content {
      .session-name-field {
        display: flex;
        flex-direction: column;
        width: 100%;

        label {
          @include section-sub-header;
          color: map-get($colours, grey-4);
          text-align: left;
          text-transform: uppercase;
          margin-bottom: 0.8rem;
        }
      }

      .session-ended-body {
        text-align: left;
        margin-top: 1.6rem;
      }

      .toggle-text {
        text-align: left;
        margin-top: 2.4rem;

        p {
          @include callout;
          color: map-get($colours, grey-6);
          margin: 0.8rem 0;
        }
      }

      .keep-audio-file-field {
        display: flex;
        align-items: center;
        margin-top: 3.2rem;

        label {
          @include callout;
          margin-right: 1.2rem;

          color: map-get($colours, accent-purple-2);
          .file-size {
            color: map-get($colours, grey-3);
          }
        }

        .file-kept-label {
          color: map-get($colours, grey-5);
          margin-left: 1.2rem;
        }
      }

      .checkboxes {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;
        grid-template-areas:
          "transcript audio"
          "link link";
        row-gap: 2.4rem;
        column-gap: 1.6rem;
        margin-top: 1.6rem;

        .field-checkbox {
          justify-self: start;

          label {
            @include callout;
            margin-left: 0.8rem;
            margin-top: 0.5rem;

            .file-size {
              color: map-get($colours, grey-3);
            }

            .email {
              color: map-get($colours, grey-5);
            }
          }
        }

        .transcript {
          grid-area: transcript;
        }

        .audio {
          grid-area: audio;
        }

        .link {
          grid-area: link;
        }
      }
    }

    .download-ready {
      .field-checkbox {
        display: flex;
        align-items: center;

        &:not(:first-child) {
          margin-top: 2.4rem;
        }
      }
    }

    .download-done {
      margin: 4.8rem 0;

      .download-file-label {
        &:not(:first-child) {
          margin-top: 2.4rem;
        }
      }
    }

    .download-file-label {
      margin-left: 1rem;
      display: flex;
      align-items: center;
      // grid-template-areas:
      //   "icon name"
      //   "icon size";
      grid-template-areas: "icon name";
      column-gap: 0.8rem;
      grid-template-columns: auto 1fr;

      .file-icon {
        grid-area: icon;
      }

      .file-name {
        grid-area: name;
        justify-self: start;
        @include body-1;
        color: map-get($colours, grey-6);
      }

      .file-size {
        grid-area: size;
        justify-self: start;
        @include body-2;
        color: map-get($colours, grey-4);
      }
    }
  }

  .p-dialog-footer {
    display: flex;
    flex-direction: row;
    // height: calc(4.8rem + #{$vertical-padding});
    padding: 0 $horizontal-padding $vertical-padding;

    .end-session-buttons {
      display: flex;
      flex-direction: row;
      height: 5.6rem;
      gap: 2.4rem;
    }

    .session-ended-buttons {
      display: flex;
      flex-direction: column;
      gap: 1.6rem;

      > * {
        margin: 0;
      }
    }
  }

  header {
    @include h2-accent;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      height: 8.8rem;
      width: 8.8rem;
      margin-bottom: 1.6rem;
    }
  }

  .content {
    @include section-sub-header;
  }

  .p-inputswitch .p-inputswitch-slider:before {
    background-image: url("../assets/images/Checkmark-purple.svg");
  }

  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    background-image: url("../assets/images/Checkmark-purple.svg");
  }

  .filename-disclaimer {
    display: flex;
    font-size: 1.4rem;
    color: map-get($colours, red);
    margin-top: 1.2rem;

    span {
      margin-left: 0.8rem;
    }
  }
}
</style>
