import { unescapeCharacters, convertHTMLToString } from "./helpers_legacy";
import { editorConstants } from "./constants";

/* eslint-disable */

export function getFilteredContentSection(editor) {
  // Ideally would like to only have to look at a small relevant section to help
  // computation speed but not sure how to line up positions between HTML content
  // and the actual content. Need to look at HTML content bc otherwise blank newlines
  // are ignored by the methods provided by ProseMirror/TipTap
  // let content = getRelevantContentSection(
  //   editor.getHTML(),
  //   currIndex,
  //   totalDocSize
  // );

  let content = convertHTMLToString(editor.getHTML());

  // We need to unescape in order for the positions to match up
  content = unescapeCharacters(content);
  return content;
}

export function getRelevantContentSection(
  contentAsHtml,
  currIndex,
  totalDocSize
) {
  const percentagePos = currIndex / totalDocSize;
  const approxPosInHtml = contentAsHtml.length * percentagePos;
  const start = Math.max(
    0,
    approxPosInHtml - editorConstants.SURROUNDING_RELEVANT_CHARACTER_RANGE
  );
  const end = Math.min(
    contentAsHtml.length,
    approxPosInHtml + editorConstants.SURROUNDING_RELEVANT_CHARACTER_RANGE
  );

  // PROBLEM: the slice may intersect a tag
  return contentAsHtml.slice(start, end);

  // The best we can do is: get the slice from the beginning until the currIndex, but
  // not very helpful since the editing is usually happening at the bottom
}

/**
 * Get the index of the next either whitespace or non-whitespace char
 *  from the currIndex
 */
export function getNextWhitespaceOrNotIndex(
  content,
  currIndex,
  isReverse,
  lookingForWhitespace
) {
  while (
    (lookingForWhitespace
      ? !editorConstants.WHITESPACES.includes(content.charAt(currIndex))
      : editorConstants.WHITESPACES.includes(content.charAt(currIndex))) &&
    currIndex < content.length &&
    currIndex >= 0
  ) {
    if (isReverse) {
      currIndex--;
    } else {
      currIndex++;
    }
  }
  return currIndex;
}

export function selectCurrentWord(editor, state, wordNavigation) {
  // This line should be added to all keyboard navigation altering methods to allow disabling them
  if (!wordNavigation) return false;

  let currIndex = Math.min(
    state.selection.$head.pos,
    state.selection.$anchor.pos
  );

  const content = getFilteredContentSection(editor);

  let currWordFirstIndex =
    getNextWhitespaceOrNotIndex(content, currIndex - 2, true, true) + 2;
  let currWordLastIndex =
    getNextWhitespaceOrNotIndex(content, currWordFirstIndex, false, true) + 1;

  return { first: currWordFirstIndex, last: currWordLastIndex };
}

export function isSelectionEmpty(editor) {
  const { view } = editor;
  const { selection } = view.state;
  const word = view.state.doc.textBetween(
    Math.min(selection.$head.pos, selection.$anchor.pos),
    Math.max(selection.$head.pos, selection.$anchor.pos),
    " "
  );
  return selection.empty || word.trim().length === 0;
}

/* eslint-enable */
