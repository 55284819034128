<template>
  <div class="font-format-menu">
    <img :src="FontColour" class="icon-colour row-icon" />
    <div class="colour-options">
      <button
        class="colour-option"
        :style="{ backgroundColor: editorConstants.WHITE }"
        :value="editorConstants.WHITE"
        :onClick="colourButtonClicked"
      />
      <button
        class="colour-option"
        :style="{ backgroundColor: editorConstants.YELLOW }"
        :value="editorConstants.YELLOW"
        :onClick="colourButtonClicked"
      />
      <button
        class="colour-option"
        :style="{ backgroundColor: editorConstants.LIME }"
        :value="editorConstants.LIME"
        :onClick="colourButtonClicked"
      />
      <button
        class="colour-option"
        :style="{ backgroundColor: editorConstants.BLUE }"
        :value="editorConstants.BLUE"
        :onClick="colourButtonClicked"
      />
      <button
        class="colour-option"
        :style="{ backgroundColor: editorConstants.TURQUOISE }"
        :value="editorConstants.TURQUOISE"
        :onClick="colourButtonClicked"
      />
      <button class="bold-button" :onClick="boldButtonClicked">Bold</button>
    </div>
    <img :src="FontFamily" class="icon-family row-icon" />
    <span class="label-family row-label">Font Family</span>
    <div class="family-options">
      <div class="family-option">
        <RadioButton
          id="averta"
          name="fontFamily"
          @change="changeFontFamily(this.fontFamily)"
          v-model="fontFamily"
          :value="editorConstants.AVERTA"
        />
        <label
          for="averta"
          class="shift-down"
          :style="{ fontFamily: editorConstants.AVERTA }"
          >Averta</label
        >
      </div>
      <div class="family-option">
        <RadioButton
          id="arial"
          name="fontFamily"
          @change="changeFontFamily(this.fontFamily)"
          v-model="fontFamily"
          :value="editorConstants.ARIAL"
        />
        <label for="arial" :style="{ fontFamily: editorConstants.ARIAL }"
          >Arial</label
        >
      </div>
      <div class="family-option">
        <RadioButton
          id="openDyslexic"
          name="fontFamily"
          @change="changeFontFamily(this.fontFamily)"
          v-model="fontFamily"
          :value="editorConstants.OPEN_DYSLEXIC"
        />
        <label
          for="openDyslexic"
          :style="{ fontFamily: editorConstants.OPEN_DYSLEXIC }"
          >Open Dyslexic</label
        >
      </div>
      <div class="family-option">
        <RadioButton
          id="tahoma"
          name="fontFamily"
          @change="changeFontFamily(this.fontFamily)"
          v-model="fontFamily"
          :value="editorConstants.TAHOMA"
        />
        <label for="tahoma" :style="{ fontFamily: editorConstants.TAHOMA }"
          >Tahoma</label
        >
      </div>
      <div class="family-option">
        <RadioButton
          id="comicSans"
          name="fontFamily"
          @change="changeFontFamily(this.fontFamily)"
          v-model="fontFamily"
          :value="editorConstants.COMIC_SANS"
        />
        <label
          for="comicSans"
          :style="{ fontFamily: editorConstants.COMIC_SANS }"
          >Comic Sans</label
        >
      </div>
      <div class="family-option">
        <RadioButton
          id="timesNewRoman"
          name="fontFamily"
          @change="changeFontFamily(this.fontFamily)"
          v-model="fontFamily"
          :value="editorConstants.TIMES_NEW_ROMAN"
        />
        <label
          for="timesNewRoman"
          class="shift-down"
          :style="{ fontFamily: editorConstants.TIMES_NEW_ROMAN }"
          >Times New Roman</label
        >
      </div>
    </div>
    <img :src="FontSize" class="icon-size row-icon" />
    <div class="size plus-minus-row">
      <div class="label-value-wrapper">
        <span class="row-label setting-label">Font Size</span>
        <span class="setting-value">{{ parentFontSize }}</span>
      </div>
      <PlusMinusButton
        @decrement="changeFontSize(Math.max(0, parentFontSize - 1))"
        @increment="changeFontSize(parentFontSize + 1)"
      />
    </div>
    <img :src="FontHeight" class="icon-height row-icon" />
    <div class="height plus-minus-row">
      <div class="label-value-wrapper">
        <span class="row-label setting-label">Line Height</span>
        <span class="setting-value">{{ parentLineHeight }}</span>
      </div>
      <PlusMinusButton
        @decrement="changeLineHeight(Math.max(0, parentLineHeight - 0.1))"
        @increment="changeLineHeight(parentLineHeight + 0.1)"
      />
    </div>
    <img :src="FontSpacing" class="icon-spacing row-icon" />
    <div class="spacing plus-minus-row">
      <div class="label-value-wrapper">
        <span class="row-label setting-label">Letter Spacing</span>
        <span class="setting-value">{{ parentLetterSpacing }}</span>
      </div>
      <PlusMinusButton
        @decrement="changeLetterSpacing(parentLetterSpacing - 1)"
        @increment="changeLetterSpacing(parentLetterSpacing + 1)"
      />
    </div>
    <p v-if="showDisclaimer" class="disclaimer">
      *This does not affect the font size in the exported transcript.
    </p>
  </div>
</template>

<script>
import { editorConstants } from "../assets/constants";
import PlusMinusButton from "../components/PlusMinusButton";
import FontSize from "../assets/images/FontAppearance/Font-size.svg";
import FontColour from "../assets/images/FontAppearance/Font-colour.svg";
import FontFamily from "../assets/images/FontAppearance/Font-family.svg";
import FontHeight from "../assets/images/FontAppearance/Font-height.svg";
import FontSpacing from "../assets/images/FontAppearance/Font-spacing.svg";

export default {
  props: {
    changeFontColour: Function,
    toggleFontBold: Function,
    changeFontFamily: Function,
    changeFontSize: Function,
    parentFontSize: Number,
    parentFontFamily: String,
    showDisclaimer: Boolean,
    changeLineHeight: Function,
    changeLetterSpacing: Function,
    parentLineHeight: Number,
    parentLetterSpacing: Number,
  },

  components: {
    PlusMinusButton,
  },

  watch: {
    parentFontFamily: function (newVal) {
      this.fontFamily = newVal;
    },
  },

  data() {
    return {
      fontFamily: this.parentFontFamily,
      editorConstants: editorConstants,
      FontSize,
      FontColour,
      FontFamily,
      FontHeight,
      FontSpacing,
    };
  },

  methods: {

    colourButtonClicked(event) {
      this.changeFontColour(event.target.value);
    },

    boldButtonClicked() {
      this.toggleFontBold();
    },
  },
};
</script>

<style lang="scss">
.font-format-menu {
  display: grid;
  align-items: center;
  grid-template-columns: auto auto;
  grid-template-rows: 3.2rem 3.2rem auto 3.2rem 3.2rem 3.2rem;
  grid-template-areas:
    "icon-colour colours"
    "icon-family label-family"
    ". family-options"
    "icon-size size"
    "icon-height height"
    "icon-spacing spacing"
    "disclaimer disclaimer";
  padding: 0.8rem;
  background-color: map-get($colours, accent-purple-4);
  color: map-get($colours, white);

  .icon-colour {
    grid-area: icon-colour;
  }

  .colours {
    grid-area: colours;
  }

  .icon-family {
    grid-area: icon-family;
  }

  .label-family {
    grid-area: label-family;
  }

  .family-options {
    grid-area: family-options;
  }

  .icon-size {
    grid-area: icon-size;
  }

  .size {
    grid-area: size;
  }

  .icon-height {
    grid-area: icon-height;
  }

  .height {
    grid-area: height;
  }

  .icon-spacing {
    grid-area: icon-spacing;
  }

  .spacing {
    grid-area: spacing;
  }

  .row-icon {
    margin-right: 0.8rem;
  }

  .row-label {
    @include body-2;
  }

  .family-option {
    height: 3.2rem;
    display: flex;
    align-items: center;

    label {
      margin-left: 0.8rem;
      font-size: 1.2rem;
    }
  }

  .plus-minus-row {
    display: flex;
    justify-content: space-between;
  }

  .label-value-wrapper {
    display: flex;

    .setting-label {
      display: flex;
      align-items: center;
    }

    .setting-value {
      margin: 0.4rem 3rem 0 0.4rem;
      display: flex;
      align-items: center;
      @include caption;
      color: map-get($colours, grey-3);
    }
  }

  .colour-option {
    height: 1.6rem;
    width: 1.6rem;
    border-radius: 50%;
    margin-right: 0.6rem;
  }

  .bold-button {
    font-weight: bold;
    border: none;
    background: none;
    font-size: 1.6rem;
    padding: 0;
    margin-left: 1rem !important;
    line-height: 0.85;
    color: map-get($colours, white);
  }

  .p-radiobutton {
    width: 1.6rem;
    height: 1.6rem;
  }

  .p-radiobutton .p-radiobutton-box {
    width: 1.6rem;
    height: 1.6rem;
  }

  .radio-row {
    display: flex;
    align-items: center;

    label {
      font-size: 1.6rem;
      margin-left: 0.8rem;

      &.shift-down {
        margin-top: 0.3rem;
      }
    }
  }

  .disclaimer {
    @include caption;
    color: map-get($colours, grey-4);
    grid-area: disclaimer;
    white-space: normal;
    padding: 0.6rem 0.8rem;
    margin: 0;
  }
}

.p-overlaypanel.p-overlaypanel-flipped {
  &:after,
  &:before {
    display: none;
  }
}
</style>
