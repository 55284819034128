<template>
  <DisablePersonalNote
    :setSubscribeVisible="setSubscribeVisible"
    :setVisible="setSubscriptionAlert"
    :condensed="condensedDisabledDialog"
    v-if="isPersonalNote && subscriptionAlert && showDisablePersonalNoteDialog"
    class="disable-personal-note-dialog"
  />
  <div class="alert-dialog-wrapper" v-if="alertVisible">
    <div class="alert-dialog">
      <div class="alert-dialog-body">
        <img :src="LogoImage" />
        <div class="alert-dialog-body-text">
          <div class="alert-dialog-body-text-title">
            We hope you enjoy expert notes proudly prepared by our professional
            note-taker, just for you.
          </div>
          <div class="alert-dialog-body-text-text">
            Note: While we are delighted to provide notes, these notes remain
            the property of their respective authors and are offered here for
            your professional use and reference.
          </div>
        </div>
      </div>
      <button class="alert-dialog-button" @click="closeProNoteAlert">
        Close
      </button>
    </div>
  </div>
  <div
    class="text-editor-wrapper note-editor"
    :class="{ 'disable-and-blur': disablePersonalNote }"
    v-bind="$attrs"
  >
    <editor-content
      :editor="noteEditor"
      :class="{
        'zoom-50': zoomFactor === 0.5,
        'zoom-75': zoomFactor === 0.75,
        'zoom-100': zoomFactor === 1,
        'zoom-125': zoomFactor === 1.25,
        'zoom-150': zoomFactor === 1.5,
        'zoom-175': zoomFactor === 1.75,
        'zoom-200': zoomFactor === 2,
      }"
      class="text-editor"
      :style="{
        fontSize: NOTES_PANEL_DEFAULTS.FONT_SIZE,
      }"
    />
    <div class="gradient" :class="isScrolledToBottom ? 'hide' : ''" />
  </div>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-3";
import { Extension } from "@tiptap/core";
import StarterKit from "@tiptap/starter-kit";
import Collaboration from "@tiptap/extension-collaboration";
// import CollaborationCursor from "@tiptap/extension-collaboration-cursor";
import { HocuspocusProvider } from "@hocuspocus/provider";
import * as Y from "yjs";
import Typography from "@tiptap/extension-typography";
import Underline from "@tiptap/extension-underline";
import Highlight from "@tiptap/extension-highlight";
import Subscript from "@tiptap/extension-subscript";
import Superscript from "@tiptap/extension-superscript";
import TextAlign from "@tiptap/extension-text-align";
import { Color } from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";
import FontFamily from "@tiptap/extension-font-family";
import FontSize from "tiptap-extension-font-size";
import Link from "@tiptap/extension-link";
import Image from "@tiptap/extension-image";
import { dbConstants, NOTES_PANEL_DEFAULTS } from "../../assets/constants";
import Emoji from "@tiptap-pro/extension-emoji";
import LogoImage from "../../assets/images/LogoPurple.svg";
import Placeholder from "@tiptap/extension-placeholder";
import CloseGreyIcon from "../../assets/images/Close-grey.svg";
import DisablePersonalNote from "./DisablePersonalNote.vue";
// import IconButton from "../IconButton.vue";

export default {
  inheritAttrs: false,

  components: {
    EditorContent,
    DisablePersonalNote,
    // IconButton,
  },

  props: {
    id: String,
    setNumServiceProviders: Function,
    setNumStudents: Function,
    setNumOthers: Function,
    username: String,
    noteEditor: Object,
    setNoteEditor: Function,
    audioComponent: Object,
    insertCurrentTimestamp: Function,
    editable: Boolean,
    zoomFactor: Number,
    showProNoteAlert: {
      type: Boolean,
      default: false,
    },
    isPersonalNote: {
      type: Boolean,
      default: false,
    },
    disablePersonalNote: {
      type: Boolean,
      default: false,
    },
    setSubscribeVisible: {
      type: Function,
      default: () => {},
    },
    condensedDisabledDialog: {
      type: Boolean,
      default: false,
    },
    showDisablePersonalNoteDialog: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      dbConstants,
      isScrolledToBottom: true,
      fontSize: 18,
      NOTES_PANEL_DEFAULTS,
      emojisOutput: "",
      CloseGreyIcon,
      subscriptionAlert: this.disablePersonalNote,
      LogoImage,
      alertVisible: this.showProNoteAlert,
    };
  },

  watch: {
    disablePersonalNote(newVal) {
      this.subscriptionAlert = newVal;
    },
  },

  mounted() {
    const ydoc = new Y.Doc();
    this.provider = new HocuspocusProvider({
      url: "wss://mp-collab-server.habitatlearn.com/collaboration",
      document: ydoc,
      name: this.id,
      token: "secret-token",
    });
    this.provider.on("status", (event) => {
      this.status = event.status;
    });

    window.ydoc = ydoc;
    let ref = this;
    const CustomExtension = Extension.create({
      addKeyboardShortcuts() {
        return {
          // "Alt-Shift-t": ref.insertCurrentTimestamp,
          "Cmd-Shift-t": ref.insertCurrentTimestamp,
        };
      },
    });

    const extensions = [
      CustomExtension,
      Typography,
      Underline,
      Highlight,
      Subscript,
      Superscript,
      Color,
      TextStyle,
      FontFamily,
      FontSize,
      Link,
      Image.configure({
        allowBase64: true,
      }),
      TextAlign.configure({
        types: ["heading", "paragraph"],
      }),
      Emoji,
      Placeholder.configure({
        placeholder: "Begin crafting your personal notes here.",
      }),
    ];

    if (this.editable) {
      this.setNoteEditor(
        new Editor({
          content: "",
          extensions: [
            StarterKit.configure({
              history: false,
            }),
            ...extensions,
            Collaboration.configure({
              document: ydoc,
            }),
          ],
        })
      );
    } else {
      this.setNoteEditor(
        new Editor({
          content: "",
          extensions: [StarterKit, ...extensions],
          editable: false,
        })
      );
    }
  },

  methods: {
    closeProNoteAlert() {
      this.$emit("closeProNoteAlert");
      this.alertVisible = false;
    },

    setSubscriptionAlert(bool) {
      this.subscriptionAlert = bool;
    },

    closeSubscriptionAlert() {
      this.subscriptionAlert = false;
    },
  },

  beforeUnmount() {
    this.noteEditor.destroy();
  },
};
</script>

<style lang="scss">
.text-editor-wrapper {
  display: flex;
  flex-direction: column;

  .text-editor {
    padding: 0 3.2rem;
    height: 100%;
    overflow: auto;
    margin: 1.2rem;
    margin-left: 0;
    word-break: break-word;

    &.zoom-50 {
      .ProseMirror {
        transform: scale(0.5);
      }
    }
    &.zoom-75 {
      .ProseMirror {
        transform: scale(0.75);
      }
    }
    &.zoom-100 {
      .ProseMirror {
        transform: scale(1);
      }
    }
    &.zoom-125 {
      .ProseMirror {
        transform: scale(1.25);
      }
    }
    &.zoom-150 {
      .ProseMirror {
        transform: scale(1.5);
      }
    }
    &.zoom-175 {
      .ProseMirror {
        transform: scale(1.75);
      }
    }
    &.zoom-200 {
      .ProseMirror {
        transform: scale(2);
      }
    }
  }

  .ProseMirror {
    height: calc(100% - 3.6rem);
    outline: none;
    font-family: $font-default;
    // font-size: 2rem;
    transform-origin: top left;

    > * + * {
      margin-top: 0.75em;
    }

    a {
      color: map-get($colours, medium-blue);
      cursor: pointer;
      text-decoration: underline;
    }

    img {
      max-width: 100%;
      height: auto;

      &.ProseMirror-selectednode {
        outline: 0.2rem dashed #fff;
      }
    }

    mark {
      background: #877594;
      color: #fff;
      padding: 0.4rem 0.8rem;
      border-radius: 1.4rem;
      font-size: 1.4rem;
    }
  }

  .gradient {
    bottom: 9.2rem;
  }

  .text-formatting-bar-wrapper {
    padding: 0.8rem 1.6rem 0;
    z-index: 1;
  }
}

.note-editor {
  p.is-editor-empty:first-child::before {
    color: map-get($colours, translucent-white-60);
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }
}

.alert-dialog-wrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-items: center;
  align-items: center;
  top: 4.8rem;

  .alert-dialog {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 1.4rem;

    width: 96%;
    max-width: 96%;
    z-index: 20;
    background: #fff7ed;
    color: black;
    padding: 0.8rem;
    .alert-dialog-body {
      display: flex;
      flex-direction: row;
      img {
        width: 1.6rem;
        height: 1.6rem;
        margin-right: 0.8rem;
      }

      .alert-dialog-body-text {
        display: flex;
        flex-direction: column;
        color: map-get($colours, accent-purple-4);
        .alert-dialog-body-text-title {
          font-weight: bold;
          margin-bottom: 0.8rem;
          font-size: 1.3rem;
        }
        .alert-dialog-body-text-text {
          font-size: 1.2rem;
          font-family: $font-secondary;
        }
      }
    }
    .alert-dialog-button {
      padding: 0.8rem 0;
      width: 90%;
      display: flex;
      justify-content: center;
      color: #fff;
      font-family: $font-secondary;
      font-size: 1.2rem;
      margin: 1.2rem 4.2rem;
      background: map-get($colours, accent-purple);
      border-radius: 1.4rem;
    }
  }
  width: 100%;
  max-width: 100%;
  z-index: 99;
}

.modal-z-index {
  z-index: 99;
}
</style>
