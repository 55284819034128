<template>
  <div class="panels-wrapper">
    <InfoPanel
      :isOpen="infoPanelOpen"
      :toggleInfoPanelOpen="toggleInfoPanelOpen"
      sessionBrowserPage
      :openNewSessionModal="setNewSessionModalVisible"
    />
    <div class="standby-content content-panel">
      <div v-if="!orderSubmitted" class="request-notes">
        <div class="save-header">Recording saved!</div>
        <img :src="OnboardingRecordNotesImage" class="record-notes-image" />
        <InputText type="text" v-model="title" class="form-input" readonly />
        <p class="question">Do you want to request notes for this recording?</p>
        <RoundedButton
          class="request-notes-button"
          mediumwide
          largerFont
          text="Request notes"
          @click="orderFormVisible = true"
        />
        <UnderlinedButton
          text="Maybe later"
          class="maybe-later"
          mediumLight
          noUnderline
          @click="goHome"
        />
      </div>
      <div v-else class="order-confirmation">
        <img :src="Checkmark" class="checkmark" />
        <div class="thank-you">Thank you for your order</div>
        <p class="confirmation-blurb">
          Your order is confirmed. <br />You’ll be notified when your notes are
          done.
        </p>
        <RoundedButton
          class="back-home-button"
          mediumwide
          largerFont
          text="Back to home"
          @click="goHome"
        />
      </div>
    </div>
    <RequestNotesOrderForm
      :visible="orderFormVisible"
      :recordingTitle="title"
      :durationHMS="durationHMS"
      :durationMilliseconds="durationMilliseconds"
      :recordingUploadId="recordingUploadId"
      :setOrderFormVisible="setOrderFormVisible"
      @order-successful="orderSuccessful()"
    ></RequestNotesOrderForm>
  </div>
  <NewSessionModal
    :visible="newSessionModalVisible"
    :setVisible="setNewSessionModalVisible"
  />
</template>
<script>
import OnboardingRecordNotesImage from "../assets/images/Onboarding-record-notes.png";
import InfoPanel from "../components/InfoPanel";
import RequestNotesOrderForm from "../components/Orders/RequestNotesOrderForm.vue";
import RoundedButton from "../components/RoundedButton";
import UnderlinedButton from "../components/UnderlinedButton";
import Checkmark from "../assets/images/Checkmark-Order-Confirmed.svg";
import NewSessionModal from "../components/NewSessionModal.vue";

export default {
  components: {
    InfoPanel,
    RoundedButton,
    UnderlinedButton,
    RequestNotesOrderForm,
    NewSessionModal,
  },
  data() {
    return {
      OnboardingRecordNotesImage,
      Checkmark,
      infoPanelOpen: false,
      orderFormVisible: false,
      title: "",
      durationHMS: "",
      durationMilliseconds: "",
      recordingUploadId: "",
      orderSubmitted: false,
      newSessionModalVisible: false,
    };
  },
  mounted() {
    this.title = localStorage.getItem("latestRecordingTitle");
    this.durationHMS = localStorage.getItem("latestRecordingDurationHMS");
    this.durationMilliseconds = localStorage.getItem(
      "latestRecordingDurationMS"
    );
    this.recordingUploadId = localStorage.getItem("latestRecordingUploadId");
  },
  methods: {
    toggleInfoPanelOpen() {
      this.infoPanelOpen = !this.infoPanelOpen;
    },
    setOrderFormVisible(val) {
      this.orderFormVisible = val;
    },
    goHome() {
      this.$router.push("Standby");
    },
    orderSuccessful() {
      this.orderFormVisible = false;
      this.orderSubmitted = true;
    },
    setNewSessionModalVisible(visible) {
      this.newSessionModalVisible = visible;
    },
  },
};
</script>
<style lang="scss">
$row-width: 45rem;
.standby-content {
  width: 100%;
  height: 100%;
  padding: 5rem 5.6rem;
  overflow: auto;
  font-family: $font-secondary;

  .request-notes {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    .save-header {
      margin: 0 auto;
      margin-top: 8rem;
      font-family: PPWoodland;
      font-size: 3.4rem;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.18;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
    }
    .record-notes-image {
      width: 30rem;
    }

    .question {
      font-family: Averta;
      font-size: 2rem;
    }

    .form-input {
      min-width: $row-width;
      height: 5.6rem;
      text-align: left;
      background-color: #6d5b79;
      border: solid 1px #877594;
      color: #bababa;
    }
    .request-notes-button {
      color: #fff;
      width: 160px;
      height: 56px;
      margin: 34px 200px 18px 216px;
      padding: 16px 24px;
      border-radius: 32px;
      box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2),
        0 3px 14px 0 rgba(0, 0, 0, 0.12), 0 8px 10px 0 rgba(0, 0, 0, 0.14);
      background-color: #877594;
    }
    .maybe-later {
      // margin: 0 auto;
      margin-left: 2rem;
      font-family: Manrope;
      font-size: 1.4rem;
      color: #e8e8e8;
    }
  }
  .order-confirmation {
    text-align: center;
    .checkmark {
      margin-top: 12.6rem;
    }
    .thank-you {
      font-family: Averta;
      font-size: 2.4rem;
      font-weight: bold;
      font-stretch: normal;
      margin-top: 4rem;
    }
    .confirmation-blurb {
      font-size: 1.6rem;
      margin-bottom: 7.2rem;
    }
    .back-home-button {
      margin: 0 auto;
      color: #fff;
      width: 160px;
      height: 56px;
      padding: 16px 24px;
      border-radius: 32px;
      box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2),
        0 3px 14px 0 rgba(0, 0, 0, 0.12), 0 8px 10px 0 rgba(0, 0, 0, 0.14);
      background-color: #877594;
    }
  }
}
</style>