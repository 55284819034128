<template>
  <div
    class="disable-personal-note-dialog"
    :class="{ condensed: props.condensed }"
  >
    <span class="disable-personal-note-dialog-body-text">
      The personal notes feature is only available to subscription accounts.
      Subscribe to unlock full access!
    </span>
    <div class="buttons-wrapper">
      <button
        @click="props.setSubscribeVisible(true)"
        class="diable-personal-note-dialog-body-button"
      >
        Subscribe
      </button>
      <IconButton
        :icon="CloseGreyIcon"
        class="disable-personal-note-dialog-close-icon"
        alt="Close"
        :onclick="
          () => {
            props.setVisible(false);
          }
        "
        xxsmall
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import IconButton from "../IconButton.vue";
const CloseGreyIcon = require("../../assets/images/Close-grey.svg") as string;

interface Props {
  setSubscribeVisible: Function;
  setVisible: Function;
  condensed?: boolean;
}

const props = defineProps<Props>();
</script>

<style lang="scss">
.disable-personal-note-dialog {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 1rem;
  background: map-get($colours, grey-7);
  color: white;
  position: absolute;
  z-index: 999;
  top: 4rem;
  left: 1.6rem;
  // max-width: 70rem;
  // width: 100%;
  padding: 1.6rem 0.8rem 1.6rem 2rem;
  width: calc(100% - 3.2rem); // For margins on left and right
  font-size: 1.2rem;
  font-family: $font-secondary;

  &.condensed {
    padding: 1.2rem 0.8rem 1.2rem 1.6rem;
    width: calc(100% - 1.6rem); // For margins on left and right
    left: 0.8rem;

    .buttons-wrapper {
      .disable-personal-note-dialog-close-icon {
        padding: 0.2rem;
      }
    }

    .diable-personal-note-dialog-body-button {
      margin: 0 0.6rem;
    }
  }

  .buttons-wrapper {
    display: flex;
    align-items: center;

    .disable-personal-note-dialog-close-icon {
      padding: 0.4rem;

      img {
        width: 1.6rem;
        height: 1.6rem;
      }
    }

    .diable-personal-note-dialog-body-button {
      padding: 0.8rem 1.2rem;
      border-radius: 2.4rem;
      background-image: linear-gradient(to right, #8f4d77 0%, #877594 100%);
      font-size: 1.2rem;
      color: #fff;
      margin: 0 1rem;
      align-self: center;
    }
  }
}
// }
</style>
