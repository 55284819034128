<template>
  <div class="subscription">
    <div class="loading-screen" v-if="loading">
      <span>Loading...</span>
      <ProgressSpinner class="spinner bottom" />
    </div>
    <SettingsBlock title="Your subscription" v-else>
      <div v-if="currentUserSubscriptionDetails.isActive" class="content">
        <p>
          {{
            currentUserSubscriptionDetails.isTrial
              ? "You are currently on our free trial."
              : currentUserSubscriptionDetails.isBasic
              ? "You are currently on our basic plan."
              : currentUserSubscriptionDetails.isPro
              ? "You are currently on our pro plan."
              : "Your subscription is active."
          }}
        </p>
        <p
          v-if="currentUserSubscriptionDetails.isCancelling"
          class="subscription-cancelling"
        >
          Your subscription is set to cancel at the end of the billing period.
        </p>
        <div
          v-if="
            currentUserSubscriptionDetails.isStripe ||
            currentUserSubscriptionDetails.isAppStore
          "
        >
          <RoundedButton
            text="Modify Subscription"
            mediumwide
            lessRound
            bold
            accent
            @click="modifySubscription"
            :loading="modifySubscriptionLoading"
            class="modify-subscription-button"
          />
          <p class="relog-message">
            Note: changes to your subscription will appear here after closing
            and reopening this dialog.
          </p>
          <div
            v-if="modifySubscriptionError"
            class="modify-subscription-error error"
          >
            There was an error opening the Stripe Customer Portal.
          </div>
        </div>
      </div>
      <div v-else class="content">
        <p>You are not currently on any subscription.</p>
        <RoundedButton
          text="See subscription plans"
          mediumwide
          lessRound
          bold
          accent
          @click="
            () => {
              this.showSubscriptionSignupDialog = true;
            }
          "
          class="see-subscription-plans-button"
        />
      </div>
      <SubscriptionSignup
        :visible="showSubscriptionSignupDialog"
        :setVisible="setShowSubscriptionSignupDialog"
        :refreshCurrentUserSubscriptionDetails="
          refreshCurrentUserSubscriptionDetails
        "
      />
    </SettingsBlock>
  </div>
</template>

<script>
import SubscriptionSignup from "../Onboarding/SubscriptionSignup.vue";
import RoundedButton from "../RoundedButton.vue";
import SettingsBlock from "./SettingsBlock.vue";
import { APP_STORE_URL } from "../../assets/constants";
import { getCurrentUserSubscriptionDetails } from "../../assets/helpers_legacy";

export default {
  components: {
    SubscriptionSignup,
    SettingsBlock,
    RoundedButton,
  },

  data() {
    return {
      loading: true,
      showSubscriptionSignupDialog: false,
      currentUserSubscriptionDetails: {},
      modifySubscriptionError: false,
      modifySubscriptionLoading: false,
    };
  },

  async created() {
    await this.refreshCurrentUserSubscriptionDetails();
  },

  methods: {
    async refreshCurrentUserSubscriptionDetails() {
      this.loading = true;
      this.currentUserSubscriptionDetails =
        await getCurrentUserSubscriptionDetails();
      this.loading = false;
    },

    modifySubscription() {
      if (this.currentUserSubscriptionDetails.isStripe) {
        this.modifySubscriptionError = false;
        this.modifySubscriptionLoading = true;

        var sessionsHeaders = new Headers();
        sessionsHeaders.append("content-type", "application/json");
        sessionsHeaders.append(
          "Authorization",
          `Bearer ${JSON.parse(localStorage.getItem("tokenMP")).accessToken}`
        );
        var sessionRequestOptions = {
          method: "post",
          headers: sessionsHeaders,
          redirect: "follow",
        };
        fetch(
          process.env.VUE_APP_MP_SERVER_API_URL +
            "self/create-customer-portal-session",
          sessionRequestOptions
        )
          .then((response) => response.text())
          .then((result) => {
            let data = JSON.parse(result);
            this.modifySubscriptionLoading = false;

            if (data.status === "error") {
              this.modifySubscriptionError = true;
            } else {
              window.open(data.data.url, "_blank");
            }
          })
          .catch((e) => {
            console.log("Error modifying subscription: ", e);
            this.modifySubscriptionError = true;
            this.modifySubscriptionLoading = false;
          });
      } else {
        // App Store subscription
        window.open(APP_STORE_URL, "_blank");
      }
    },

    setShowSubscriptionSignupDialog(bool) {
      this.showSubscriptionSignupDialog = bool;
    },
  },
};
</script>

<style lang="scss"></style>
