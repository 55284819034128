<template>
  <div class="support">
    <SettingsBlock title="Contact us">
      <div class="contact-block">
        <div class="label">Email:</div>
        <div class="info">
          <a :href="'mailto:' + SUPPORT_INFO.EMAIL">{{ SUPPORT_INFO.EMAIL }}</a>
        </div>
      </div>
      <div class="contact-block">
        <div class="label">Live Chat:</div>
        <div class="info">
          <div class="info-row">Monday to Friday, 8 AM to 10 PM EST</div>
          <div class="info-row">
            Click
            <a
              href=""
              @click.prevent="openExternalUrl(SUPPORT_INFO.CONTACT_URL)"
              >here</a
            >
            to chat with support
          </div>
        </div>
      </div>
      <div class="contact-block">
        <div class="label">Phone:</div>
        <div class="info">
          <div class="info-row">Monday to Friday, 9 AM to 5 PM EST</div>
          <div class="info-row">
            <a :href="'tel:' + SUPPORT_INFO.PHONE_NUM" class="phone-num">{{
              SUPPORT_INFO.PHONE_NUM
            }}</a>
          </div>
        </div>
      </div>
      <div class="contact-block address">
        <div class="info">
          <div class="info-row">
            Habitat Learn Inc, 180 John Street, Toronto, ON, M5T 1X5 Canada
          </div>
        </div>
      </div>
      <div class="contact-block socials">
        <div class="info">
          <div class="info-row">
            <IconButton
              :icon="LinkedInIcon"
              alt="LinkedIn"
              class="social-button"
              @click.prevent="openExternalUrl(SOCIALS_URLS.LINKEDIN)"
              small
              rounded
            />
            <IconButton
              :icon="TwitterIcon"
              class="social-button"
              alt="Twitter"
              @click.prevent="openExternalUrl(SOCIALS_URLS.TWITTER)"
              small
              rounded
            />
            <IconButton
              :icon="InstagramIcon"
              class="social-button"
              alt="Instagram"
              @click.prevent="openExternalUrl(SOCIALS_URLS.INSTAGRAM)"
              small
              rounded
            />
            <IconButton
              :icon="YoutubeIcon"
              class="social-button"
              alt="Youtube"
              @click.prevent="openExternalUrl(SOCIALS_URLS.YOUTUBE)"
              small
              rounded
            />
          </div>
        </div>
      </div>
    </SettingsBlock>
  </div>
</template>

<script>
import SettingsBlock from "./SettingsBlock.vue";
import IconButton from "../../components/IconButton.vue";
import { SUPPORT_INFO, SOCIALS_URLS } from "../../assets/constants";
import InstagramIcon from "../../assets/images/Socials/instagram.svg";
import LinkedInIcon from "../../assets/images/Socials/linkedin.svg";
import TwitterIcon from "../../assets/images/Socials/twitter.svg";
import YoutubeIcon from "../../assets/images/Socials/youtube.svg";

export default {
  components: {
    SettingsBlock,
    IconButton,
  },

  data() {
    return {
      SUPPORT_INFO,
      SOCIALS_URLS,
      InstagramIcon,
      LinkedInIcon,
      TwitterIcon,
      YoutubeIcon,
    };
  },

  methods: {
    openExternalUrl(url) {
      window.open(url, "_blank");
      return false;
    },
  },
};
</script>

<style lang="scss">
.support {
  .contact-block {
    font-size: 1.4rem;
    letter-spacing: 0.03rem;
    font-family: $font-secondary;

    &:first-child {
      margin-top: 1.6rem;
    }

    &:not(:first-child) {
      margin-top: 2.4rem;
    }

    a {
      color: map-get($colours, grey-2);

      &:hover {
        filter: brightness(80%);
      }
    }

    .label {
      color: map-get($colours, grey-3);
      margin-bottom: 0.4rem;
    }

    .info {
      color: map-get($colours, grey-2);

      .info-row {
        margin-top: 0.4rem;
      }

      .phone-num {
        text-decoration: none;
      }
    }

    &.address {
      margin-top: 4rem;

      .info {
        color: map-get($colours, grey-3);
      }
    }

    &.socials {
      margin-top: 4rem;

      .info-row {
        display: flex;
        flex-direction: row;
        gap: 2.4rem;
        align-items: center;

        .social-button {
          background: transparent;

          &:hover {
            // filter: brightness(20%);
            // background: transparent;
          }
        }
      }
    }
  }
}
</style>
