<template>
  <div class="message-screen">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.message-screen {
  width: 100%;
  height: 100%;
  background: map-get($component-colours, background-primary);
  position: absolute;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  font-size: 2.4rem;
}
</style>
