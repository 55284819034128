<template>
  <div class="magic-link-landing">
    <header class="app-branding">
      <img :src="LogoFlat" class="logo" />
      <h2 class="mp-title">Messenger Pigeon</h2>
      <p>By Habitat Learn</p>
    </header>
    <RoundedButton
      class="magic-link-button"
      text="OPEN DESKTOP APPLICATION"
      uppercase
      taller
      accent
      lessRound
      monospace
      semiBold
      type="submit"
      @click="openDesktopApp()"
    ></RoundedButton>
  </div>
</template>
<script setup lang="ts">
import { useRoute } from "vue-router";

import RoundedButton from "../components/RoundedButton.vue";
const LogoFlat = require("../assets/logo/icon-flat.svg") as string;

const route = useRoute()

function openDesktopApp() {
  window.location.href =
    "messenger-pigeon://auth/magic-link/" + route.params.uuid;
}
</script>
<style lang="scss">
.magic-link-landing {
  display: flex;
  justify-content: center;
  flex-direction: column;

  .app-branding {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: 9.6rem;
    margin-bottom: 9.4rem;

    .mp-title {
      margin: 0;
      font-size: 2rem;
      font-weight: 500;
    }
    p {
      font-size: 1rem;
      font-family: $font-secondary;
      margin: 0.4rem 0 0 0;
      letter-spacing: 0.015rem;
    }

    .logo {
      margin-bottom: 1.6rem;
      height: 3.91rem;
    }
  }
  .magic-link-button {
    font-family: $font-secondary;
    padding: 0 4rem;
  }
}
</style>
