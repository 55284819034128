<template>
  <div class="loading-screen" v-if="loading && visible">
    <span>Loading...</span>
    <ProgressSpinner class="spinner bottom" />
  </div>
  <Dialog
    :visible="visible"
    modal
    class="subscription-signup-dialog"
    :header="false"
    :closable="false"
    v-else
  >
    <IconButton
      :icon="CloseGreyIcon"
      class="close-button"
      alt="Close menu"
      :onclick="
        () => {
          this.setVisible(false);
        }
      "
      xsmall
    />
    <div class="subscriptions-body" v-if="step === 0">
      <header><h2>Subscription options</h2></header>
      <div
        v-if="currentUserSubscriptionDetails.isFreeTrialEligible"
        class="free-trial-disclaimer"
      >
        Get started with our subscriptions today and enjoy a 30-day free trial.
        After the trial, you’ll be billed for the chosen plan, ensuring
        continued access to our pro services.
      </div>
      <div
        class="subscription-option"
        :class="
          subscriptionModel === SUBSCRIPTION_MODELS.MONTHLY.type && 'selected'
        "
        @click="subscriptionModel = SUBSCRIPTION_MODELS.MONTHLY.type"
      >
        <div class="title">
          Monthly
          <RadioButton
            v-model="subscriptionModel"
            :inputId="SUBSCRIPTION_MODELS.MONTHLY.type"
            :name="SUBSCRIPTION_MODELS.MONTHLY.type"
            :value="SUBSCRIPTION_MODELS.MONTHLY.type"
          />
        </div>
        <div class="price">
          {{
            formattedSubscriptionPrice(monthlySubscriptionPrice) + " / month"
          }}
        </div>
        <ul class="features">
          <li v-for="perk in SUBSCRIPTION_MODELS.MONTHLY.perks" :key="perk">
            {{ perk }}
          </li>
          <li v-if="currentUserSubscriptionDetails.isFreeTrialEligible">
            30 day free trial**
          </li>
        </ul>
        <div class="disclaimer">
          <div>*3 hour max length for audio recordings and uploads</div>
          <div v-if="currentUserSubscriptionDetails.isFreeTrialEligible">
            **Introductory offer only available for new subscribers
          </div>
        </div>
      </div>
      <div
        class="subscription-option"
        :class="
          subscriptionModel === SUBSCRIPTION_MODELS.YEARLY.type && 'selected'
        "
        @click="subscriptionModel = SUBSCRIPTION_MODELS.YEARLY.type"
      >
        <div class="save-more-label">Save More + Get 1 Month Free</div>
        <div class="title">
          Yearly
          <RadioButton
            v-model="subscriptionModel"
            :inputId="SUBSCRIPTION_MODELS.YEARLY.type"
            :name="SUBSCRIPTION_MODELS.YEARLY.type"
            :value="SUBSCRIPTION_MODELS.YEARLY.type"
          />
        </div>
        <div class="price">
          {{ formattedSubscriptionPrice(yearlySubscriptionPrice) + " / year" }}
        </div>
        <ul class="features">
          <li v-for="perk in SUBSCRIPTION_MODELS.YEARLY.perks" :key="perk">
            {{ perk }}
          </li>
          <li v-if="currentUserSubscriptionDetails.isFreeTrialEligible">
            30 day free trial**
          </li>
        </ul>
        <div class="disclaimer">
          <div>*3 hour max length for audio recordings and uploads</div>
          <div v-if="currentUserSubscriptionDetails.isFreeTrialEligible">
            **Introductory offer only available for new subscribers
          </div>
        </div>
      </div>
      <div
        class="subscription-option"
        :class="
          subscriptionModel === SUBSCRIPTION_MODELS.FREE.type && 'selected'
        "
        @click="subscriptionModel = SUBSCRIPTION_MODELS.FREE.type"
      >
        <div class="title">
          <div class="plan-title-wrapper">
            <span>Free</span>
            <span class="current-plan-label">Current plan</span>
          </div>
          <RadioButton
            v-model="subscriptionModel"
            :inputId="SUBSCRIPTION_MODELS.FREE.type"
            :name="SUBSCRIPTION_MODELS.FREE.type"
            :value="SUBSCRIPTION_MODELS.FREE.type"
          />
        </div>
        <div class="price">{{ currencySymbol + "0" }}</div>
        <ul class="features">
          <li v-for="perk in SUBSCRIPTION_MODELS.FREE.perks" :key="perk">
            {{ perk }}
          </li>
        </ul>
        <div class="disclaimer">
          *3 hour max length for audio recordings and uploads
        </div>
      </div>
      <RoundedButton
        text="Get started"
        fitWidth
        accent
        lessRound
        uppercase
        class="action-button"
        @click="nextStep"
      />
    </div>
    <div v-else-if="step === 1">
      <header class="header-with-back-button">
        <button @click="step--" class="back-button">
          <img :src="ChevronLeftWhiteIcon" alt="Back" />
        </button>
        <h2>Payment details</h2>
      </header>
      <form class="payment-details-body" @submit.prevent="submitOrder">
        <div
          class="payment-info"
          v-if="!currentUserSubscriptionDetails.isFreeTrialEligible"
        >
          <label for="stripeInput">Card information</label>
          <div class="stripe-input-wrapper payment-input" id="stripeInput">
            <StripeElements
              v-if="stripe"
              v-slot="{ elements }"
              ref="elms"
              :stripe-key="stripeKey"
              :instance-options="instanceOptions"
              :elements-options="elementsOptions"
            >
              <StripeElement
                ref="card"
                :elements="elements"
                :options="cardOptions"
              />
            </StripeElements>
          </div>
          <!-- <div class="location-fields">
              <div class="p-field location-field">
                <label for="country">Country</label>
                <Dropdown
                  v-model="v$.country.$model"
                  :options="COUNTRIES"
                  id="country"
                  optionLabel="name"
                  placeholder="Country"
                  :class="{
                    'p-invalid': v$.country.$invalid && orderSubmitted,
                  }"
                  class="country-input dark-input payment-input"
                />
              </div>
              <div class="location-field">
                <label for="postalCode">Postal code</label>
                <InputText
                  id="postalCode"
                  placeholder="ZIP"
                  v-model="postalCode"
                  aria-describedby="session-name"
                  class="payment-input"
                  :class="{
                    'p-invalid': v$.postalCode.$invalid && orderSubmitted,
                  }"
                />
              </div>
            </div> -->
        </div>
        <SubscriptionOverviewCard
          :subscriptionModel="subscriptionModel"
          :formattedSubscriptionPrice="formattedSelectedSubscriptionPrice"
          class="subscription-overview-card"
          :freeTrialEligible="
            currentUserSubscriptionDetails.isFreeTrialEligible
          "
        />
        <div class="order-submission-error-message error">
          {{ orderSubmissionErrorMessage }}
        </div>
        <RoundedButton
          :text="
            currentUserSubscriptionDetails.isFreeTrialEligible
              ? 'Start free trial'
              : 'Pay ' + formattedSelectedSubscriptionPrice
          "
          :loading="orderSubmissionLoading"
          fitWidth
          accent
          lessRound
          uppercase
          class="action-button payment-button"
          :image="LockGreyIcon"
          type="submit"
          rightIcon
        />
      </form>
    </div>
    <div class="successful-subscription" v-else>
      <header class="">
        <img :src="CheckmarkCircleGreyIcon" />
        <h2>Congratulations!</h2>
      </header>
      <div v-if="isFreeTrialEligible">
        <p>
          {{
            "You have successfully subscribed to our Free Trial.\nYou will receive an email confirmation shortly."
          }}
        </p>
        <p>
          Enjoy one month of unlimited recordings stored securely on our cloud,
          AI transcripts, pay-per-order notes for audio recordings and uploads,
          and study assistant.
        </p>
        <p>Make the most of your trial period!</p>
      </div>
      <div v-else>
        <p>
          {{
            "You have successfully subscribed to our plan.\nYou will receive an email confirmation shortly."
          }}
        </p>
        <p>
          Thank you for choosing our service, and we hope you have a great
          experience!
        </p>
      </div>
      <RoundedButton
        text="OK"
        accent
        tall
        wide
        lessRound
        uppercase
        class="action-button"
        @click="nextStep"
      />
    </div>
  </Dialog>
</template>

<script>
import RoundedButton from "../../components/RoundedButton";
import IconButton from "../../components/IconButton";
import SubscriptionOverviewCard from "./SubscriptionOverviewCard";
import ChevronLeftWhiteIcon from "../../assets/images/Chevron-left-white-2.svg";
import LockGreyIcon from "../../assets/images/Lock-grey.svg";
import CloseGreyIcon from "../../assets/images/Close-grey.svg";
import CheckmarkCircleGreyIcon from "../../assets/images/Checkmark-circle-grey.svg";
import {
  COUNTRIES,
  COUNTRIES_CURRENCY,
  DEFAULT_COUNTRY_ISO,
  SUBSCRIPTION_MODELS,
} from "../../assets/constants";
import { loadStripe } from "@stripe/stripe-js";
import { StripeElements, StripeElement } from "vue-stripe-js";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import axios from "axios";
import { onMounted, ref, computed } from "vue";
import { useStore } from "vuex";
import { getCurrentUserSubscriptionDetails } from "../../assets/helpers_legacy";

export default {
  setup() {
    const stripe = ref(null);
    const instanceOptions = ref({
      // https://stripe.com/docs/js/initializing#init_stripe_js-options
    });
    const elementsOptions = ref({
      // https://stripe.com/docs/js/elements_object/create#stripe_elements-options
    });
    const store = useStore();

    const stripeKey = computed(() => {
      switch (store.state.currentUser.location) {
        case "CA":
          return process.env.VUE_APP_STRIPE_PK_CA;
        case "GB":
          return process.env.VUE_APP_STRIPE_PK_GB;
        case "US":
          return process.env.VUE_APP_STRIPE_PK_US;
        default:
          return process.env.VUE_APP_STRIPE_PK_US;
      }
    });

    onMounted(async () => {
      const res = await loadStripe(stripeKey.value);

      stripe.value = res;
    });

    return {
      stripe,
      stripeKey,
      instanceOptions,
      elementsOptions,
      v$: useVuelidate(),
    };
  },

  props: {
    setJustSubscribed: Function,
    visible: Boolean,
    setVisible: Function,
    refreshCurrentUserSubscriptionDetails: {
      type: Function,
      default: () => {},
    },
  },

  components: {
    RoundedButton,
    SubscriptionOverviewCard,
    StripeElements,
    StripeElement,
    IconButton,
  },

  data() {
    return {
      ChevronLeftWhiteIcon,
      LockGreyIcon,
      CheckmarkCircleGreyIcon,
      CloseGreyIcon,

      MAX_STEPS: 3,
      SUBSCRIPTION_MODELS: SUBSCRIPTION_MODELS,
      COUNTRIES: COUNTRIES,

      step: 0,
      subscriptionModel: SUBSCRIPTION_MODELS.MONTHLY.type,
      monthlySubscriptionPrice: "",
      yearlySubscriptionPrice: "",
      stripeLoaded: false,
      orderSubmitted: false,
      orderSubmissionLoading: false,
      orderSubmissionErrorMessage: "",
      postalCode: "",
      currentUserSubscriptionDetails: {},
      loading: true,
      cardOptions: {
        style: {
          base: {
            iconColor: "#c4f0ff",
            color: "#fff",
            fontWeight: "500",
            fontFamily: "Manrope, Roboto, Open Sans, Segoe UI, sans-serif",
            "::placeholder": {
              color: "#bababa",
            },
          },
          invalid: {
            iconColor: "#eb5b5b",
            color: "#eb5b5b",
          },
        },
      },
    };
  },

  async created() {
    this.currentUserSubscriptionDetails =
      await getCurrentUserSubscriptionDetails();
    this.loading = false;
  },

  computed: {
    currentUserCountryISO() {
      return this.$store.state.currentUser.location || DEFAULT_COUNTRY_ISO;
    },

    formattedSelectedSubscriptionPrice() {
      return this.isFree
        ? "Free"
        : this.formattedSubscriptionPrice(
            this.subscriptionModel === SUBSCRIPTION_MODELS.MONTHLY.type
              ? this.monthlySubscriptionPrice
              : this.yearlySubscriptionPrice
          );
    },

    currencySymbol() {
      return COUNTRIES_CURRENCY[this.currentUserCountryISO].symbol;
    },

    currencyAbbreviation() {
      return COUNTRIES_CURRENCY[this.currentUserCountryISO].abbreviation;
    },

    orderFieldsInvalid() {
      return this.v$.country.$invalid || this.v$.postalCode.invalid;
    },

    isFree() {
      return this.subscriptionModel === this.SUBSCRIPTION_MODELS.FREE.type;
    },
  },

  validations() {
    return {
      postalCode: {
        required,
      },
      country: {
        required,
      },
    };
  },

  async mounted() {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${JSON.parse(localStorage.getItem("tokenMP")).accessToken}`
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      process.env.VUE_APP_MP_SERVER_API_URL + "self/stripe-subscription-prices",
      requestOptions
    );

    const subscriptionPrices = await response.json();
    this.monthlySubscriptionPrice =
      subscriptionPrices.data.monthly.unit_amount_decimal / 100;
    this.yearlySubscriptionPrice =
      subscriptionPrices.data.yearly.unit_amount_decimal / 100;
  },

  methods: {
    async nextStep() {
      // If selected free plan, no need to collect payment details
      if (this.step === 0 && this.isFree) {
        this.setVisible(false);
        await this.refreshCurrentUserSubscriptionDetails();
        return;
      }

      this.step++;
      this.orderSubmissionErrorMessage = "";

      if (this.step >= this.MAX_STEPS) {
        this.setVisible(false);
        await this.refreshCurrentUserSubscriptionDetails();
      }
    },

    formattedSubscriptionPrice(price) {
      return this.currencySymbol + price + " " + this.currencyAbbreviation;
    },

    submitOrder() {
      this.orderSubmitted = true;
      this.orderSubmissionLoading = true;
      this.orderSubmissionErrorMessage = "";

      const data = {
        interval: this.subscriptionModel,
      };
      let stripeElement = null;
      let stripeElements = null;

      axios
        .post(
          process.env.VUE_APP_MP_SERVER_API_URL + "self/subscription",
          data,
          {
            withCredentials: false,
            headers: {
              Authorization:
                "Bearer " +
                JSON.parse(localStorage.getItem("tokenMP")).accessToken,
            },
          }
        )
        .then((response) => {
          if (response.data.status === "error") {
            this.orderSubmissionErrorMessage = response.message;
            console.log("Error: ", response);
          } else if (response.data.status === "success") {
            if (response.data.data.subscription.status === "trialing") {
              // Auto-enrolled for free month trial
              this.onSuccessfulSubscription();
              return;
            } else {
              // Not eligible for free month trial, pay now
              stripeElement = this.$refs.card.stripeElement;
              stripeElements = this.$refs.elms;

              stripeElements.instance
                .confirmCardPayment(
                  response.data.data.paymentIntent.client_secret,
                  {
                    payment_method: {
                      card: stripeElement,
                    },
                  }
                )
                .then((result) => {
                  if (result.error) {
                    this.orderSubmissionErrorMessage = result.error.message;
                    return;
                  } else {
                    // Successful subscription
                    this.onSuccessfulSubscription();
                  }
                });
            }
          }
          this.orderSubmissionLoading = false;
        })
        .catch((e) => {
          console.error("error while creating subscription:", e);
          this.orderSubmissionErrorMessage =
            "There was an error processing your order";
          this.orderSubmissionLoading = false;
        });
    },

    async onSuccessfulSubscription() {
      if (this.setJustSubscribed) {
        this.setJustSubscribed(true);
      }

      this.$emit("subscriptionSuccess");

      this.nextStep();
    },
  },
};
</script>

<style lang="scss">
.subscription-signup-dialog {
  background-color: map-get($colours, accent-purple-2);
  color: map-get($colours, white);
  border-radius: 1.2rem;
  overflow: hidden;
  box-shadow: 0 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.2),
    0 0.3rem 1.4rem 0 rgba(0, 0, 0, 0.12), 0 0.8rem 1rem 0 rgba(0, 0, 0, 0.14);
  width: 50rem;
  padding: 0;

  .close-button {
    position: absolute;
    top: 1.6rem;
    right: 1.6rem;
  }

  .p-dialog-header {
    display: none;
  }

  .p-dialog-content {
    background-color: inherit;
    color: inherit;
    padding: 4rem 2.4rem;
    overflow-x: hidden;
  }

  .header-with-back-button {
    display: flex;
    flex-direction: row;
    align-items: center;

    h1,
    h2 {
      margin: 0;
    }

    .back-button {
      margin-right: 2.4rem;
      background: none;

      img {
        height: 1.2rem;
        width: 1.2rem;
      }
    }
  }

  h2 {
    margin: 0;
  }

  .subscriptions-body {
    > *:not(:first-child) {
      margin-top: 1.6rem;
    }

    .free-trial-disclaimer {
      font-size: 1.4rem;
      color: rgba(255, 255, 255, 0.8);
      margin-top: 0.8rem;
      font-family: $font-secondary;
    }

    .subscription-option {
      background-color: map-get($colours, accent-purple-3);
      border-radius: 1.4rem;
      padding: 1.4rem 1.6rem;
      font-family: $font-secondary;
      border: 0.1rem solid rgba(0, 0, 0, 0);
      transition: border 0.3s ease-in-out, max-height 0.5s;
      cursor: pointer;
      max-height: 8.4rem;
      position: relative;

      &.selected {
        border-color: map-get($colours, accent-purple);
        max-height: 30rem;
        transition: max-height 0.5s ease-out;

        .features,
        .disclaimer {
          opacity: 1;
          transition: opacity 0.3s ease-out;
          transition-delay: 0.3s;
        }
      }

      .title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        text-transform: uppercase;
        letter-spacing: 0.125rem;
        color: map-get($colours, accent-purple);
        font-size: 1.4rem;

        .plan-title-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 0.8rem;

          .current-plan-label {
            background-color: rgba(255, 255, 255, 0.8);
            font-size: 0.8rem;
            color: map-get($colours, grey-5);
            border-radius: 0.4rem;
            padding: 0.2rem 0.6rem;
            font-family: $font-secondary;
            font-weight: bold;
          }
        }
      }

      .save-more-label {
        background-color: map-get($colours, accent-purple-1);
        font-size: 1rem;
        padding: 0.4rem 0.6rem;
        border-radius: 1.4rem;
        border: 0.1rem solid map-get($colours, accent-purple);
        position: absolute;
        top: 0;
        left: 8rem;
        transform: translateY(-30%);
      }

      .price {
        margin-top: 0.8rem;
      }

      .features,
      .disclaimer {
        opacity: 0;
        transition: opacity 0.3s ease-out;
      }

      .features {
        font-size: 1.4rem;
        color: map-get($colours, grey-3);
        margin: 1.6rem 0 1.8rem;
        list-style-image: url("../../assets/images/Checkmark-purple.svg");
        padding-left: 1.6rem;

        li {
          padding-left: 0.8rem;
        }

        > *:not(:first-child) {
          margin-top: 1.2rem;
        }
      }

      .disclaimer {
        font-size: 1rem;
        color: map-get($colours, translucent-white-60);
        text-align: center;
      }
    }

    .action-button {
      margin-top: 4.8rem;
    }
  }

  .payment-info {
    margin-top: 3.2rem;

    label {
      font-family: $font-secondary;
      font-weight: 600;
      font-size: 1.4rem;
      color: map-get($colours, grey);
    }

    .location-fields {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;

      .location-field {
        display: flex;
        flex-direction: column;
        margin-top: 1.6rem;
        width: 50%;
      }
    }
  }

  .order-submission-error-message {
    font-size: 1.4rem;
  }

  .subscription-overview-card {
    margin-bottom: 1.6rem;
  }

  .payment-button {
    margin-top: 2.4rem;
  }

  .payment-input {
    border: 0.2rem solid map-get($colours, accent-purple);
    padding: 1rem;
    border-radius: 0.6rem;
    font-size: 1.4rem;
    background: transparent;
    color: map-get($colours, white);
    margin-top: 0.8rem;

    &:hover {
      border: 0.2rem solid map-get($colours, accent-purple-1);
    }

    &:focus {
      border: 0.2rem solid map-get($colours, accent-purple);
    }

    &::placeholder,
    .p-dropdown-label.p-placeholder,
    .p-dropdown-trigger {
      color: map-get($colours, grey-3);
    }

    .p-inputtext {
      color: map-get($colours, white);
      font-size: 1.4rem;
    }

    .p-dropdown-label {
      padding: 0;
    }
  }

  .successful-subscription {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 1.6rem;

    header {
      h2 {
        margin: 2.4rem 0;
      }
    }

    p {
      font-size: 1.6rem;
      width: 37rem;
      margin: 1.6rem 0;
      white-space: pre-line;
    }

    .action-button {
      margin-top: 5.6rem;
    }
  }

  .p-radiobutton {
    .p-radiobutton-box,
    .p-radiobutton-box.p-highlight {
      border-color: map-get($colours, accent-purple-2);
    }
  }
}

.p-dropdown-items-wrapper {
  font-size: 1.4rem;
  background: map-get($colours, accent-purple-1);

  .p-dropdown-items {
    .p-dropdown-item {
      color: map-get($colours, white);
      background: map-get($colours, accent-purple-4);

      &.p-highlight {
        background: darken(map-get($colours, accent-purple-1), 5%);
        color: map-get($colours, white);
        font-weight: bold;
      }
    }
  }
}
</style>
