import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";

export default createStore({
  state: {
    currentUser: null,
    storedUsers: JSON.parse(localStorage.getItem("storedUsers")) || [],
    sessionDetails: {},
    editor: null,
    platform: null,
  },

  plugins: [
    new VuexPersistence({
      modules: ["currentUser", "storedUsers", "sessionDetails", "platform"],
    }).plugin,
  ],

  mutations: {
    setSessionDetails(state, sessionDetails) {
      state.sessionDetails = sessionDetails;
    },

    updateCurrentUser(state, attributes) {
      state.currentUser = { ...state.currentUser, ...attributes };
      if (state.editor) {
        state.editor.chain().user(state.currentUser).run();
      }
    },

    /**
     * Keeps track of which accounts have logged in on this browser
     * Mainly used to determine whether to show the onboarding screens
     * or not - should be refactored in the future with a "lastLogin"
     * or similar field from the server
     **/
    updateStoredUsers(state, email) {
      if (!state.storedUsers.includes(email)) {
        state.storedUsers.push(email);
        localStorage.setItem("storedUsers", JSON.stringify(state.storedUsers));
      }
    },

    setCurrentUser(state, user) {
      state.currentUser = user;
    },

    setEditor(state, editor) {
      state.editor = editor;
    },

    destroyEditor(state) {
      state.editor.destroy();
    },

    setPlatform(state, platform) {
      state.platform = platform;
    },
  },
  actions: {},
  getters: {
    storedUserByEmail: (state) => (email) => {
      return state.storedUsers.includes(email);
    },
  },
});
