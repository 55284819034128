<template>
  <OverlayPanel
    ref="appGrid"
    class="app-grid"
    :class="this.$store.state.lightModeActive ? 'light-mode-active' : ''"
    :dismissable="true"
    :ariaCloseLabel="'Close App Grid'"
    @hide="clearFeedback"
    @show="getSize"
    id="op"
  >
    <Transition name="slide-up" mode="out-in">
      <div class="app-grid-content" v-if="!showFeedbackForm">
        <div class="shortcuts section">
          <header>
            <IconButton
              :icon="
                this.$store.state.lightModeActive
                  ? BackArrowDark
                  : BackArrowLight
              "
              alt="Back"
              @click="clickBack"
              dark
              xsmall
              class="back-arrow"
            />
            Shortcuts
          </header>
          <div class="apps">
            <button @click="goToURL(LINKS.HABITAT_LEARN)">
              <img
                :src="
                  this.$store.state.lightModeActive
                    ? HabitatLearnLogoColour
                    : HabitatLearnLogo
                "
                alt="Habitat Learn"
              />
              <span>Habitat Learn</span>
            </button>
            <button @click="goToURL(LINKS.BEEHIVE)">
              <img
                :src="
                  this.$store.state.lightModeActive
                    ? BeehiveLogoColour
                    : BeehiveLogo
                "
                alt="Beehive"
              />
              <span>Beehive</span>
            </button>
            <button @click="goToURL(LINKS.MESSENGER_PIGEON)">
              <img
                :src="
                  this.$store.state.lightModeActive
                    ? MessengerPigeonLogoColour
                    : MessengerPigeonLogo
                "
                alt="Messenger Pigeon"
              />
              <span>Messenger Pigeon</span>
            </button>
            <button @click="goToURL(LINKS.CORAL)">
              <img
                :src="
                  this.$store.state.lightModeActive
                    ? CoralLogoColour
                    : CoralLogo
                "
                alt="Coral"
              />
              <span>Coral</span>
            </button>
          </div>
        </div>
        <div class="help-feedback section">
          <header>Help & Feedback</header>
          <div class="links">
            <TextButton
              text="Products Info"
              light
              small
              @click="goToURL(LINKS.HABITAT_LEARN_PRODUCTS)"
            />
            <TextButton
              text="Contact Us"
              :light="!this.$store.state.lightModeActive"
              small
              @click="goToURL(LINKS.HABITAT_LEARN_CONTACT)"
            />
            <TextButton
              text="Send Feedback"
              :light="!this.$store.state.lightModeActive"
              :onClick="openFeedbackForm"
              small
            />
          </div>
        </div>
      </div>
      <FeedbackForm
        v-else-if="showFeedbackForm"
        :close="closeFeedbackForm"
        :size="appGridSize"
      />
    </Transition>
  </OverlayPanel>
</template>

<script>
import CoralLogo from "../assets/images/logos/Coral-Logo-Light.svg";
import CoralLogoColour from "../assets/images/logos/Coral-Logo-Colour.svg";
import BeehiveLogo from "../assets/images/logos/Beehive-Logo-Light.svg";
import BeehiveLogoColour from "../assets/images/logos/Beehive-Logo-Colour.svg";
import HabitatLearnLogo from "../assets/images/logos/Habitat-Learn-Logo-Light.svg";
import HabitatLearnLogoColour from "../assets/images/logos/Habitat-Learn-Logo-Colour.svg";
import LaunchAdminLogo from "../assets/images/logos/Launch-Admin-Logo-Light.svg";
import LaunchAdminLogoColour from "../assets/images/logos/Launch-Admin-Logo-Colour.svg";
import MessengerPigeonLogo from "../assets/images/logos/MP-Logo-Light.svg";
import MessengerPigeonLogoColour from "../assets/images/logos/MP-Logo-Colour.svg";
import TextButton from "./TextButton.vue";
import IconButton from "./IconButton";
import BackArrowLight from "../assets/images/Back-Arrow-Light.svg";
import BackArrowDark from "../assets/images/Back-Arrow-Dark.svg";
import { LINKS } from "../assets/constants";
import FeedbackForm from "../components/FeedbackForm.vue";

export default {
  components: {
    TextButton,
    IconButton,
    FeedbackForm,
  },

  data() {
    return {
      MessengerPigeonLogo,
      MessengerPigeonLogoColour,
      CoralLogo,
      CoralLogoColour,
      BeehiveLogo,
      BeehiveLogoColour,
      HabitatLearnLogo,
      HabitatLearnLogoColour,
      LaunchAdminLogo,
      LaunchAdminLogoColour,
      BackArrowLight,
      BackArrowDark,
      LINKS,
      showFeedbackForm: false,
      appGridSize: {},
    };
  },

  methods: {
    toggle(event) {
      this.$refs.appGrid.toggle(event);
    },

    clickBack() {
      this.$refs.appGrid.hide();
    },

    goToURL(url) {
      window.location.href = url;
    },

    openFeedbackForm() {
      this.showFeedbackForm = true;
    },

    closeFeedbackForm() {
      this.showFeedbackForm = false;
    },
    clearFeedback() {
      this.showFeedbackForm = false;
    },
    getSize() {
      this.appGridSize.width = document.getElementById("op").clientWidth + "px";
      this.appGridSize.height =
        document.getElementById("op").clientHeight + "px";
    },
  },
};
</script>

<style lang="scss">
$icon-size-s: 4rem;
$icon-size-m: 4.8rem;
$icon-size-l: 5.6rem;
$button-padding: 2.4rem;
$outside-horizontal-padding-s: 2rem;
$outside-horizontal-padding-l: 3.2rem;
$screen-width-breakpoint: 500;
$screen-height-breakpoint: 700;

.app-grid {
  background-color: map-get($colours, accent-purple-3);
  width: 100%;
  min-height: 100%;
  margin-top: 0;

  @include cwh($screen-width-breakpoint, $screen-height-breakpoint) {
    width: initial;
    min-height: initial;
    margin-top: initial;
    border-radius: 1.4rem;

    .back-arrow {
      display: none;
    }
  }

  &.light-mode-active {
    background-color: map-get($colours, white);

    .section {
      header {
        color: map-get($colours, black);
      }

      &.shortcuts {
        border-bottom: 0.1rem solid map-get($component-colours, border-dark);
      }

      .apps {
        button {
          color: map-get($colours, grey-7);

          &:hover:not(:disabled) {
            background-color: map-get($component-colours, black-overlay-5);
            filter: none;
          }
        }
      }
    }
  }

  &.p-overlaypanel {
    &:after,
    &:before {
      display: none;
    }
  }

  .p-overlaypanel-content {
    padding: 0;
  }

  .section {
    padding: 2.4rem 0;

    header {
      @include section-header-2;
      color: map-get($colours, white);
      padding: 0 $outside-horizontal-padding-s 2.4rem;

      @include cwh($screen-width-breakpoint, $screen-height-breakpoint) {
        padding-left: $outside-horizontal-padding-l;
        padding-right: $outside-horizontal-padding-l;
      }
    }
  }

  .shortcuts {
    border-bottom: 0.1rem solid map-get($component-colours, border-light);

    header {
      display: flex;
      align-items: center;

      .back-arrow {
        margin-right: 1.6rem;
      }
    }

    .apps {
      display: grid;
      grid-template-columns: auto auto auto;
      gap: 2.4rem 1.6rem;
      padding: 0 $outside-horizontal-padding-s;
      justify-items: center;

      @include rwd(700) {
        grid-template-columns: auto auto auto auto;
      }

      @include ch($screen-height-breakpoint) {
        grid-template-columns: auto auto auto;
      }

      @include cwh($screen-width-breakpoint, $screen-height-breakpoint) {
        padding: 0 $outside-horizontal-padding-l;
      }

      button {
        background-color: transparent;
        @include caption;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
        padding-bottom: 0.4rem;
        color: map-get($colours, grey-2);
        white-space: pre-wrap;
        width: 8rem;
        border-radius: 0.8rem;

        @include ch(750) {
          @include section-sub-header;
          width: 9.4rem;
        }

        @include ch(950) {
          @include section-header-3;
          width: 11.2rem;
        }

        &:disabled {
          color: map-get($colours, grey-5);
        }

        img {
          margin: $button-padding;
          width: $icon-size-s;
          height: $icon-size-s;

          @include ch(750) {
            width: $icon-size-m;
            height: $icon-size-m;
          }
        }

        span {
          margin: 0 0.4rem;
        }

        &:hover:not(:disabled) {
          background-color: map-get($component-colours, black-overlay-30);
          filter: none;
        }
      }
    }
  }

  .help-feedback {
    .links {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0 $outside-horizontal-padding-s;

      @include cwh($screen-width-breakpoint, $screen-height-breakpoint) {
        padding: 0 $outside-horizontal-padding-l;
      }

      > *:not(:first-child) {
        margin-top: 2.4rem;
      }
    }
  }
  .slide-up-enter-active,
  .slide-up-leave-active {
    transition: all 0.1s ease-out;
  }

  .slide-up-enter-from {
    opacity: 0;
    transform: translateX(20px);
  }

  .slide-up-leave-to {
    opacity: 0;
    transform: translateX(-20px);
  }
}
</style>
