<template>
  <Dialog
    :visible="props.visible"
    modal
    class="hours-used-modal"
    :closable="false"
  >
    <template #header>
      <img :src="BatteryGirl" class="header-image" />
      <h1>Reminder</h1>
    </template>
    <div class="content">
      <p>
        You’ve used {{ props.hoursUsed }} out of your 30-hour recording limit.
        Just a heads-up to keep you in the loop.
      </p>
      <p>Subscribe now to unlock unlimited recording and more features!</p>
    </div>
    <template #footer>
      <RoundedButton
        text="Dismiss"
        lessRound
        accent
        fitWidth
        uppercase
        tall
        :onClick="() => props.setVisible && props.setVisible(false)"
      />
    </template>
  </Dialog>
</template>
<script setup lang="ts">
import RoundedButton from "../RoundedButton.vue";

const BatteryGirl = require("../../assets/images/Battery-Girl.png") as string;

interface Props {
  visible: boolean;
  hoursUsed: number;
  setVisible: Function,
}
const props = defineProps<Props>();
</script>

<style lang="scss">
$side-padding: 2.4rem;

.hours-used-modal {
  background-color: map-get($colours, accent-purple-2);
  border-radius: 1.2rem;
  overflow: hidden;
  overflow-y: auto;
  width: 35rem;

  .p-dialog-header {
    background-color: inherit;
    display: flex;
    flex-direction: column;
    color: map-get($colours, white);
    padding: $side-padding $side-padding 0;

    .header-image {
      height: 11.5rem;
      width: 15.6rem;
    }

    h1 {
      font-family: $font-default;
      font-weight: bold;
      font-size: 1.8rem;
      margin-bottom: 0;
      margin-top: 1.6rem;
    }
  }

  .p-dialog-content {
    background-color: inherit;
    padding: 0 $side-padding;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: map-get($colours, white);
    font-size: 1.4rem;
    font-family: $font-secondary;

    p {
      text-align: center;
      line-height: 1.6rem;
      letter-spacing: 0.1rem;
      margin: 1.6rem;
    }
  }

  .p-dialog-footer {
    background-color: inherit;
    padding: 2.4rem $side-padding $side-padding;
    display: flex;
    flex-direction: column;
    gap: 2.4rem;

    button {
      width: 100%;
      margin: 0;
    }
  }
}
</style>
