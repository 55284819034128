<template>
  <div class="media-player" ref="mediaPlayer">
    <div class="controls" ref="controls">
      <span v-if="!isInfoPanelOpen" class="button-placeholder" />
      <IconButton
        :icon="Rewind10WhiteIcon"
        alt="Rewind media"
        :onclick="skipBackward"
        xsmall
        v-tooltip.top="'Rewind ' + MEDIA_SKIP_AMOUNT + 's'"
      />
      <IconButton
        :icon="isPaused && mediaPaused ? PlayWhiteIcon : PauseWhiteIcon"
        alt="Toggle media playing"
        :onclick="togglePlay"
        xsmall
        v-tooltip.top="isPaused ? 'Play' : 'Pause'"
      />
      <IconButton
        :icon="SkipForward10WhiteIcon"
        alt="Fast forward media"
        :onclick="skipForward"
        xsmall
        v-tooltip.top="'Skip forward ' + MEDIA_SKIP_AMOUNT + 's'"
      />

      <div
        v-if="mediaType !== 'video'"
        class="media-timeline"
        :class="isInfoPanelOpen && 'shrink'"
      >
        <div class="media-title">{{ mediaName }}</div>
        <div class="timeline-wrapper">
          <div class="time">
            <div class="current">{{ currentTime }}</div>
            <!-- <div class="divider">/</div> -->
            <div class="length">{{ totalLength }}</div>
          </div>
          <div class="timeline" ref="timeline">
            <div class="time-preview" ref="timePreview">{{ previewTime }}</div>
            <div class="progress" ref="progressBar" />
          </div>
        </div>
      </div>
      <div
        class="media-timer"
      >
        <div
          class="media-timer-timer"
        >
          {{ `${currentTime} / ${totalLength}` }}
      </div>

      </div>
      <div class="button-with-dropdown-attached gap">
        <IconButton
          :icon="PlaybackSpeedWhiteIcon"
          alt="Playback speed"
          xsmall
          v-tooltip.top="'Playback speed'"
        />
        <div class="dropdown-menu right">
          <button
            class="dropdown-menu-button text-normal"
            @click="setPlaybackSpeed(0.25)"
          >
            0.25x
          </button>
          <button
            class="dropdown-menu-button text-normal"
            @click="setPlaybackSpeed(0.5)"
          >
            0.5x
          </button>
          <button
            class="dropdown-menu-button text-normal"
            @click="setPlaybackSpeed(1)"
          >
            1x
          </button>
          <button
            class="dropdown-menu-button text-normal"
            @click="setPlaybackSpeed(2)"
          >
            2x
          </button>
          <button
            class="dropdown-menu-button text-normal"
            @click="setPlaybackSpeed(3)"
          >
            3x
          </button>
        </div>
      </div>
      <IconButton
        v-if="mediaType !== 'video'"
        :icon="AudioVisualizationWhiteIcon"
        alt="Audio visualization"
        :onclick="toggleAudioVisualization"
        xsmall
        v-tooltip.top="'Audio visualization'"
      />
      <div class="volume-container">
        <IconButton
          :icon="isMuted ? VolumeMutedWhiteIcon : VolumeOnWhiteIcon"
          alt="Play Media"
          :onclick="toggleMuted"
          xsmall
          v-tooltip.top="'Volume'"
        />
        <div class="volume-slider" ref="volumeSlider" @click="clickOnSlider">
          <div class="volume-percentage" ref="volumePercentage"></div>
        </div>
      </div>
      <IconButton
        v-if="mediaType !== 'video'"
        :icon="TimestampWhiteIcon"
        alt="Insert Timestamp"
        class="text-formatting-button"
        v-tooltip.top="'Insert timestamp'"
        xsmall
        :onclick="insertCurrentTimestamp"
      />
      <div
        class="split-line"
      />
      <IconButton
        :v-if="addBookmark !== null"
        :icon="BookmarkIcon"
        alt="Bookmark"
        class="text-formatting-button"
        v-tooltip.top="'Bookmark'"
        xsmall
        :onclick="addBookmark"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from "vue";
import { getTimeCodeFromNum } from "../../assets/helpers_legacy";
import IconButton from "../IconButton.vue";

const VolumeOnWhiteIcon = require("../../assets/images/Volume-on-white.svg") as string;
const VolumeMutedWhiteIcon = require("../../assets/images/Volume-muted-white.svg") as string;
const Rewind10WhiteIcon = require("../../assets/images/Rewind-10-white.svg") as string;
const SkipForward10WhiteIcon = require("../../assets/images/Skip-forward-10-white.svg") as string;
const PlayWhiteIcon = require("../../assets/images/Play-white.svg") as string;
const PauseWhiteIcon = require("../../assets/images/Pause-white.svg") as string;
const PlaybackSpeedWhiteIcon = require("../../assets/images/Playback-speed-white.svg") as string;
const AudioVisualizationWhiteIcon = require("../../assets/images/Audio-visualization-white.svg") as string;
const TimestampWhiteIcon = require("../../assets/images/Timestamp-white.svg") as string;
const BookmarkIcon = require("../../assets/images/Bookmark-white.svg") as string;

const props = defineProps({
  mediaComponent: {
    type: HTMLMediaElement,
    default: null,
  },
  mediaType: {
    type: String,
    default: "audio",
  },
  audioVisualization: Boolean,
  mediaPaused: Boolean,
  setPlaybackSpeed: {
    type: Function,
    default: () => {},
  },
  MEDIA_SKIP_AMOUNT: {
    type: Number,
    default: 10,
  },
  insertCurrentTimestamp: Function,
  mediaName: String,
  isInfoPanelOpen: Boolean,
  addBookmark: {
    type: Function,
    default: null,
  },
})

const currentTime = ref("0:00");
const previewTime = ref("");
const totalLength = ref("");
const mediaComponent = ref(props.mediaComponent);
const isMuted = ref(mediaComponent.value.muted);
const isPaused = ref(mediaComponent.value.paused);
const showAudioVisualization = ref(props.audioVisualization);
const mediaPlayer = ref(null);

// template refs
const controls = ref<HTMLDivElement|null>(null);
const volumeSlider = ref<HTMLDivElement|null>(null);
const timeline = ref<Element|null>(null);
const timePreview = ref<HTMLDivElement|null>(null);
const progressBar = ref<HTMLDivElement|null>(null);
const volumePercentage = ref<HTMLDivElement|null>(null);

const emit = defineEmits([
  "updateShowAudioVisualization",
  "updateCurrentTime",
  "toggleAudioVisualization",
]);

function toggleMuted() {
  mediaComponent.value.muted = !mediaComponent.value.muted;
}

function togglePlay() {
  if (mediaComponent.value.paused) {
    mediaComponent.value.play();
    isPaused.value = false;
  } else {
    mediaComponent.value.pause();
    isPaused.value = true;
  }
}

watch(() => props.mediaComponent, function (newComponent: HTMLMediaElement) {
  if (newComponent) {
    mediaComponent.value = newComponent;
    currentTime.value = getTimeCodeFromNum(mediaComponent.value.currentTime);
    setupMediaListeners();
  }
})

function toggleAudioVisualization() {
  showAudioVisualization.value = !showAudioVisualization.value;
  emit("updateShowAudioVisualization", showAudioVisualization.value);
}

function skipForward() {
  mediaComponent.value.currentTime += props.MEDIA_SKIP_AMOUNT;
}

function skipBackward() {
  mediaComponent.value.currentTime -= props.MEDIA_SKIP_AMOUNT;
}

function clickOnSlider(evt: Event) {
  if (volumeSlider.value) {
    const sliderWidth = window.getComputedStyle(volumeSlider.value).width;
    const newVolume = (evt as MouseEvent).offsetX / parseInt(sliderWidth);
    mediaComponent.value.volume = newVolume;
    if (volumePercentage.value) {
      volumePercentage.value.style.width = newVolume * 100 + "%";
    }
  }
}

function setPlaybackSpeed(playbackSpeed: number) {
  mediaComponent.value.playbackRate = playbackSpeed;
}

function setupMediaListeners() {
  if (totalLength.value === "") {
    mediaComponent.value.addEventListener(
      "loadeddata",
      () => {
        totalLength.value = getTimeCodeFromNum(mediaComponent.value.duration);
        mediaComponent.value.volume = 0.75;
      },
      false,
    );
  }

  mediaComponent.value.addEventListener(
    "timeupdate",
    () => {
      currentTime.value = getTimeCodeFromNum(mediaComponent.value.currentTime);
    },
    false,
  );

  mediaComponent.value.addEventListener(
    "pause",
    () => {
      isPaused.value = mediaComponent.value.paused;
    },
    false,
  );

  mediaComponent.value.addEventListener(
    "play",
    () => {
      isPaused.value = mediaComponent.value.paused;
    },
    false,
  );
}

onMounted(() => {
    if (props.mediaType === "video") {
      emit("updateShowAudioVisualization", true);
    }

    setupMediaListeners();

    //click on timeline to skip around
    if (timeline.value !== null) {
      timeline.value.addEventListener(
        "click",
        (e) => {
          if (timeline.value) {
            const timelineWidth = window.getComputedStyle(timeline.value).width;
            const timeToSeek =
              ((e as MouseEvent).offsetX / parseInt(timelineWidth)) * mediaComponent.value.duration;
            emit("updateCurrentTime", timeToSeek);
          }
        },
        false
      );

      timeline.value.addEventListener(
        "mouseleave",
        () => {
          if (timePreview.value) {
            timePreview.value.style.opacity = "0";
          }
        },
        false
      );

      timeline.value.addEventListener(
        "mousemove",
        (e) => {
          if (timeline.value && timePreview.value) {
            const timelineWidth = window.getComputedStyle(timeline.value).width;
            const positionPercentage = (e as MouseEvent).offsetX / parseInt(timelineWidth);
            timePreview.value.style.left = positionPercentage * 100 + "%";
            timePreview.value.style.opacity = "1";
            const timeToSeek = positionPercentage * mediaComponent.value.duration;
            previewTime.value = getTimeCodeFromNum(timeToSeek);
          }
        },
        false
      );

    }

    setInterval(() => {
      if (progressBar.value) {
        progressBar.value.style.width =
        (mediaComponent.value.currentTime / mediaComponent.value.duration) * 100 +
        "%";
      currentTime.value = getTimeCodeFromNum(mediaComponent.value.currentTime);
      }
    }, 500);
})
</script>

<style lang="scss">
.media-player {
  font-family: arial;
  color: white;
  font-size: 1.2rem;

  display: grid;
  grid-template-rows: 6px auto;

  .controls {
    height: 6.4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    width: 100%;

    > * {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.8rem;
      height: initial;

      @media only screen and (max-width: 950px) {
        padding: 0.6rem;
      }
    }

    .button-placeholder {
      width: 4rem;

      @media only screen and (max-width: 1000px) {
        display: none;
      }
    }

    .media-timeline {
      // width: 20rem;
      display: flex;
      flex-direction: column;
      height: 4rem;
      background: map-get($colours, white);
      border-radius: 0.4rem;
      padding: 0 0.4rem;
      margin: 0 0.8rem;
      width: 20rem;

      @media only screen and (max-width: 1000px) {
        &.shrink {
          width: 15rem;
        }
      }

      .media-title {
        color: map-get($colours, black);
        @include caption;
        max-width: 90%;
        text-overflow: ellipsis;
        color: black;
        overflow: hidden;
        white-space: nowrap;
      }

      .timeline-wrapper {
        width: 100%;

        .time {
          display: flex;
          justify-content: space-between;
          width: 100%;
          font-size: 1rem;
          color: map-get($colours, grey-7);
          font-family: $font-secondary;

          > * {
            padding: 2px;
          }

          .length {
            color: map-get($colours, grey-5);
          }
        }

        .timeline {
          background: map-get($colours, grey-2);
          width: 100%;
          height: 0.3rem;
          position: relative;
          cursor: pointer;

          .time-preview {
            padding: 0.8rem;
            position: absolute;
            background: map-get($colours, accent-purple-4);
            font-size: 1.2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: translateX(-50%) translateY(-100%);
            pointer-events: none;
            opacity: 0;
            transition: opacity 0.2s;
            border-radius: 0.8rem;
          }

          .progress {
            background: map-get($colours, accent-purple-3);
            width: 0%;
            height: 100%;
            transition: 0.25s;
          }
        }
      }
    }

    .volume-container {
      cursor: pointer;
      position: relative;
      z-index: 2;

      .volume-button {
        height: 2.6rem;
        display: flex;
        align-items: center;

        .volume {
          transform: scale(0.7);
        }
      }

      .volume-slider {
        position: absolute;
        left: -0.2rem;
        z-index: -1;
        width: 0;
        height: 15px;
        background: white;
        transition: 0.25s;

        .volume-percentage {
          background: map-get($colours, accent-purple-3);
          height: 100%;
          width: 75%;
        }
      }

      &:hover {
        .volume-slider {
          left: -123px;
          width: 120px;
        }
      }
    }
  }

  .p-inputswitch {
    width: 3.75rem;
    height: 1.5rem;
  }

  .p-inputswitch .p-inputswitch-slider {
    border-radius: 1.5rem;
    background: map-get($colours, grey-3);

    &:hover {
      background: map-get($colours, grey-3);
    }
  }

  .p-inputswitch-checked .p-inputswitch-slider {
    background: map-get($colours, accent-purple-1);

    &:hover {
      background: map-get($colours, accent-purple-1);
    }
  }

  .p-inputswitch .p-inputswitch-slider:before {
    width: 2.4rem;
    height: 2.4rem;
    margin-top: -1.2rem;
    background: map-get($colours, white);
    background-image: url("../../assets/images/AudioLines-grey.svg");
    background-repeat: no-repeat;
    background-position: center;
  }

  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    background: map-get($colours, white);
    background-image: url("../../assets/images/AudioLines.svg");
    background-repeat: no-repeat;
    background-position: center;
  }

  .split-line {
    width: 1px;
    height: 30%;
    padding: 0;
    margin: 0.8rem;
    background-color: rgba(25, 25, 25, 0.3);
  }

  .media-timer {
    background-color: map-get($colours, accent-purple-3);
    padding: 1.2rem 1.6rem;
    border-radius: 0.8rem;
    .media-timer-timer {
      font-family: $font-secondary;
      font-size: 1.2rem;
    }
  }
}
</style>
