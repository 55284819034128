<template>
  <div class="chat-list">
    <div v-show="showChatMainMenu">
      <SidePanelHeader title="Conversations" :onClose="closeChat" />
      <div class="conversations">
        <p v-if="otherUsers.length === 0" class="no-chat-partners">
          Available chat partners will appear here.
        </p>
        <button
          v-else
          v-for="(user, index) in otherUsers"
          :key="user.id"
          class="conversation"
          @click="changeTabIndex(user, index)"
        >
          <div class="left-section">
            <Avatar
              :label="user.name[0] ? user.name[0].toUpperCase() : ''"
              size="xlarge"
              shape="circle"
            />
            <span class="user-name">{{ user.name }}</span>
            <span class="user-type">{{
              user.type === dbConstants.USER_TYPES.SERVICE_PROVIDER
                ? "(captioner)"
                : ""
            }}</span>
          </div>
          <Badge
            v-if="this.unreadMessages.get(user.id) > 0"
            :value="this.unreadMessages.get(user.id)"
          ></Badge>
        </button>
      </div>
      <div class="edit-display-name">
        <label for="username">Edit your display name</label>
        <InputText
          class="text-input-field"
          id="displayName"
          type="username"
          v-model="displayName"
          @change="displayNameChanged"
        >
          <img :src="Wave" alt="" />
        </InputText>
      </div>
    </div>
    <TabView v-show="!showChatMainMenu" :activeIndex="activeTabIndex">
      <TabPanel v-for="user in otherUsers" :key="user.id" :header="user.name">
        <Chat
          class="chat"
          :toggleChatOpen="toggleChatOpen"
          :closeChat="closeChat"
          :username="displayName"
          :partnerUser="user"
          :sessionName="
            this.$route.params.id + this.$store.state.currentUser.id + user.id
          "
          :incrementNumUnreadMessages="incrementNumUnreadMessages"
          :clearNumUnreadMessages="clearNumUnreadMessages"
          :changeTabIndex="changeTabIndex"
          :pusher="pusher"
          :socketId="socketId"
          :setShowChatMainMenu="callSetShowChatMainMenu"
        />
      </TabPanel>
    </TabView>
  </div>
</template>

<script>
import Chat from "./Chat";
import SidePanelHeader from "../SidePanel/SidePanelHeader";
import { dbConstants } from "../../assets/constants";
import Wave from "../../assets/images/Wave.svg";
import Close from "../../assets/images/Close.svg";

export default {
  props: {
    isOpen: Boolean,
    toggleChatOpen: Function,
    closeChat: Function,
    incrementNumUnreadMessages: Function,
    clearNumUnreadMessages: Function,
    users: Array,
    unreadMessages: Map,
    pusher: Object,
    socketId: String,
    showChatMainMenu: Boolean,
    setShowChatMainMenu: Function,
  },

  components: {
    Chat,
    SidePanelHeader,
  },

  data() {
    return {
      dbConstants: dbConstants,
      Wave,
      Close,
      displayName: this.$store.state.currentUser.name.fullName,
      activeTabIndex: 0,
      activeChatPartnerUid: null,
    };
  },

  computed: {
    otherUsers() {
      return this.users
        .filter((user) => user.id != this.$store.state.currentUser.id)
        .filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i);
    },
  },

  watch: {
    users: function (newVal) {
      if (!newVal.find((user) => user.id === this.activeChatPartnerUid)) {
        // the user you were chatting with disconnected
        this.setShowChatMainMenu(true);
      }
    },
  },

  methods: {
    changeTabIndex(partnerUser, index) {
      this.activeTabIndex = index;
      this.activeChatPartnerUid = partnerUser.id;
      this.setShowChatMainMenu(false);
      this.clearNumUnreadMessages(partnerUser.id);
    },

    callSetShowChatMainMenu(bool) {
      this.setShowChatMainMenu(bool);
      if (bool) {
        // no longer has a conversation open
        this.activeChatPartnerUid = null;
      }
    },

    displayNameChanged() {
      const name = this.displayName;
      if (name) {
        this.$store.commit("updateCurrentUser", { name });
      }
    },
  },
};
</script>

<style lang="scss">
.chat-list {
  // background-color: map-get($component-colours, chat-background);
  // color: map-get($component-colours, chat-font-primary);
  // min-width: $side-panel-width;
  height: 100%;

  .conversations {
    list-style: none;
    padding: 0;
    margin-top: 1.2rem;

    .no-chat-partners {
      padding: 1.2rem $side-panel-outside-padding;
      font-size: 1.6rem;
    }

    .conversation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1.6rem;
      font-family: $font-secondary;
      font-weight: 500;
      padding: 1.2rem $side-panel-outside-padding;
      text-decoration: none;
      color: map-get($component-colours, chat-font-primary);
      width: 100%;
      text-align: left;
      background: map-get($component-colours, info-panel-background);

      &:hover {
        // background-color: map-get($component-colours, menu-item-selected-light);
        // background-color: red;
        filter: brightness(95%);
        color: inherit;
      }

      .left-section {
        .p-avatar {
          background-color: map-get($component-colours, chat-avatar);
          color: map-get($component-colours, chat-avatar-text);

          .p-avatar-text {
            line-height: 0;
          }
        }

        .user-name {
          margin-left: 1.6rem;
        }

        .user-type {
          font-size: 1.4rem;
          color: map-get($component-colours, chat-font-secondary);
          margin-left: 0.8rem;
        }
      }

      .p-badge {
        background: map-get($component-colours, chat-unread-badge);
        font-weight: 400;
        font-size: 1.25rem;
        min-width: 2.5rem;
        height: 2.5rem;
        line-height: 2.5rem;
      }
    }
  }

  .edit-display-name {
    margin-top: 1.2rem;
    padding: 0 $side-panel-outside-padding 1rem;

    label {
      display: block;
      font-weight: bold;
      font-size: 1.8rem;
    }

    img {
      position: absolute;
      height: 3rem;
    }

    .text-input-field {
      margin-top: 0.6rem;
      padding-left: 5rem;
      background-image: url("../../assets/images/Wave.svg");
      background-repeat: no-repeat;
      background-position: left -4rem center;
      background-origin: content-box;
      background-size: 3rem 3rem;
    }
  }

  .p-tabview {
    height: 100%;

    .p-tabview-nav {
      display: none;
    }

    .p-tabview-panels {
      padding: 0;
      height: 100%;

      .p-tabview-panel {
        height: 100%;
      }
    }
  }
}
</style>
