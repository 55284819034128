<template>
  <button
    :class="['rounded-square-button', active ? 'active' : '']"
    :disabled="disabled"
    @click="onClick"
  >
    <img
      v-if="image"
      :src="image"
      :alt="imageAlt"
      :class="[tall ? 'tall' : '', xtall ? 'x-tall' : '']"
    />
    <span v-else class="text">{{ text }}</span>
  </button>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
    active: Boolean,
    onClick: Function,
    image: String,
    imageAlt: String,
    text: String,
    tall: Boolean,
    xtall: Boolean,
  },
};
</script>

<style lang="scss">
.rounded-square-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: map-get($component-sizes, button-height);
  width: map-get($component-sizes, button-height);
  border-radius: 0.8rem;
  font-size: 1rem;
  border: none;
  transition: background-color 0.5s ease;
  font-weight: bold;
  outline: none;

  &[disabled] {
    background-color: map-get($component-colours, button-colour-disabled);
    color: map-get($component-colours, button-font-colour-disabled);
  }

  &.active {
    background-color: map-get($colours, accent-purple-3);
  }

  img {
    max-height: 2.4rem;

    &.tall {
      max-height: 2.8rem;
    }

    &.x-tall {
      max-height: 3.2rem;
    }
  }

  .text {
    margin-top: 0.2rem;
  }
}
</style>
