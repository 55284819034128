<template>
  <div class="settings-block">
    <header>
      <h1>{{ title }}</h1>
      <hr />
    </header>
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
  },
};
</script>

<style lang="scss">
.settings-block {
  background: map-get($component-colours, settings-block);
  padding: 2.4rem 4rem;
  border-radius: 0.6rem;

  header {
    h1 {
      margin: 0;
      color: map-get($colours, white);
      font-size: 1.6rem;
      font-weight: bold;
      font-family: $font-default;
    }

    hr {
      margin: 0.6rem;
      height: 0.1rem;
      border: none;
      background-color: map-get($component-colours, settings-header-hr);
    }
  }

  .content {
    color: map-get($colours, grey-1);
  }
}
</style>
