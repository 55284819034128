<template>
  <div class="tooltip">
    <IconButton
      class="settings-block-inputblock-label-icon tooltip"
      :icon="iconImg"
      alt="infoicon"
      inheritSize
      type="button"
    />
    <div class="tooltipbody validator-tooltip">
      <div class="validator-tooltip-content">
        <div class="validator-tooltip-header">
          <img :src="LightbulbPurple" alt="lightbulb" />
          Password must include:
        </div>
        <div class="validator-tooltip-body">
          <div class="validator-tooltip-body-item">
            <img :src="CheckmarkGrey" alt="checkmark" class="bullet" />
            A minimum of 8 characters long
          </div>
          <div class="validator-tooltip-body-item">
            <img :src="CheckmarkGrey" alt="checkmark" class="bullet" />
            An uppercase letter (A-Z)
          </div>
          <div class="validator-tooltip-body-item">
            <img :src="CheckmarkGrey" alt="checkmark" class="bullet" />
            An lowercase letter (a-z)
          </div>
          <div class="validator-tooltip-body-item">
            <img :src="CheckmarkGrey" alt="checkmark" class="bullet" />
            A number (0-9)
          </div>
          <div class="validator-tooltip-body-item">
            <img :src="CheckmarkGrey" alt="checkmark" class="bullet" />
            A special character
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InfoIcon from "../../assets/images/InfoIcon.svg";
import InfoIconPurple from "../../assets/images/InfoIcon-purple.svg";
import CheckmarkGrey from "../../assets/images/Checkmark-grey.svg";
import LightbulbPurple from "../../assets/images/Lightbulb-purple.svg";

import IconButton from "../IconButton";

export function passwordValidatorMinimumLength(password) {
  return password.length >= 8;
}

export function passwordValidatorHasUppercase(password) {
  return /[A-Z]/.test(password);
}

export function passwordValidatorHasLowercase(password) {
  return /[a-z]/.test(password);
}

export function passwordValidatorHasNumber(password) {
  return /[0-9]/.test(password);
}

export function passwordValidatorHasSpecial(password) {
  return /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(password);
}

export default {
  props: {
    purple: Boolean,
  },

  components: {
    IconButton,
  },

  data() {
    return {
      iconImg: this.purple ? InfoIconPurple : InfoIcon,
      LightbulbPurple,
      CheckmarkGrey,
    };
  },
};
</script>

<style lang="scss">
.validator-tooltip {
  background: white;
  border-radius: 12px;
  background: white;
  font-size: 1.4rem;
  width: 25.6rem;
  display: flex;
  flex-direction: column;

  .validator-tooltip-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 8px;
    padding-bottom: 8px;
    text-align: left;

    .validator-tooltip-header {
      color: black;
      display: flex;
      font-size: 1.4rem;
      display: flex;
      margin-bottom: 8px;
      height: 16px;
      font-weight: bold;
    }

    .validator-tooltip-body {
      display: flex;
      flex-direction: column;
      margin-left: 4px;
      padding: 0 2rem;

      font-size: 1.4rem;
      color: map-get($colours, grey-5);

      .validator-tooltip-body-item {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin-top: 4px;

        .bullet {
          margin-top: 0.2rem;
        }
      }
    }

    img {
      color: map-get($colours, grey-5);
      margin-right: 6px;
    }
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  margin: 0px;
  padding-bottom: 4px; // dumb work around
}

.tooltip .tooltipbody {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -116px;
}

.tooltip:hover .tooltipbody {
  visibility: visible;
}
</style>
