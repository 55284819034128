import { createApp } from "vue";
import ProgressSpinner from "primevue/progressspinner";
import "./styles/global.scss";
import App from "./App.vue";
import PrimeVue from "primevue/config";
// import "primevue/resources/themes/md-light-deeppurple/theme.css";
import "./styles/_theme.scss";
// import "primevue/resources/themes/rhea/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import store from "./store/store";
import OverlayPanel from "primevue/overlaypanel";
import Slider from "primevue/slider";
import RadioButton from "primevue/radiobutton";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import Password from "primevue/password";
import Card from "primevue/card";
import router from "./router";
import ConfirmationService from "primevue/confirmationservice";
import ConfirmDialog from "primevue/confirmdialog";
import Dialog from "primevue/dialog";
import InputSwitch from "primevue/inputswitch";
import Splitter from "primevue/splitter";
import SplitterPanel from "primevue/splitterpanel";
import BadgeDirective from "primevue/badgedirective";
import Tooltip from "primevue/tooltip";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Avatar from "primevue/avatar";
import Badge from "primevue/badge";
import Dropdown from "primevue/dropdown";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import Checkbox from "primevue/checkbox";
import Rating from "primevue/rating";
import Message from 'primevue/message';
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Calendar from "primevue/calendar";
import Menu from "primevue/menu";
import MultiSelect from "primevue/multiselect";
import AutoComplete from "primevue/autocomplete";
import TreeSelect from "primevue/treeselect";
import ToggleButton from "primevue/togglebutton";
import ProgressBar from "primevue/progressbar";
import FileUpload from "primevue/fileupload";
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Tag from 'primevue/tag';
import PortalVue, { Portal } from 'portal-vue'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import BugsnagPerformance from '@bugsnag/browser-performance'

Bugsnag.start({
  apiKey: process.env.VUE_APP_BUGSNAG_API_KEY!,
  plugins: [new BugsnagPluginVue()]
})
BugsnagPerformance.start({ apiKey: process.env.VUE_APP_BUGSNAG_API_KEY! })
const bugsnagVue = Bugsnag.getPlugin('vue')!

const app = createApp(App).use(store).use(router).use(PrimeVue).use(bugsnagVue);

app.component("OverlayPanel", OverlayPanel);
app.component("Slider", Slider);
app.component("RadioButton", RadioButton);
app.component("Button", Button);
app.component("InputText", InputText);
app.component("Textarea", Textarea);
app.component("Password", Password);
app.component("Card", Card);
app.use(ConfirmationService);
app.component("ConfirmDialog", ConfirmDialog);
app.component("Dialog", Dialog);
app.component("InputSwitch", InputSwitch);
app.component("Splitter", Splitter);
app.component("SplitterPanel", SplitterPanel);
app.directive("badge", BadgeDirective);
app.component("ProgressSpinner", ProgressSpinner);
app.directive("tooltip", Tooltip);
app.component("TabView", TabView);
app.component("TabPanel", TabPanel);
app.component("Avatar", Avatar);
app.component("Badge", Badge);
app.component("Dropdown", Dropdown);
app.component("Checkbox", Checkbox);
app.component("Rating", Rating);
app.component("Message", Message);
app.component("DataTable", DataTable);
app.component("Column", Column);
app.component("Calendar", Calendar);
app.component("Menu", Menu);
app.component("MultiSelect", MultiSelect);
app.component("AutoComplete", AutoComplete);
app.component("TreeSelect", TreeSelect);
app.component("ToggleButton", ToggleButton);
app.component("Toast", Toast);
app.component("ProgressBar", ProgressBar);
app.component("FileUpload", FileUpload);
app.component("Accordion", Accordion);
app.component("AccordionTab", AccordionTab);
app.component("Tag", Tag);

app.use(ToastService);
app.use(PortalVue);

app.mount("#app");