<template>
  <div class="stt-model">
    <SettingsBlock title="Speech-to-text model">
      <p class="subtitle">
        If you are experiencing issues with the speech-to-text generator, you
        can try selecting a different model from the list below.
      </p>
      <!-- <div class="stt-model-option">
        <RadioButton
          class="radio"
          id="google"
          name="sttType"
          @change="this.changeSttType(this.sttType)"
          v-model="sttType"
          :value="editorConstants.STT_TYPES.GOOGLE"
        />
        <label for="google">Standard 1</label>
      </div> -->
      <div class="stt-model-option">
        <RadioButton
          class="radio"
          id="deepgram"
          name="sttType"
          @change="this.changeSttType(this.sttType)"
          v-model="sttType"
          :value="editorConstants.STT_TYPES.DEEPGRAM"
        />
        <label for="google">Standard 1</label>
      </div>
      <div class="stt-model-option">
        <RadioButton
          class="radio"
          id="deepgram-diarization"
          name="sttType"
          @change="this.changeSttType(this.sttType)"
          v-model="sttType"
          :value="editorConstants.STT_TYPES.DEEPGRAM_DIARIZATION"
        />
        <label for="deepgram-diarization">Speaker detection</label>
      </div>
    </SettingsBlock>
  </div>
</template>

<script>
import SettingsBlock from "./SettingsBlock";
import { editorConstants } from "../../assets/constants";

export default {
  props: {
    selectedSttType: String,
    changeSttType: Function,
  },

  components: {
    SettingsBlock,
  },

  data() {
    return {
      editorConstants,
      sttType: this.selectedSttType,
    };
  },

  watch: {
    selectedSttType(newVal) {
      this.sttType = newVal;
    },
  },
};
</script>

<style lang="scss">
.stt-model {
  .subtitle {
    color: map-get($colours, grey-3);
    margin: 1.6rem 0 0;
    font-family: $font-secondary;
    font-size: 1.2rem;
    letter-spacing: 0.02rem;
  }

  .stt-model-option {
    margin-top: 1.8rem;

    label {
      font-family: $font-secondary;
      margin-left: 1rem;
      letter-spacing: 0.03rem;
      font-size: 1.4rem;
    }
  }
}
</style>
